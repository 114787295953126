import { all } from 'redux-saga/effects';
import {categorySaga} from './categorySaga';
import {productSaga} from "./productSaga";
import {favoriteSaga} from "./favoriteSaga";
import {cartSaga} from "./cartSaga";
import {authSaga} from "./authSaga";
import {userSaga} from "./userSaga";
import {paymentSaga} from "./paymentSaga";
import {bonusSaga} from "./bonusSaga";
import {bannerSaga} from "./bannerSaga";

export default function* rootSaga() {
    yield all([
        categorySaga(),
        productSaga(),
        favoriteSaga(),
        cartSaga(),
        authSaga(),
        userSaga(),
        paymentSaga(),
        bonusSaga(),
        bannerSaga()
    ]);
}