
import * as types from '../actions/actionTypes';

const initialState = {
    product: {},
    products: [],
    isLoading: false,
    isLoadingSearch: false,
    userClick: '',
    brands: [],
    popularProducts: {},
    newProducts: {},
    searchProducts: {},
}
export default function productReducer(state = initialState, action = null) {
    switch (action.type) {
        case types.GET_PRODUCT:
            return { ...state, isLoading: true };
        case types.PRODUCT_RECEIVED:
            return { ...state, isLoading: false, product: action.payload };
        case types.PRODUCT_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_PRODUCTS_SEARCH:
            return { ...state, isLoadingSearch: true };
        case types.PRODUCTS_SEARCH_RECEIVED:
            return { ...state, isLoadingSearch: false, searchProducts: action.payload };
        case types.PRODUCTS_SEARCH_FAILED:
            return { ...state, isLoadingSearch: false, error: action.error };
        case types.GET_PRODUCTS:
            return { ...state, isLoading: true };
        case types.PRODUCTS_RECEIVED:
            return { ...state, isLoading: false, products: action.payload };
        case types.PRODUCTS_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_POPULAR_PRODUCTS:
            return { ...state, isLoading: true };
        case types.POPULAR_PRODUCTS_RECEIVED:
            return { ...state, isLoading: false, popularProducts: action.payload };
        case types.POPULAR_PRODUCTS_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_NEW_PRODUCTS:
            return { ...state, isLoading: true };
        case types.NEW_PRODUCTS_RECEIVED:
            return { ...state, isLoading: false, newProducts: action.payload };
        case types.NEW_PRODUCTS_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.CREATE_USER_CLICK:
            return { ...state, isLoading: true };
        case types.CREATE_USER_CLICK_SUCCESS:
            return { ...state, isLoading: false, userClick: action.payload };
        case types.CREATE_USER_CLICK_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_BRANDS:
            return { ...state, isLoading: true };
        case types.BRANDS_RECEIVED:
            return { ...state, isLoading: false, brands: action.payload };
        case types.BRANDS_FAILED:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}