
import * as types from '../actions/actionTypes';

const initialState = {
    cardResponse: '',
    cards: [],
    isLoading: false,
    recipientResponse: '',
    editRecipientResponse: '',
    recipients: [],
    orders: [],
    cancelResponse: '',
    orderedProducts: [],
    isLoadingOrdered: false,
    orderResponse: '',
    isLoadingOrder: false,
    isLoadingTime: false,
    time: []
}
export default function paymentReducer(state = initialState, action = null) {
    switch (action.type) {
        case types.ADD_CARD:
            return { ...state, isLoading: true };
        case types.CARD_RECEIVED:
            return { ...state, isLoading: false, cardResponse: action.payload };
        case types.CARD_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.ADD_ORDER:
            return { ...state, isLoadingOrder: true };
        case types.ORDER_ADDED:
            return { ...state, isLoadingOrder: false, orderResponse: action.payload };
        case types.ORDER_ADD_FAILED:
            return { ...state, isLoadingOrder: false, error: action.error };
        case types.GET_CARDS:
            return { ...state, isLoading: true };
        case types.CARDS_SUCCESS:
            return { ...state, isLoading: false, cards: action.payload };
        case types.CARDS_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.DELETE_CARD:
            return { ...state, isLoading: true };
        case types.CARD_REMOVED:
            return { ...state, isLoading: false, cardResponse: action.payload };
        case types.DELETE_CARD_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.ADD_RECIPIENT:
            return { ...state, isLoading: true };
        case types.RECIPIENT_ADDED:
            return { ...state, isLoading: false, recipientResponse: action.payload };
        case types.RECIPIENT_ADD_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_RECIPIENT:
            return { ...state, isLoading: true };
        case types.RECIPIENT_RECEIVED:
            return { ...state, isLoading: false, recipients: action.payload };
        case types.RECIPIENT_GET_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.DELETE_RECIPIENT:
            return { ...state, isLoading: true };
        case types.RECIPIENT_DELETED:
            return { ...state, isLoading: false, recipientResponse: action.payload };
        case types.RECIPIENT_DELETE_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.EDIT_RECIPIENT:
            return { ...state, isLoading: true };
        case types.RECIPIENT_EDITED:
            return { ...state, isLoading: false, editRecipientResponse: action.payload };
        case types.RECIPIENT_EDIT_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_ORDER:
            return { ...state, isLoading: true };
        case types.ORDER_RECEIVED:
            return { ...state, isLoading: false, orders: action.payload };
        case types.ORDER_GET_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_TIME:
            return { ...state, isLoadingTime: true };
        case types.TIME_RECEIVED:
            return { ...state, isLoadingTime: false, time: action.payload };
        case types.TIME_FAILED:
            return { ...state, isLoadingTime: false, error: action.error };    
        case types.CANCEL_ORDER:
            return { ...state, isLoading: true };
        case types.ORDER_CANCELLED:
            return { ...state, isLoading: false, cancelResponse: action.payload };
        case types.ORDER_CANCEL_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.REFUND_PRODUCT:
            return { ...state, isLoading: true };
        case types.PRODUCT_REFUNDED:
            return { ...state, isLoading: false, cancelResponse: action.payload };
        case types.PRODUCT_REFUND_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.CANCEL_PURCHASE:
            return { ...state, isLoading: true };
        case types.PURCHASE_CANCELLED:
            return { ...state, isLoading: false, cancelResponse: action.payload };
        case types.PURCHASE_CANCEL_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_ORDER_PRODUCT:
            return { ...state, isLoadingOrdered: true };
        case types.ORDER_PRODUCT_RECEIVED:
            return { ...state, isLoadingOrdered: false, orderedProducts: action.payload };
        case types.ORDER_PRODUCT_FAILED:
            return { ...state, isLoadingOrdered: false, error: action.error };
        default:
            return state;
    }
}