import React, {Component} from 'react';
import HeaderTop  from "../../components/header/headerTop";
import HeaderMain from "../../components/header/headerMain";
import ProductsSlider from "../../components/products-slider";
import Footer from "../../components/footer";
import ShowCase from "../../components/showcase-brand";
import './brands.scss';
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as productActions from "../../actions/productActions";
import * as categoryActions from "../../actions/categoryActions";
import {connect} from "react-redux";
import {Input, message, Pagination, Select, Spin} from "antd";
import {Link, withRouter} from "react-router-dom";
import {CloseCircleFilled, CloseOutlined} from "@ant-design/icons";
const { Option } = Select;

class Brand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            pageSize: 150,
            lowCost: null,
            highCost: null,
            sortName: 'costAscend',
            brandName: false,
            categoryId: '',
            search: '',
            changedPageSize: false,
            products: []
        };
    }
    onChange = page => {
        const product = {
            brandId: this.props.match.params.id,
            pageNumber: page,
            pageSize: this.state.pageSize,
            lowCost: this.state.lowCost,
            highCost: this.state.highCost,
            search: this.state.search,
            categoryId: this.state.categoryId
        }
        this.setState({
            current: page,
            pageNumber: page
        })
        this.props.actions.getProducts(product);
    }
    handleScroll = () => {
        const {pageSize} = this.state;
        const product = {
            brandId: this.props.match.params.id,
            pageNumber: this.state.pageNumber + 1,
            pageSize: pageSize
        }
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight && this.state.products.length <= this.props.totalCountOfProducts) {
        this.setState({
            pageNumber: this.state.pageNumber + 1,
            current: this.state.current + 1
        })
        this.props.actions.getProducts(product);
        } 
     }
    closeShowProduct = () => {
        this.setState({
            isShowProduct: false,
            lowCost: '',
            highCost: '',
            current: 1,
            categoryId: null,
            search: '',
            changedPageSize: true
        })
        const filter = {
            sortName: this.state.sortName,
            lowCost: '',
            highCost: '',
            pageNumber: 1,
            brandId: this.props.match.params.id,
            categoryId: ''
        }
        this.props.actions.getProducts(filter);
    }
    filterProduct = () => {
        const filter = {
            pageNumber: 1,
            pageSize: this.state.pageSize,
            sortName: this.state.sortName,
            lowCost: this.state.lowCost,
            highCost: this.state.highCost,
            brandId: this.props.match.params.id,
            categoryId: this.state.categoryId,
            search: this.state.search
        }
        this.setState({
            current: 1,
            changedPageSize: true
        })
        this.props.actions.getProducts(filter);
    }
    onSearch = value => {
        const product = {
            subCategoryId: this.props.match.params.id,
            search: value
        }
        this.props.productActions.getProducts(product);
    }
    onChangeSort = e => {
        const filter = {
            pageNumber: 1,
            sortName: e,
            brandId: this.props.match.params.id
        }
        this.setState({
            current: 1
        })
        this.props.actions.getProducts(filter);
    }
    handleCategory = (id, i) => {
        const {pageSize} = this.state;
        // const element = document.getElementsByClassName('category-element');
        // for( let i = 0; i < element.length; i++ ) {
        //     element[i].className = 'category-element';
        // }
        // document.getElementById(i).classList.add('category-element', 'category-element--active');
        console.log(id)
        this.setState({
            categoryId: id,
            current: 1
        })
        if (id) {
            this.setState({
                isShowProduct: true
            })
        }
    }
    onShowSizeChange = (value) => {
        this.setState({
            pageSize: value,
            pageNumber: 1,
            changedPageSize: true
        })
        const product = {
            pageNumber: 1,
            brandId: this.props.match.params.id,
            pageSize: value,
            categoryId: this.state.categoryId
            // sectionId: this.props.match.params.id
        }
        this.props.actions.getProducts(product);
    }
    onSearch = value => {
        const product = {
            brandId: this.props.match.params.id,
            search: value
        }
        this.props.actions.getProducts(product);
    }
    componentDidMount() {
        const filter = {
            pageNumber: 1,
            sortName: this.state.sortName,
            brandId: this.props.match.params.id,
            pageSize: this.state.pageSize
        }
        this.props.actions.getProducts(filter);
        this.props.actions.getNewProducts(filter);
        this.props.categoryActions.getCategoriesByBrand(this.props.match.params.id, '');
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    onChangePrice = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.value) {
            this.setState({
                isShowProduct: true
            })
        }
    }

    // handleCategory = (id, i) => {
    //     const element = document.getElementsByClassName('category-element');
    //     for( let i = 0; i < element.length; i++ ) {
    //         element[i].className = 'category-element';
    //     }
    //     document.getElementById(i).classList.add('category-element', 'category-element--active');
    //     const product = {
    //         pageNumber: this.state.current,
    //         categoryId: id,
    //         pageSize: 15,
    //         brandId: this.props.match.params.id
    //     }
    //     this.setState({
    //         categoryId: id
    //     })
    //     this.props.actions.getProducts(product);
    // }

    closeCategory = id => {
        document.getElementById(id).className = 'category-element';
        const product = {
            brandId: this.props.match.params.id,
            pageNumber: this.state.current,
            categoryId: '',
            pageSize: 15
        }
        this.props.actions.getProducts(product);
    }
    componentWillReceiveProps(nextProps) {
        if (!this.state.brandName) {
            localStorage.setItem(`brand`,
                nextProps.location.query ? nextProps.location.query.brandName :
                    localStorage.brand);
            localStorage.setItem(`brandId`,
                nextProps.location.query ? nextProps.location.query.brandId :
                    localStorage.brandId);
            this.setState({
                brandName: true
            })
        }
        if (this.state.changedPageSize) {
            this.setState({
                products: nextProps.products,
                changedPageSize: false
            })
        } else if(nextProps.products !== this.props.products && !this.state.changedPageSize) {
            const {products} = nextProps
            this.setState({
                products: [...this.state.products, ...products]
            })
        }
        
    }
    onShowSizeChange = (value) => {
        this.setState({
            pageSize: value,
            pageNumber: 1,
            changedPageSize: true
        })
        const product = {
            pageNumber: 1,
            pageSize: value,
            sortName: this.state.sortName,
            lowCost: this.state.lowCost,
            highCost: this.state.highCost,
            brandId: this.props.match.params.id,
            categoryId: this.state.categoryId,
            search: this.state.search
            // sectionId: this.props.match.params.id
        }
        
       
        this.props.actions.getProducts(product);
    }
    render() {
        // const settings = {
        //     dots: false,
        //     arrows: true,
        //     infinite: false,
        //     speed: 500,
        //     autoPlay: true,
        //     centered: true,
        //     variableWidth: true,
        //     slidesToScroll: 3,
        //     initialSlide: 0,
        //     responsive: [
        //         {
        //             breakpoint: 1024,
        //             settings: {
        //                 slidesToShow: 3,
        //                 slidesToScroll: 3,
        //                 infinite: true,
        //                 dots: false
        //             }
        //         },
        //         {
        //             breakpoint: 600,
        //             settings: {
        //                 slidesToShow: 2,
        //                 slidesToScroll: 2,
        //                 initialSlide: 2
        //             }
        //         },
        //         {
        //             breakpoint: 480,
        //             settings: {
        //                 slidesToShow: 1,
        //                 slidesToScroll: 1
        //             }
        //         }
        //     ]
        // };
        const { lowCost, highCost, isShowProduct, products } = this.state;
        let newProduct = [], newProd = {};
   
        let breadCrumb = <div className="breadcrumb">
            <Link className="tiny-text" to={`/`}>Главная</Link>
            <span className="tiny-text"> / </span>
            <Link className="tiny-text" to={`/brands`}>Бренды</Link>
        </div>;
        const filterCategories = this.props.categoriesSuper.map((item, i) => <Option value={item.category.id} key={i}>{item.category.name}</Option>)
        const {brandCat, totalCountOfProducts, isLoading, error, newProducts } = this.props;
        // const categoryElements = brandCat.map((item, i) => {
        //     return(
        //         <div id={i} key={i} className="category-element">
        //             <CloseCircleFilled onClick={() => this.closeCategory(i)}/>
        //             <div style={{width: '100%', padding: '5px', display: 'flex', textAlign: 'center', alignItems: 'center'}} onClick={() => this.handleCategory(item.category.id, i)}  >
        //                 <p>{item.category.name}</p>
        //             </div>
        //         </div>
        //     );
        // });
        if (newProducts && newProducts.length > 0) {
            newProduct = newProducts.map((item, i) => {
                return {
                    id: item.id,
                    img: item.photo_1,
                    label: item.brand.brandName,
                    title: item.name,
                    price: item.price,
                    promoPrice: item.promoPrice,
                    want: item.section.products
                }
            });
        }
        newProd = {
            title: `Новинки`,
            newProduct
        }
        if (error && error.response) {
            message.error( error.response.data);
        }
        const pagination = <div className="pagination-brand">
        <span>Показывать по</span>
         <Select value={this.state.pageSize} onChange={this.onShowSizeChange}>
                    <Option value="20">20</Option>
                    <Option value="50">50   </Option>
                    <Option value="100">100</Option>
                    <Option value="150">150</Option>
                </Select>
        {/* <Pagination 
            onShowSizeChange={this.onShowSizeChange} 
            showSizeChanger 
            current={current} 
            pageSize={pageSize} 
            onChange={this.onChange} 
            pageSizeOptions={[10,20,50,100,150]}
            total={totalCountOfProducts}
        /> */}
    </div>;
        return (
            <Spin spinning={isLoading}>
                <HeaderTop/>
                <HeaderMain/>
                <div className="wrapper">
                    <div className="brand">
                        <div className="container">
                            <div className="brand-info">
                                {breadCrumb}
                                <span className="enormous-text">{products && products.length > 0 ? products[0].brand.brandName : this.props.location.query ? this.props.location.query : localStorage.brand}</span>
                            </div>
                        </div>
                        {/* <ProductsSlider productsSlider={newProd.newProduct && newProd.newProduct.length > 0 ? newProd : {}}/> */}
                        {pagination}
                        <div className="showcase-filter">
                            <div className="container">
                                <div className="search">
                                    <p className="large-text">Витрина</p>
                                    {/*<Search*/}
                                    {/*    placeholder="Введите наименование продукта"*/}
                                    {/*    onSearch={this.onSearch}*/}
                                    {/*    enterButton = {<Button style={{background: '#FFC700', color: '#000000'}}><SearchOutlined/></Button>}*/}
                                    {/*    style={{ width: 300, borderRadius: '4px' }}*/}
                                    {/*/>*/}
                                </div>
                                {/*<div className="categories-tab">*/}
                                {/*    <div className="container">*/}
                                {/*        <Slider {...settings}>*/}
                                {/*            {categoryElements}*/}
                                {/*        </Slider>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="showcase-filter--inner">
                                <div className="filter">
                                        <p className="large-text">Фильтры</p>
                                        
                                        <div className="sort">
                                    <label>Категория</label>
                                        <Select 
                                            value={this.state.categoryId}  
                                            onSelect={this.handleCategory} 
                                            placeholder="Выберите категорию" 
                                            width='100%'
                                        >
                                            {filterCategories}
                                        </Select>
                                        <div>
                                            <label>Поиск по продукту</label>
                                            <Input 
                                                name="search" 
                                                onChange={this.onChangePrice} 
                                                value={this.state.search} 
                                                placeholder="Введите наименование продукта" 
                                            />
                                        </div>
                                    </div>
                                        <div className="sort">
                                        <label>Сортировка</label>
                                            <Select defaultValue='costAscend' width='100%' onChange={this.onChangeSort}>
                                                <Option value='costAscend'>По возрастанию цены</Option>
                                                <Option value='costDescend'>По убыванию цены</Option>
                                                <Option value='createdDate'>По новинкам</Option>
                                                <Option value='userClick'>По популярности</Option>
                                            </Select>
                                        </div>
                                        <span style={{color: `#9B9B9B`}}>Поиск по цене</span>
                                        <div className="price">
                                            <div>
                                                <label>От</label>
                                                <Input type="number" name="lowCost" value={lowCost} onChange={this.onChangePrice} placeholder="От"/>
                                            </div>
                                            <div>
                                                <label>До</label>
                                                <Input type="number" name="highCost" value={highCost} onChange={this.onChangePrice} placeholder="До"/>
                                            </div>
                                        </div>
                                        { isShowProduct ? 
                                        <div className="show-filter--category">
                                            <p onClick={this.filterProduct}>Показать товары</p>
                                            <CloseOutlined onClick={this.closeShowProduct}/>
                                        </div>: '' }
                                    </div>
                                    <ShowCase pagination={pagination} products={products && products.length > 0 ? products : []}/>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Spin>
        );
    }
}
Brand.propTypes = {
    actions: PropTypes.object.isRequired,
    products: PropTypes.array,
    error: PropTypes.object,
    totalCountOfProducts: PropTypes.number,
    brandCat: PropTypes.array,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    newProducts: PropTypes.array
};
const mapStateToProps = state => ({
    products: state.product.products.productResponses,
    totalCountOfProducts: state.product.products.totalCountOfProducts,
    categoriesSuper: state.category.brandsCat,
    error: state.product.error,
    brandCat: state.category.brandsCat,
    isLoading: state.product.isLoading,
    isAuthenticated: state.auth.isAuthenticated,
    newProducts: state.product.newProducts.productResponses
});
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(productActions, dispatch),
    categoryActions: bindActionCreators(categoryActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps) (withRouter(Brand));
