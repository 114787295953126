import React, {Component} from "react";
import HeaderTop from "../../components/header/headerTop";
import HeaderMain from "../../components/header/headerMain";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import {bindActionCreators} from "redux";
import * as categoryActions from "../../actions/categoryActions";
import './section.scss';
import {Button, message, Spin, Input} from "antd";
import Footer from "../../components/footer";
import {SearchOutlined} from "@ant-design/icons";
const { Search } = Input;

class Section extends Component{
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount() {
        this.props.actions.getCategoriesAndSubcategories(this.props.match.params.id, ``);
    }
    componentDidUpdate(prevProps) {
        if(this.props.match.params.id !== prevProps.match.params.id) {
            this.props.actions.getCategoriesAndSubcategories(this.props.match.params.id, ``);
        }
    }
    onSearch = value => {
        this.props.actions.getCategoriesAndSubcategories(this.props.match.params.id, value);
    }
    render() {
        const { categories, isLoading, error } = this.props;
        categories.sort(function(a, b) {
            if (a.category.name < b.category.name) {
                return -1;
            }
            if (a.category.name > b.category.name) {
                return 1;
            }
            return 0;
        })
        const categoryItem = categories.map((item, i) => {
            return(
                <div key={i} className="section-item">
                    <div className="category-name general-text">
                        <Link className="category-name" to={{pathname: `/category/${item.category.id}`,
                            query: {category: item.category.name, sectionId: item.category.sectionId }}}>
                            {item.category.name}</Link>
                    </div>
                    <div className="category-items">
                        {item.subCategories.map((itemSub, j) => {
                            return (
                                <div key={i + `` + j} className="medium-text">
                                    <Link to={{pathname: `/subcategory/${itemSub.id}`,
                                        query: {category: item.category.name,
                                            categoryId: item.category.id,
                                            sectionId: item.category.sectionId,
                                            subCategory: itemSub.name}}}>{itemSub.name}</Link>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        });
        if (error && error.response) {
            message.error(error.response.data);
        }
        return (
            <Spin spinning={isLoading}>
                <HeaderTop/>
                <HeaderMain/>
                <div className="section">
                    <div className="container">
                        <div className="brand-category-title-section">
                            <Search
                                placeholder="Введите наименование категории"
                                onSearch={this.onSearch}
                                enterButton = {<Button style={{background: `#FFC700`, color: `#000000`}}><SearchOutlined/></Button>}
                            />
                        </div>
                        <div className="section-inner">
                            {categoryItem}
                        </div>
                    </div>
                </div>
                <Footer/>
            </Spin>
        );
    }
}
Section.propTypes = {
    actions: PropTypes.object.isRequired,
    categories: PropTypes.array,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
};
const mapStateToProps = state => ({
    categories: state.category.categories,
    error: state.category.error,
    isLoading: state.category.isLoading,
});
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(categoryActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps) (Section);
