import React from 'react';
import {Button, Modal, Spin} from "antd";
import MaskedInput from "antd-mask-input";
import PropTypes from "prop-types";

function ForgotPassword(props) {
    const {
        forgotVisible,
        isLoading,
        onChange,
        phoneForgot,
        sendForgot,
        handleForgotCancel } = props;
    return(
        <Modal
            title="Забыли пароль"
            visible={forgotVisible}
            onCancel={handleForgotCancel}
            footer={null}
        >
            <Spin spinning={isLoading}>
                <div className="code-register">
                    <p className="medium-text" align="center">Введите номер телефона</p>
                    <MaskedInput
                        onChange={onChange}
                        value={phoneForgot}
                        name="phoneForgot"
                        mask="+1(111)-111-11-11"
                        placeholder="+7(777)-777-77-77" />
                    <Button
                        onClick={sendForgot}
                        style={{width: `100%`, color: `#000`, height: `36px`, borderRadius: `5px`}}
                        type="primary">Отправить
                    </Button>
                </div>
            </Spin>
        </Modal>
    );
}

ForgotPassword.propTypes = {
    forgotVisible: PropTypes.bool,
    isLoading: PropTypes.bool,
    onChange: PropTypes.func,
    sendForgot: PropTypes.func,
    handleForgotCancel: PropTypes.func,
    phoneForgot: PropTypes.string
};

export default ForgotPassword;

