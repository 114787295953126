import * as types from './actionTypes';

export function getBonus() {
    return { type: types.GET_BONUS };
}

export function getTransactions(pageNumber) {
    return { type: types.GET_TRANSACTION, pageNumber };
}

export function sendQuestion(data) {
    return { type: types.SEND_QUESTION, data };
}