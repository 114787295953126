import {put, takeLatest} from "@redux-saga/core/effects";
import * as types from "../actions/actionTypes";
import axios from "axios";

export function* getCart(action) {
    const { uid } = action;
    console.log(action);
    try {
        const cart = yield axios.get(`${types.ENDPOINT}/mp/carts/v1/cartItems/grouped?uid=${uid}`).then(res => res.data);
        yield put({type: types.CART_RECEIVED, payload: cart});
    } catch (error) {
        yield put({type: types.CART_FAILED, error});
    }
}
export function* getShoppingList(action) {
    const { uid } = action;
    try {
        const shoppingList = yield axios.get(`${types.ENDPOINT}/mp/carts/v1/orderlist?uid=${uid}`)
            .then(res => res.data);
        yield put({type: types.SHOPPING_LIST_RECEIVED, payload: shoppingList});
    } catch (error) {
        yield put({type: types.SHOPPING_LIST_FAILED, error});
    }
}
export function* deleteCart(action) {
    const { id, uid } = action;
    try {
        const cartResponse = yield axios.delete(
            `${types.ENDPOINT}/mp/carts/v1/cartItems?cartItemId=${id}&uid=${uid}`).then(res => res.data);
        yield put({type: types.CART_REMOVE_SUCCESS, payload: cartResponse});
        yield getCart({uid: uid});
    } catch (error) {
        yield put({type: types.CART_REMOVE_FAILED, error});
    }
}
export function* deleteShopList(action) {
    const { id, isAuth } = action;
    try {
        const orderListResponse = yield axios.delete(
            `${types.ENDPOINT}/mp/carts/v1/orderlist?orderListId=${id}`).then(res => res.data);
        yield put({type: types.SHOPPING_LIST_DELETE_SUCCESS, payload: orderListResponse});
        yield getShoppingList({uid: !isAuth ? document.cookie.split(`;`)[0].slice(8) : ``});
    } catch (error) {
        yield put({type: types.SHOPPING_LIST_DELETE_FAILED, error});
    }
}
export function* deleteAllCart(action) {
    const {uid} = action;
    try {
        const cartResponse = yield axios.delete(`${types.ENDPOINT}/mp/carts/v1/cartItems/all?uid=${uid}`).then(res => res.data);
        yield put({type: types.CART_REMOVE_SUCCESS, payload: cartResponse});
        yield getCart({uid: uid});
    } catch (error) {
        yield put({type: types.CART_REMOVE_FAILED, error});
    }
}
export function* getTotalPrice(action) {
    console.log(action);
    const {uid} = action;
    try {
        const cartResponse = yield axios.get(`${types.ENDPOINT}/mp/carts/v1/totalPrice?uid=${uid}`).then(res => res.data);
        yield put({type: types.TOTAL_PRICE_SUCCESS, payload: cartResponse});
        // yield getCart({uid: 0});
    } catch (error) {
        yield put({type: types.TOTAL_PRICE_FAILED, error});
    }
}
export function* updateCart(action) {
    const { data, uid } = action;
    try {
        const cartResponse = yield axios.patch(`${types.ENDPOINT}/mp/carts/v1/cartItems?uid=${uid}`, data).then(res => res.data);
        yield put({type: types.UPDATE_CART_SUCCESS, payload: cartResponse});
        yield getCart({uid: uid});
    } catch (error) {
        yield put({type: types.UPDATE_CART_FAILED, error});
    }
}
export function* addCart(action ) {
    const { data, handleVisible, uid } = action;
    try {
        const cartResponse = yield axios.post(`${types.ENDPOINT}/mp/carts/v1/cartItems?uid=${uid}`, data).then(res => res.data);
        yield put({type: types.ADD_CART_SUCCESS, payload: cartResponse});
        yield getCart({uid: uid});
        handleVisible();
    } catch (error) {
        yield put({type: types.ADD_CART_FAILED, error});
    }
}
export function* addShoppingList(action ) {
    const {data, isAuth} = action;
    try {
        const shopListResponse = yield axios.post(`${types.ENDPOINT}/mp/carts/v1/orderlist`, data).then(res => res.data);
        yield put({type: types.SHOPPING_LIST_ADD_SUCCESS, payload: shopListResponse});
        yield getShoppingList({uid: !isAuth ? document.cookie.split(`;`)[0].slice(8) : ``});
    } catch (error) {
        yield put({type: types.SHOPPING_LIST_ADD_FAILED, error});
    }
}
export function* updateShoppingList(action) {
    const { data, isAuth } = action;
    try {
        const cartResponse = yield axios.put(`${types.ENDPOINT}/mp/carts/v1/orderlist/count`, data).then(res => res.data);
        yield put({type: types.SHOPPING_LIST_UPDATE_SUCCESS, payload: cartResponse});
        yield getShoppingList({uid: !isAuth ? document.cookie.split(`;`)[0].slice(8) : ``});
    } catch (error) {
        yield put({type: types.SHOPPING_LIST_UPDATE_FAILED, error});
    }
}
export function* cartSaga() {
    yield takeLatest(types.ADD_CART, addCart);
    yield takeLatest(types.GET_CART, getCart);
    yield takeLatest(types.REMOVE_CART, deleteCart);
    yield takeLatest(types.DELETE_ALL_CART, deleteAllCart);
    yield takeLatest(types.UPDATE_CART, updateCart);
    yield takeLatest(types.TOTAL_PRICE, getTotalPrice);
    yield takeLatest(types.GET_SHOPPING_LIST, getShoppingList);
    yield takeLatest(types.SHOPPING_LIST_ADD, addShoppingList);
    yield takeLatest(types.SHOPPING_LIST_DELETE, deleteShopList);
    yield takeLatest(types.SHOPPING_LIST_UPDATE, updateShoppingList);
}