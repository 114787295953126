
import {all, put, takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import * as types from '../actions/actionTypes';

function* addFavorite(action) {
    const { productId, userId } = action.data;
    try {
        const favoriteResponse = yield axios.post(
            `${types.ENDPOINT}/mp/products/favoriteProducts?productId=${productId}&userId=${userId}`)
            .then(res => res.data);
        yield put({type: types.ADD_FAVORITE_SUCCESS, payload: favoriteResponse.body});
    } catch (error) {
        yield put({type: types.ADD_FAVORITE_FAILED, error});
    }
}
function* getFavorite() {
    try {
        const favorites = yield axios.get(
            `${types.ENDPOINT}/mp/products/favoriteProducts`).then(res => res.data);
        yield put({type: types.GET_FAVORITE_SUCCESS, payload: favorites});
    } catch (error) {
        yield put({type: types.GET_FAVORITE_FAILED, error});
    }
}

export function* favoriteSaga() {
    yield all([
        yield takeLatest(types.ADD_FAVORITE, addFavorite),
        yield takeLatest(types.GET_FAVORITE, getFavorite)
    ]);
}
