import * as types from './actionTypes';

export function getProfile() {
    return { type: types.GET_PROFILE };
}

export function changePassword(data, closeModal) {
    return { type: types.CHANGE_PASSWORD, data, closeModal };
}

export function forgotPassword(data, closeModal) {
    return { type: types.FORGOT_PASSWORD, data, closeModal };
}

export function forgotPasswordConfirm(data, closeModal) {
    return { type: types.FORGOT_CONFIRM, data, closeModal };
}

export function editProfile(data) {
    return { type: types.EDIT_PROFILE, data };
}