import React, {Component} from 'react';
import {lazy, Suspense} from "react";
import {Spin, Modal, Select, Button} from "antd";
import {bindActionCreators} from "redux";
import * as productActions from "../../actions/productActions";
import * as authActions from "../../actions/authActions";
import * as userActions from "../../actions/userActions";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
const Footer = lazy(() => import("../../components/footer"));
const Question = lazy(() => import("../../components/question"));
const Benefit = lazy(() => import("../../components/benefit"));
const Statistics = lazy(() => import("../../components/statistics"));
const TopBrands = lazy(() => import("../../components/top-brands"));
const ProductsSlider = lazy(() => import("../../components/products-slider"));
const HeaderTop = lazy(() => import("../../components/header/headerTop"));
const HeaderMain = lazy(() => import("../../components/header/headerMain"));
const Ad = lazy(() => import("../../components/ad"));

const {Option} = Select;
class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: props.isAuthenticated || localStorage.cityId ? false : true,
            cityId: `2`
        };
    }
    componentDidMount() {
        const product = {
            pageNumber: 1,
            pageSize: 15,
            promo: true
        }
        this.props.actions.getPopularProducts(product);
        this.props.actions.getProducts(product);
        this.props.actions.getNewProducts(product);
    }
    handleCity = () => {
        localStorage.setItem('cityId', this.state.cityId)
        this.setState({
            visible: false
        })
        window.location.reload()
    }

    onSelect = e => {
        this.setState({
            cityId: e,          
        })
    }
    render() {
        const { popularProducts, newProducts } = this.props;
        let popularProduct = [], popularProd = {};
        let newProduct = [], newProd = {};
        let promoProduct = [], promoProd = {};
        if (popularProducts && popularProducts.length > 0) {
             popularProduct = popularProducts.map((item, i) => {
                return {
                    id: item.id,
                    img: item.photo_1,
                    label: item.brand.brandName,
                    title: item.name,
                    price: item.price,
                    promoPrice: item.promoPrice,
                    want: item.section?.products,
                    brandId: item.brand.brandId,
                    shortDescription: item.shortDescription
                }
            });
        }
        popularProd = {
            title: `Популярные товары`,
            popularProduct
        }

        if (newProducts && newProducts.length > 0) {
            newProduct = newProducts.map((item, i) => {
                return {
                    id: item.id,
                    img: item.photo_1,
                    label: item.brand.brandName,
                    title: item.name,
                    price: item.price,
                    promoPrice: item.promoPrice,
                    want: item.section?.products,
                    brandId: item.brand.brandId
                }
            });
        }
        newProd = {
            title: `Новинки`,
            newProduct
        }

        if (promoProduct && promoProduct.length > 0) {
            newProduct = promoProduct.map((item, i) => {
                return {
                    id: item.id,
                    img: item.photo_1,
                    label: item.brand.brandName,
                    title: item.name,
                    price: item.price,
                    promoPrice: item.promoPrice,
                    want: item.section?.products,
                    brandId: item.brand.brandId
                }
            });
        }
        promoProd = {
            title: `Новинки`,
            promoProduct
        }
        return (
            <div className="wrapper">
                <Suspense fallback={<Spin style={{width: '100%', height: '100%'}} spinning={true}/>}>
                    <HeaderTop cityId={this.props.profile.cityId}/>
                    <HeaderMain/>
                    <Ad cityId={this.props.profile.cityId}/>
                    <Benefit/>
                    {/*<Statistics/>*/}
                    <TopBrands/>
                    {/* <ProductsSlider productsSlider={popularProd.popularProduct && popularProd.popularProduct.length > 0 ? popularProd : {}}/> */}
                    <ProductsSlider 
                        productsSlider={popularProd.popularProduct && popularProd.popularProduct.length > 0 ? popularProd : {}}/>
                    <ProductsSlider 
                        productsSlider={newProd.newProduct && newProd.newProduct.length > 0 ? newProd : {}}/>
                    {/*<ProductsSlider productsSlider={specialProduct}/>*/}
                    <Question/>
                    <Footer/>
                    <Modal 
                        closable={false}
                        onOk={this.handleCity}
                        visible={this.state.visible}
                        footer={[<Button onClick={this.handleCity} type="primary">Ок</Button>]}
                    >
                        <h4>Выберите ваш город</h4>
                        <Select style={{width: '100%'}} value={this.state.cityId} onSelect={this.onSelect}>
                            <Option value="2">Алматы</Option>
                            <Option value="3">Шымкент</Option>
                        </Select>                       
                    </Modal>
                </Suspense>
            </div>
        );
    }
}
Main.propTypes = {
    popularProducts: PropTypes.array,
    newProducts: PropTypes.array
};

const mapStateToProps = (state) => ({
    popularProducts: state.product.popularProducts.productResponses,
    newProducts: state.product.newProducts.productResponses,
    isLoading: state.product.isLoading,
    error: state.product.error,
    isAuthenticated: state.auth.isAuthenticated,
    profile: state.user.profile
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(productActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps) (Main);
