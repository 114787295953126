import React, {Component} from 'react';
import './headerMain.scss';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { Cascader, AutoComplete, Menu, Badge } from "antd";
import {SearchOutlined, UnorderedListOutlined} from '@ant-design/icons';
import {Divider} from "antd";
import PropTypes from "prop-types";
import * as categoryActions from "../../../actions/categoryActions";
import * as cartActions from "../../../actions/cartActions";
import * as authActions from "../../../actions/authActions";
import * as productActions from "../../../actions/productActions";
import * as userActions from "../../../actions/userActions";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

class HeaderMain extends Component{
    constructor(props){
        super(props);
        this.state = {
            categoryId: null,
            product: this.props.search,
            subcategory: ''
        };
    }
    componentDidMount() {
        const product = {
            search: this.state.product
        }
        this.props.isAuthenticated && this.props.cartActions.getCart(0);
        this.props.categoryActions.getSections();
        !this.props.isAuthenticated && this.props.categoryActions.getTotalSections(localStorage.cityId);
        this.props.productActions.getSearchProducts(product);
    }
    onChange = (value) => {
        value[1] && this.props.history.push(`/category/${value[1]}`)
    }
    onChangeProduct = value => {
        const product = {
            search: value,
        }
        this.setState({
            product: value,
        })
        this.props.productActions.getProducts(product);
    }
    onSelect = (value, option) => {
        this.props.history.push(`/product/${value}`);
        this.setState({
            product: option.children
        })
    }

    searchProduct = () => {
        const product = {
            search: this.state.product,
            history: this.props.history,
            global: true,
            pageNumber: 1
        }
        product.search && this.props.productActions.getSearchProducts(product);      
    }
    searchProductEnter = e => {
        const product = {
            search: this.state.product,
            global: true,
            history: this.props.history,
            pageNumber: 1
        }
        product.search && e.key === 'Enter' && this.props.productActions.getSearchProducts(product);
    }

    render() {
        const { parentCategories, isLoading, totalSections, searchProducts, cart, shoppingList } = this.props;
        let quantities = []
        for (let i = 0; i < cart.length; i++) {
            for (let j = 0; j < cart[i].cartItems.length; j++) {
                quantities.push(cart[i].cartItems[j])
            }
        }
        let menuItems = <Menu.Item>Загрузка...</Menu.Item>;
        let productsGroup = []
        if (!isLoading) {
            menuItems = parentCategories.map((item, i) => (
                <Menu.Item key={i}>
                    <Link to={`/section/${item.id}`}>{item.name}</Link>
                </Menu.Item>
            ));
        }
        if (searchProducts && searchProducts.length > 0) {
            productsGroup = searchProducts.map((item, i) => {
                return {
                    key: item.subCategory?.id,
                    text: item.name,
                    value: String(item.id),
                }
            })
        }
        let options = totalSections.map((item, i) => {
            return {
                value: item.section.id,
                label: item.section.name,
                children: item.superCategories.map((itemCat, j) => {
                    return {
                        value: itemCat.id,
                        label: itemCat.name,
                        children: itemCat.categories.map((itemSub, k) => {
                            return {
                                value: itemSub.category.id,
                                label: itemSub.category.name,
                                code: i+''+j+''+k
                            };
                        })
                    };
                })
            };
        })
        // const displayRender = (labels, selectedOptions) =>
        //     labels.map((label, i) => {
        //         const option = selectedOptions[i];
        //         if (i === labels.length - 1) {
        //             return (
        //                <Link key={i} to={`/category/${option.value}`}>{option.label}</Link>
        //             );
        //         }
        //     });
        const menu = (
            <Menu style={{width: '200px'}}>
                {menuItems}
            </Menu>
        );
        // if (error && error.response) {
        //     message.error( error.response.data.message);
        // }
       
        return(
            <div className="header-main">
                <div className="container">
                    <div className="header-main--inner">
                        <Link to={`/`}>
                            <div className="logo">
                                <img src="/assets/smartplazalogo.svg" alt="logo"/>
                            </div>
                        </Link>
                        {/* <Dropdown overlay={menu}>
                            <button className="dropdown-button" onClick={e => e.preventDefault()}>
                                <MenuOutlined className="menu-outlined" />
                                <DownOutlined className="down-outlined" />
                            </button>
                        </Dropdown> */}
                      
                        <div className="header-main--search">
                        <div className="cascader-header">
                               
                                <Cascader
                                    options={options}
                                    expandTrigger="hover"
                                    onChange={this.onChange}
                                    placeholder='Все категории'
                                    style={{width: `180px`}}
                                    // displayRender={displayRender}
                                />
                                 <Divider style={{background: `#C4CBD2`, height: `36px`, width: `1px`}} type={`vertical`}/>
                            </div>
                       
                            <AutoComplete
                                value={this.state.product}
                                onChange={this.onChangeProduct}
                                onSelect={this.onSelect}
                                dataSource={productsGroup}
                                onKeyDown={this.searchProductEnter}                               
                                className="search-input"
                                placeholder="Я ищу продукт..."
                                // filterOption={(inputValue, option) =>
                                //     option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                // }
                            />
                          
                            <button onClick={this.searchProduct} className="search-button"><SearchOutlined className="search-icon"/></button>
                        </div>
                        <div className="purchase-cart">
                            <Link to='/purchase-list' className="purchase">
                                <UnorderedListOutlined style={{color: `#FFC700`, fontSize: '16px'}} />
                                <p className="general-text">Покупки</p>
                                <Badge style={{background: `#FFC700`, color: `#2D3E50`}} count={shoppingList.length} />
                            </Link>
                            <Link to='/cart' className="cart">
                                <img src="/assets/icons/cart.png" alt="cart"/>
                                <p className="general-text">Корзина</p>
                                <Badge style={{background: `#FFC700`, color: `#2D3E50`}} count={quantities.length} />
                            </Link>
                        </div>
                       
                    </div>
                </div>
            </div>
        );
    }
}
HeaderMain.propTypes = {
    categoryActions: PropTypes.object.isRequired,
    cartActions: PropTypes.object.isRequired,
    parentCategories: PropTypes.array,
    totalSections: PropTypes.array,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    searchProducts: PropTypes.array
};
const mapStateToProps = state => ({
    parentCategories: state.category.parentCategories,
    totalSections: state.category.totalSections,
    error: state.category.error,
    isLoading: state.category.isLoading,
    cart: state.cart.cart,
    isAuthenticated: state.auth.isAuthenticated,
    searchProducts: state.product.products.productResponses,
    profile: state.user.profile,
    shoppingList: state.cart.shoppingList
});
const mapDispatchToProps = dispatch => ({
    categoryActions: bindActionCreators(categoryActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
    productActions: bindActionCreators(productActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps) (withRouter(HeaderMain));