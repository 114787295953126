
import * as types from '../actions/actionTypes';

const initialState = {
    profile: {},
    passwordResponse: {},
    isLoading: false,
    forgot: {},
    forgotConfirm: {}
}
export default function userReducer(state = initialState, action = null) {
    switch (action.type) {
        case types.GET_PROFILE:
            return { ...state, isLoading: true };
        case types.PROFILE_RECEIVED:
            return { ...state, isLoading: false, profile: action.payload };
        case types.PROFILE_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.CHANGE_PASSWORD:
            return { ...state, isLoading: true };
        case types.PASSWORD_CHANGED:
            return { ...state, isLoading: false, passwordResponse: action.payload };
        case types.PASSWORD_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.FORGOT_PASSWORD:
            return { ...state, isLoading: true };
        case types.FORGOT_PASSWORD_SUCCESS:
            return { ...state, isLoading: false, forgot: action.payload };
        case types.FORGOT_PASSWORD_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.FORGOT_CONFIRM:
            return { ...state, isLoading: true };
        case types.FORGOT_PASSWORD_CONFIRMED:
            return { ...state, isLoading: false, forgotConfirm: action.payload };
        case types.FORGOT_PASSWORD_CONFIRM_FAILED:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}