import * as types from '../actions/actionTypes';

const initialState = {
    parentCategories: [],
    totalSections: [],
    subcategories: [],
    categories: [],
    brandsCat: [],
    isLoading: false,
    categoriesMain: [],
    brandSections: [],
    supercategories: [],
    categoriesSuper: []
}
export default function categoryReducer(state = initialState, action = null) {
    switch (action.type) {
        case types.GET_SECTIONS:
            return { ...state, isLoading: true };
        case types.SECTIONS_RECEIVED:
            return { ...state, isLoading: false, parentCategories: action.payload };
        case types.SECTIONS_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_SUPERCATEGORIES:
            return { ...state, isLoading: true };
        case types.SUPERCATEGORIES_RECEIVED:
            return { ...state, isLoading: false, supercategories: action.payload };
        case types.SUPERCATEGORIES_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_CATEGORIES_SUPER:
            return { ...state, isLoading: true };
        case types.CATEGORIES_SUPER_RECEIVED:
            return { ...state, isLoading: false, categoriesSuper: action.payload };
        case types.CATEGORIES_SUPER_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_BRAND_SECTION:
            return { ...state, isLoading: true };
        case types.BRAND_SECTION_RECEIVED:
            return { ...state, isLoading: false, brandSections: action.payload };
        case types.BRAND_SECTION_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_BRAND_CAT:
            return { ...state, isLoading: true };
        case types.BRAND_CAT_RECEIVED:
            return { ...state, isLoading: false, brandsCat: action.payload };
        case types.BRAND_CAT_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_SUBCATEGORIES:
            return { ...state, isLoading: true };
        case types.SUBCATEGORIES_RECEIVED:
            return { ...state, isLoading: false, subcategories: action.payload };
        case types.SUBCATEGORIES_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_CATEGORIES:
            return { ...state, isLoading: true };
        case types.CATEGORIES_RECEIVED:
            return { ...state, isLoading: false, categoriesMain: action.payload };
        case types.CATEGORIES_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_SECTION_TOTAL:
            return { ...state, isLoading: true };
        case types.SECTION_TOTAL_RECEIVED:
            return { ...state, isLoading: false, totalSections: action.payload };
        case types.SECTION_TOTAL_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_CAT_SUBCAT:
            return { ...state, isLoading: true };
        case types.SECTION_CAT_SUBCAT_RECEIVED:
            return { ...state, isLoading: false, categories: action.payload };
        case types.SECTION_CAT_SUBCAT_FAILED:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}