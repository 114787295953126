import * as types from './actionTypes';

export function signIn(data, history) {
    return { type: types.SIGN_IN, data, history };
}
export function signUp(data, openCode) {
    return { type: types.SIGN_UP, data, openCode };
}
export function signUpConfirm(data) {
    return { type: types.SIGN_UP_CONFIRM, data };
}
export function signOut() {
    return { type: types.SIGN_OUT };
}