import React, {useEffect, useState} from 'react';
import "./account.scss";
import {Button, DatePicker, Form, Input, Select, Spin, Modal} from "antd";
import HeaderTop from "../../components/header/headerTop";
import HeaderMain from "../../components/header/headerMain";
import Footer from "../../components/footer";
import MaskedInput from "antd-mask-input";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as userActions from "../../actions/userActions";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import moment from 'moment';

const { Option } = Select;
function Account(props) {
    const [form] = Form.useForm();
    const [formPassword] = Form.useForm();
    const { profile, isLoading } = props;
    const [visible, setVisible] = useState(false);
    const handleSignUpOk = values => {
        let phone = ``;
        for (let digit of values.phone) {
            if (digit !== `-` && digit !== `+` && digit !== `(` && digit !== `)`) {
                phone += digit;
            }
        }
        const registerForm = {
            firstName: values.firstName,
            lastName: values.lastName,
            birthdate: values.birthDate,
            email: values.email,
            phone: values.phone,
            gender: values.gender,
            language: values.language,
            cityId: values.cityId,
            platform: `web`,
        };
        props.actions.editProfile(registerForm);
    };
    const handleChangePassword = values => {
        const data = {
            newPassword: values.newPassword,
            phone: profile.phone,
            regCode: this.state.regCode

        };
        props.actions.changePassword(data, handleCancel);
    };
    const handleCancel = () => {
        setVisible(false);
    };
    useEffect(() => {
        props.actions.getProfile();
    }, []);
    const dateFormatList = [`DD/MM/YYYY`, `DD/MM/YY`];
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 24,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 24,
            },
        },
    };
    form.setFieldsValue({
        firstName: profile.name,
        lastName: profile.surname,
        phone: profile.phone,
        email: profile.email,
        birthDate: moment(profile.birthDate),
        gender: String(profile.gender),
        cityId: String(profile.cityId),
    });
    formPassword.setFieldsValue({
        newPassword: ``,
        confirm: ``,
        oldPassword: ``,
    });
    return (
        <Spin spinning={isLoading}>
            <HeaderTop/>
            <HeaderMain/>
            <div className="container-basket">
                <div className="account">
                    <div className="account-inner">
                        <p className="large-text">Мои данные</p>
                        <Form
                            {...formItemLayout}
                            name="register"
                            onFinish={handleSignUpOk}
                            scrollToFirstError
                            form={form}
                        >
                            <div className="account-form">
                                <Form.Item
                                    className="account-form--item"
                                    name="firstName"
                                    label={
                                        <span>
                                            Имя&nbsp;
                                        </span>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: `Пожалуйста введите имя!`,
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    className="account-form--item"
                                    name="lastName"
                                    label={
                                        <span>
                                            Фамилия&nbsp;
                                        </span>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: `Пожалуйста введите фамилию!`,
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    className="account-form--item"
                                    name="phone"
                                    label="Телефон"
                                    rules={[
                                        {
                                            required: true,
                                            message: `Пожалуйста, введите свой номер телефона!`,
                                        },
                                    ]}
                                >
                                    <MaskedInput mask="+1(111)-111-11-11" placeholder="+7(777)-777-77-77"/>
                                </Form.Item>
                            </div>
                            <div className="account-form">
                                <Form.Item
                                    name="email"
                                    label="E-mail"
                                    className="account-form--item"
                                    rules={[
                                        {
                                            type: `email`,
                                            message: `Неправильный e-mail!`,
                                        },
                                        {
                                            required: true,
                                            message: `Пожалуйста введите свой e-mail!`,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    className="account-form--item"
                                    name="birthDate"
                                    label="Дата рождения"
                                    rules={[
                                        {
                                            required: true,
                                            message: `Пожалуйста, выберите дату рождения!`,
                                        },
                                    ]}
                                >
                                    <DatePicker placeholder="Выберите дату рождения" style={{width: `100%`}}
                                        format={dateFormatList}/>
                                </Form.Item>

                                <Form.Item
                                    className="account-form--item"
                                    name="gender"
                                    label="Пол"
                                    rules={[
                                        {
                                            required: true,
                                            message: `Пожалуйста, выберите пол!`,
                                        },
                                    ]}
                                >
                                    <Select placeholder="Выберите пол">
                                        <Option value='1'>Мужской</Option>
                                        <Option value='0'>Женский</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            {/*<Form.Item*/}
                            {/*    name="language"*/}
                            {/*    label="Язык"*/}
                            {/*    rules={[*/}
                            {/*        {*/}
                            {/*            required: true,*/}
                            {/*            message: `Пожалуйста, выберите язык!`,*/}
                            {/*        },*/}
                            {/*    ]}*/}
                            {/*>*/}
                            {/*    <Select placeholder="Выберите язык">*/}
                            {/*        <Option value='kazakh'>Казахский</Option>*/}
                            {/*        <Option value='russian'>Русский</Option>*/}
                            {/*        <Option value='english'>English</Option>*/}
                            {/*        <Option value='uzbek'>Узбекский</Option>*/}
                            {/*    </Select>*/}
                            {/*</Form.Item>*/}
                            <Form.Item
                                name="cityId"
                                label="Город"
                                className="account-form--item"
                                style={{marginRight: `10px`}}
                                rules={[
                                    {
                                        required: true,
                                        message: `Пожалуйста, выберите город!`,
                                    },
                                ]}
                            >
                                <Select placeholder="Выберите город">
                                    <Option value='2'>Алматы</Option>
                                    <Option value='3'>Шымкент</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="Пароль"
                            >
                                <Button onClick={() => setVisible(true)}>Изменить</Button>
                            </Form.Item>
                            <Form.Item className="center-element">
                                <Button style={{color: `#000`, height: `36px`, borderRadius: `5px`}}
                                    type="primary" htmlType="submit">
                                    Сохранить
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
            <Footer/>
            <Modal
                title="Изменить пароль"
                visible={visible}
                onOk={handleChangePassword}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <Form
                    {...formItemLayout}
                    name="register"
                    onFinish={handleChangePassword}
                    scrollToFirstError
                    form={formPassword}
                >
                    <Form.Item
                        name="oldPassword"
                        label="Текущий пароль"
                        rules={[
                            {
                                required: true,
                                message: `Пожалуйста, введите текущий пароль!`,
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="newPassword"
                        label="Новый пароль"
                        rules={[
                            {
                                required: true,
                                message: `Пожалуйста, введите новый пароль!`,
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="confirm"
                        label="Повторите пароль"
                        dependencies={[`password`]}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: `Пожалуйста, повторите пароль!`,
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue(`newPassword`) === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(`Пароли не совпадают!`);
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item className="center-element">
                        <Button style={{color: `#000`, height: `36px`, borderRadius: `5px`, textAlign: `center`}}
                            type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </Spin>
    );
}
Account.propTypes = {
    actions: PropTypes.object.isRequired,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
    profile: PropTypes.object,
    passwordResponse: PropTypes.object
};
const mapStateToProps = state => ({
    profile: state.user.profile,
    error: state.user.error,
    isLoading: state.user.isLoading,
    passwordResponse: state.user.passwordResponse
});
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(userActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps) (withRouter(Account));