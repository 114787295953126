import {all, put, takeLatest} from "@redux-saga/core/effects";
import * as types from "../actions/actionTypes";
import axios from "axios";
import {message} from "antd";
import {fetchTotalSections} from './categorySaga';
import {getMarketBrands} from './productSaga';

function* fetchProfile() {
    try {
        const profile = yield axios.get(`${types.ENDPOINT}/users/api/v1/profile`).then(res => res.data);
        yield put({type: types.PROFILE_RECEIVED, payload: profile});
        localStorage.setItem(`cityId`, profile.cityId);
        yield fetchTotalSections({cityId: profile.cityId});
        if (window.location.pathname === `/`) yield getMarketBrands({cityId: profile.cityId});
    } catch (error) {
        yield put({type: types.PROFILE_FAILED, error});
        message.error(error.response.data.message);
    }
}
function* changePassword(action) {
    const { data, closeModal } = action;
    console.log(data)
    try {
        const profile = yield axios.put(`${types.ENDPOINT}/users/api/v1/forgot/password/change?newPassword=${data.newPassword}&phone=${data.phone}&registrationCode=${data.regCode}`)
            .then(res => res.data);
        yield put({type: types.PASSWORD_CHANGED, payload: profile});
        closeModal();
        message.success(profile.message);
    } catch (error) {
        yield put({type: types.PASSWORD_FAILED, error});
        message.error(`Ошибка при изменении`);
    }
}
function* forgotPassword(action) {
    const { data, closeModal } = action;
    try {
        const forget = yield axios.put(`${types.ENDPOINT}/users/api/v1/forgot/password?phone=${data}`).then(res => res.data);
        yield put({type: types.FORGOT_PASSWORD_SUCCESS, payload: forget});
        closeModal();
    } catch (error) {
        yield put({type: types.FORGOT_PASSWORD_FAILED, error});
        message.error(error.response.data.message);
    }
}
function* forgotPasswordConfirm(action) {
    const { data, closeModal } = action;
    try {
        const forget = yield axios.put(`${types.ENDPOINT}/users/api/v1/forgot/password/confirm?phone=${data.phone}&registrationCode=${data.regCode}`).then(res => res.data);
        yield put({type: types.FORGOT_PASSWORD_CONFIRMED, payload: forget});
        closeModal();
    } catch (error) {
        yield put({type: types.FORGOT_PASSWORD_CONFIRM_FAILED, error});
        message.error(error.response.data.message);
    }
}
function* editProfile(action) {
    const { data } = action;
    try {
        const profile = yield axios.put(`${types.ENDPOINT}/users/api/v1/profile/edit`, data).then(res => res.data);
        yield put({type: types.PROFILE_EDITED, payload: profile});
        message.success(`Данные сохранены!`);
    } catch (error) {
        yield put({type: types.PROFILE_EDIT_FAILED, error});
        message.error(`Ошибка при сохранении данных!`);
    }
}
export function* userSaga() {
    yield all([
        takeLatest(types.GET_PROFILE, fetchProfile),
        takeLatest(types.CHANGE_PASSWORD, changePassword),
        takeLatest(types.EDIT_PROFILE, editProfile),
        takeLatest(types.FORGOT_PASSWORD, forgotPassword),
        takeLatest(types.FORGOT_CONFIRM, forgotPasswordConfirm),
    ]);
}