import React, {Component} from "react";
import HeaderTop from "../../components/header/headerTop";
import HeaderMain from "../../components/header/headerMain";
import Footer from "../../components/footer";
import './basket.scss';
import {DeleteOutlined, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as cartActions from "../../actions/cartActions";
import * as authActions from "../../actions/authActions";
import {Empty, Spin} from "antd";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {ENDPOINT} from "../../actions/actionTypes";
import Payment from "../../components/payment";
import Button from "antd/es/button";
import classNames from 'classnames';

class Basket extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            favorite: '',
            visible: false,
            deliveryMethod: 'delivery',
            pickup: false,
            recipientId: null,

        };
    }
    componentDidMount() {
        const uid = document.cookie.split(';')[0].slice(8);
        console.log(document.cookie.split(';')[0].slice(8))
        this.props.cartActions.getCart(!this.props.isAuthenticated ? uid : ``);
        this.props.cartActions.getTotalPrice(!this.props.isAuthenticated ? uid : ``);      
    }

    handleDelete = id => {
        const uid = document.cookie.split(';')[0].slice(8)
        this.props.cartActions.deleteCart(id, !this.props.isAuthenticated ? uid : ``);
    }

    handleCount = (type, item) => {
        const uid = document.cookie.split(';')[0].slice(8);
        let count = 0;
        if (type === 'inc') {
            count = 1;
        } else if (type === 'dec') {
            count = -1;
        }
        const cartItem = {
            id: item.cartItem.id,
            productId: item.cartItem.productId,
            userId: 0,
            productCount: count
        }
        this.props.cartActions.updateCount(cartItem, !this.props.isAuthenticated ? uid : ``);
    }

    handleDeleteAll = () => {
        const uid = document.cookie.split(';')[0].slice(8);
        this.props.cartActions.deleteAllCart(!this.props.isAuthenticated ? uid : ``);
    }
    onChangeRadio = e => {
        this.setState({
            deliveryMethod: e.target.value
        })
        if (e.target.value === 'pickup') {
            this.setState({
                pickup: true,
            })
        } else {
            this.setState({
                pickup: false,
            })
        }
    };
    render() {
        const { isLoading, cart } = this.props;
        const {deliveryMethod, pickup} = this.state;
        let quantities = []
        for (let i = 0; i < cart.length; i++) {
            for (let j = 0; j < cart[i].cartItems.length; j++) {
                quantities.push(cart[i].cartItems[j])
            }
        }
        let isEmpty = '';
        let comission = 0, sum = 0, delivery = 0;
        let brandsTrue = [];
        cart.forEach((item) => {
            item.brandResponse.minOrderSum > item.totalPrice ? brandsTrue.push('false') : brandsTrue.push('true');
            sum = sum + item.totalPrice;
            if (deliveryMethod === "delivery") {
                
                comission = comission + item.totalPrice * item.brandResponse.userDeliveryCommission/100;
                delivery = delivery + (item.brandResponse.deliveryFixedSum !== null && item.brandResponse.deliveryFixedSum != 0.0 ? item.brandResponse.deliveryFixedSum :  item.totalPrice *  item.brandResponse.deliverySum/100);
            } else {
                comission = comission + item.totalPrice * item.brandResponse.userPickupCommission/100
            }
        });
        const total = sum + comission + delivery;
        const cartItems = cart.map((item, i) => {
            return (
                <div key={i}>
                <div className={classNames("brand-cart", {"brand-cart-active": item.brandResponse.minOrderSum > item.totalPrice})} >
                    <h2>{item.brandResponse ? item.brandResponse.brandName : ''}</h2>
                    <p>Минимальная сумма заказа: {item.brandResponse ? item.brandResponse.minOrderSum : ''}тг</p>
                    {/*<p>Доставка: {item.brandResponse ? item.brandResponse.deliverySum : ''}тг</p>*/}
                </div>
                    {item.cartItems.map((itemCart, j) => {
                        return (
                            <div key={i+''+j} className="basket-item--product">
                                <div className="basket-elements">
                                    <div className="list-item basket-items">
                                        <div className="basket-item">
                                            <Link to={`/product/${itemCart.cartItem.productId}`} className="basket-img">
                                                <img src={`${ENDPOINT}/mp/products/photos/${itemCart.cartItem.photo_1}`} alt="product"/>
                                            </Link>
                                            <Link to={`/product/${itemCart.cartItem.productId}`} className="basket-content">
                                                <p className="medium-text">{itemCart.cartItem.name}</p>
                                            </Link>
                                        </div>
                                        <div className="basket-attributes">
                                            {itemCart.productAttribute?.map((itemAtt, k) => {
                                                return (
                                                    <span key={i+''+j+''+k}>{itemAtt.valueResponse.name}, </span>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="list-item basket-count">
                                        {itemCart.cartItem.productCount > 1 ? <MinusCircleOutlined
                                            onClick={() => this.handleCount('dec', itemCart)}
                                            style={{
                                                color: `#000`, cursor: `pointer`,
                                                fontSize: `28px`, marginRight: `10px`
                                            }}/> : ''}

                                        <span>{itemCart.cartItem.productCount}</span>
                                        <PlusCircleOutlined
                                            onClick={() => this.handleCount('inc', itemCart)}
                                            style={{
                                                color: `#000`, cursor: `pointer`,
                                                fontSize: `28px`, marginLeft: `10px`
                                            }}/>
                                    </div>
                                    <div className="general-text list-item basket-price">
                                        <div className="general-text list-item basket-price">
                                            {itemCart.cartItem.promoPrice ? <span className="tiny-text"
                                                                              style={{textDecoration: 'line-through'}}>{itemCart.cartItem.price}тг</span> : itemCart.cartItem.price}
                                        </div>
                                        <span
                                            style={{marginLeft: '5px'}}>{itemCart.cartItem.unitPrice ? `${itemCart.cartItem.unitPrice * itemCart.cartItem.productCount +  (itemCart.productAttribute && itemCart.productAttribute?.length ? itemCart.productAttribute.reduce((sum, it) => {
                                                return sum + it.valueResponse.price      
                                                }, 0)  : 0    )           
                                            }  тг` : ''}</span>
                                    </div>
                                </div>
                                <div className="footer-basket">
                                    <p onClick={() => this.handleDelete(itemCart.cartItem.id)}><DeleteOutlined/>Удалить</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        });
        if (cart.length === 0) {
            isEmpty = <Empty style={{width: `100%`, marginTop: "20px"}}
                                   description={
                                       <span>
                                             Пусто
                                       </span>
                                   }/>;
        }
        return (
            <Spin spinning={isLoading}>
                <HeaderTop/>
                <HeaderMain/>
                <div className="wrapper-white">
                    <div className="basket">
                        <div className="container-basket">
                            {cart.length > 0 && this.props.isAuthenticated ? 
                                <Payment 
                                    brandId={cart && cart.length > 0 ? cart[0].brandResponse.brandId : ''} 
                                    brandCollectable={cart && cart.length > 0 ? cart[0].brandResponse.collectable : ''} 
                                    delivery={delivery} 
                                    sum={sum} 
                                    comission={comission} 
                                    total={total} 
                                    brandsTrue={brandsTrue} 
                                    pickup={pickup} 
                                    onChangeRadio={this.onChangeRadio} 
                                    delMethod={deliveryMethod}   
                                    visible={this.state.visible}
                                /> : !this.props.isAuthenticated ?
                                <div className="cart-order--empty">
                                <Button type="primary" >Нужна авторизация для заказа товара</Button>
                            </div> :
                                <div className="cart-order--empty">
                                    <Button type="primary" onClick={() => this.props.history.push('/')}>Посмотрите каталог товаров</Button>
                                </div>
                            }
                            <div className="basket-title">
                                <span onClick={this.handleDeleteAll} className="medium-text">Удалить все</span>
                            </div>
                            <div className="basket-inner">
                                <div className="basket-header">
                                    <p className="list-item large-text">{quantities.length === 1 ? `${quantities.length} товар` : quantities.length > 1 && quantities.length <5 ?  `${quantities.length} товара` : `${quantities.length} товаров`} </p>
                                    {/*<p className="list-item tiny-text">Количество</p>*/}
                                    {/*<p className="list-item tiny-text">Цена</p>*/}
                                </div>
                                {isEmpty}
                                {cartItems}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Spin>
        );
    }
}
Basket.propTypes = {
    cartActions: PropTypes.object.isRequired,
    cart: PropTypes.array,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
    quantity: PropTypes.number
};
const mapStateToProps = state => ({
    cart: state.cart.cart,
    quantity: state.cart.quantityProduct,
    isLoading: state.cart.isLoading,
    isAuthenticated: state.auth.isAuthenticated,
    error: state.cart.error,
});
const mapDispatchToProps = dispatch => ({
    cartActions: bindActionCreators(cartActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps) (withRouter(Basket));
