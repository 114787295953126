
import * as types from '../actions/actionTypes';

const initialState = {
    banner: {},
    isLoading: false
}
export default function bannerReducer(state = initialState, action = null) {
    switch (action.type) {
        case types.GET_BANNERS:
            return { ...state, isLoading: true };
        case types.BANNERS_RECEIVED:
            return { ...state, isLoading: false, banner: action.payload };
        case types.BANNERS_FAILED:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}