import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Link, withRouter} from 'react-router-dom';
import './showcase-brand.scss';
import {Button, Empty, Modal, message, InputNumber} from 'antd';
import {bindActionCreators} from "redux";
import * as productActions from "../../actions/productActions";
import { connect } from 'react-redux';
import * as authActions from "../../actions/authActions";
import * as cartActions from "../../actions/cartActions";
import { ENDPOINT } from "../../actions/actionTypes";
import {ShoppingCartOutlined, EyeOutlined, UnorderedListOutlined} from '@ant-design/icons'

function ShowCaseBrand(props) {
    const [visible, setVisible] = useState(false);
    const [quickVisible, setQuickVisible] = useState(false);
    const [name, setName] = useState(``);
    const [shortDescription, setShortDescription] = useState(``);
    const [isShowCart, setIsShowCart] = useState(false);
    const [img, setImg] = useState(``);
    const [price, setPrice] = useState(``);
    const [productCount, setProductCount] = useState(1)
    const [id, setId] = useState(null)
    const [brand, setBrand] = useState({})
    
    const createUserClick = id => {
        props.actions.createUserClick(id);
    };

    const handleCancel = () => {
        setVisible(false);
        setQuickVisible(false)
    };

    const handleVisible = () => {
        setVisible(true);
    };

    const handleQuickCancel = () => {
        setQuickVisible(false);
    };

    const handleQuickVisible = item => {
        setQuickVisible(true);
        setName(item.name);
        setPrice(item.promoPrice ? item.promoPrice : item.price);
        setImg(item.photo_1);
        setShortDescription(item.shortDescription);
        setIsShowCart(item.section?.products)
        setId(item.id)
        setBrand(item.brand.brandId)
    };

    const handleOkCart = () => {
        props.history.push(`/cart`);
    };
    
    const onMouseOver = id => () => {
        const elements = document.getElementsByClassName('eye-buttons')
        const overlays = document.getElementsByClassName('showcase-img-overlay')
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = 'none'
        }
        for (let i = 0; i < overlays.length; i++) {
            overlays[i].style.display = 'none'
        }
        const quickShow = document.getElementById(String(id))
        quickShow.style.display = 'block'
        const overlay = document.getElementById(`overlay${String(id)}`)
        overlay.style.display = 'block' 
    };
    const onMouseOut = id => () => {
        const quickShow = document.getElementById(String(id))
        const overlay = document.getElementById(`overlay${String(id)}`)
        quickShow.style.display = 'none'
        overlay.style.display = 'none'
    };

    const onChangeQuantity = e => {
        setProductCount(e)
    };

    const addShoppingList = item => {
        const data = {
            productId: item.id,
            uid: !props.isAuthenticated ? document.cookie.split(`;`)[0].slice(8) : ``,
            productCount: 1,
            brandId: item.brandId,
        }
        const isAuth = props.isAuthenticated ? true : false
        props.cartActions.addShoppingList(data, isAuth)
    };

    useEffect(() => {
        const uid = !props.isAuthenticated ? document.cookie.split(`;`)[0].slice(8) : ``
        props.cartActions.getShoppingList(uid)
        
       
    }, [])
 
    const handleOk = item => {
        const uid = document.cookie.split(';')[0].slice(8);
        const { cart, cartActions, isAuthenticated } = props;
        const cartItem = {
            cartItem: {
                productId: item.id,
                userId: 0,
                productCount: productCount ? productCount : 1,
                brandId: item.brand.brandId
            },
            productAttributes: []
        };
        if (cart?.length === 0) {
            cartActions.addCart(cartItem, handleVisible, !isAuthenticated ? uid : '');
        } else if (cart && cart?.length > 0 && item.brand.brandId === cart[0]?.brandResponse?.brandId) {
            cartActions.addCart(cartItem, handleVisible, !isAuthenticated ? uid : '');
        } else if (cart && cart?.length > 0 && item.brand.brandId !== cart[0]?.brandResponse?.brandId) {
            message.error(`Для дополнения корзины товаром из другого магазина/заведения,
            завершите текущий и оформите новый, пожалуйста`, 5);
        } 
        setName(item.name);
        setPrice(item.promoPrice ? item.promoPrice : item.price);
        setImg(item.photo_1);
    };
    let showcaseGroup;
    if (props.products?.length === 0) {
        showcaseGroup = <Empty style={{width: `100%`}}
            description={
                <span>
           Доступных товаров нет
                </span>
            }
        />;
    } else {
        showcaseGroup = props?.products?.map((item, i) => (
            <div onClick={() => createUserClick(item.id)}
                key={i} className="showcase-brand-item">
                <div 
                    style={{positon: 'relative'}}
                    onMouseOver={onMouseOver(item.id)} 
                    onMouseOut={onMouseOut(item.id)} 
                    to={`/product/${item.id}`} 
                    className="showcase-brand--img"
                 
                >
                      
                    <img src={`${ENDPOINT}/mp/products/photos/${item.photo_1}`} alt={item.name}/>
                <Button 
                    onClick={() => handleQuickVisible(item)} 
                    style={{display: 'none', width: '100%', borderRadius: 0}} 
                    className="eye-buttons"  
                    id={item.id}>
                        <EyeOutlined />  
                    Быстрый просмотр
                </Button>
                <div id={`overlay${item.id}`} className="showcase-img-overlay"></div>
                </div>
                <Link to={`/product/${item.id}`} className="prod-info">
                    <div>
                        <p className="tiny-text">{item.brand.brandName}</p>
                        <p style={{textOverflow: `ellipsis`, whiteSpace: `nowrap`, overflow: `hidden`}}
                            className="medium-text">{item.name}</p>
                    </div>
                    <div className="product-prices">
                        {item.promoPrice ? <span className="tiny-text price-promo">{item.price}</span> :
                            <span style={{color: `#000000`}} className="general-text">{item.price} тг</span>}
                        {item.promoPrice ? <span className="price-label general-text">{item.promoPrice} тг</span>
                            : ``}
                    </div>                 
                </Link>               
                {item.section?.products ?
                    <button onClick={() => handleOk(item)} className="medium-text"><ShoppingCartOutlined style={{marginRight: '5px'}}/>В корзину</button>
                    : <Link to={`/product/${item.id}`} className="medium-text">Подробнее</Link>}     
                   {item.section?.products && !props.shoppingList.some(elem => elem.cartItem.productId === item.id) ?  
                   <p onClick={() => addShoppingList(item)} className="purchase-text" 
                   style={{display: 'flex', justifyContent: 'flex-end', cursor: 'pointer'}}>+ В список покупок</p>  
                   : item.section?.products && props.shoppingList.some(elem => elem.cartItem.productId === item.id) ? 
                   <p  className="purchase-text" 
                   style={{display: 'flex', justifyContent: 'flex-end', cursor: 'pointer', color: 'green'}}>Добавлено</p> : ''}        
            </div>
        ));
    }
    return(
        <div className="showcase-brand" >            
            <div className="showcase-brand--inner">
                {showcaseGroup}
            </div>
            {/* {props.pagination} */}
            <Modal
                title="ТОВАР ДОБАВЛЕН В КОРЗИНУ"
                visible={visible}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Продолжить покупку
                    </Button>,
                    <Button style={{color: `#000`}} key="ok" type="primary" onClick={handleOkCart}>
                        Перейти в корзину
                    </Button>
                ]}
            >
                <div className="cart-modal">
                    <div className="cart-modal--img">
                        <img src={`${ENDPOINT}/mp/products/photos/${img}`} alt={`product`}/>
                    </div>
                    <div className="medium-text cart-modal--text">
                        <p>{name}</p>
                        <p>{price}тг</p>
                    </div>
                </div>
            </Modal>
            <Modal
                title="Быстрый просмотр"
                visible={quickVisible}
                onCancel={handleQuickCancel}
                footer={null}
            >
                <div>
                    <div >
                        <div className="cart-modal">
                        <div className="cart-modal--img">
                            <img src={`${ENDPOINT}/mp/products/photos/${img}`} alt={`product`}/>
                        </div>
                        <div className="cart-modal-info">
                        <div className="medium-text cart-modal--text">
                            <p className="general-text">{name}</p>
                            <p className="medium-text"><strong>Описание: </strong>{shortDescription}</p>                 
                            <p style={{marginTop: '10px', fontWeight: 600, fontSize: '18px'}} className="general-text">{price}тг</p>
                        </div>
                        <div>
                        {isShowCart ? <div className="add-cart">
                            {/* <span className="tiny-text">Количество/кг: </span> */}
                            <InputNumber min={1} max={100} defaultValue={1} onChange={onChangeQuantity}  />
                            <Button onClick={() => handleOk({id: id, brand: {brandId: brand}, name: name, price: price, photo_1: img})} style={{color: `#000`, fontSize: '16px', height: '40px', width: '100%'}} type="primary"><ShoppingCartOutlined/>В корзину</Button>
                        </div> : ``}        
                    </div>
                        </div>                      
                        </div>                                             
                    </div>                
                </div>
                
            </Modal>
        </div>
    );
}
ShowCaseBrand.propTypes = {
    userClick: PropTypes.object,
    pagination: PropTypes.object,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
    products: PropTypes.array,
    actions: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    history: PropTypes.object,
    cartActions: PropTypes.object
};
const mapStateToProps = state => ({
    userClick: state.product.userClick,
    error: state.product.error,
    isLoading: state.product.isLoading,
    isAuthenticated: state.auth.isAuthenticated,
    cartResponse: state.cart.cartResponse,
    cart: state.cart.cart,
    shoppingList: state.cart.shoppingList
});
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(productActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps) (withRouter(ShowCaseBrand));