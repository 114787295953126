import * as types from './actionTypes';

export function getProduct(id) {
    return { type: types.GET_PRODUCT, id };
}

export function getProducts(product) {
    return { type: types.GET_PRODUCTS, product};
}

export function getSearchProducts(product) {
    return { type: types.GET_PRODUCTS_SEARCH, product};
}

export function createUserClick(productId) {
    return { type: types.CREATE_USER_CLICK, productId};
}

export function getMarketBrands(cityId) {
    return { type: types.GET_BRANDS, cityId };
}

export function getPopularProducts(product) {
    return { type: types.GET_POPULAR_PRODUCTS, product };
}

export function getNewProducts(product) {
    return { type: types.GET_NEW_PRODUCTS, product};
}