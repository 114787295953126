import React, {Component} from "react";
import {Result, Button} from "antd";
import {withRouter} from "react-router";

class Miscellaneous extends Component{
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    onClick = () => {
        this.props.history.push('/');
    }
    render() {
        return (
            <Result
                status="404"
                title="404"
                subTitle="Извините, данная страница не найдена."
                extra={<Button onClick={this.onClick} type="primary">Домой</Button>}
            />
        );
    }
}
export default withRouter(Miscellaneous);
