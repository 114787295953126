import React, {Component} from "react";
import HeaderTop from "../../components/header/headerTop";
import HeaderMain from "../../components/header/headerMain";
import Benefit from "../../components/benefit";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import {bindActionCreators} from "redux";
import * as categoryActions from "../../actions/categoryActions";
import * as productActions from "../../actions/productActions";
import './category.scss';
import {message, Spin, Input, Select, Pagination} from "antd";
import ShowCaseBrand from "../../components/showcase-brand";
import {CloseOutlined, CloseCircleFilled, FilterOutlined} from "@ant-design/icons";
import Footer from "../../components/footer";
const { Option } = Select;

class Category extends Component{
    constructor(props) {
        super(props);
        this.state = {
            lowCost: null,
            highCost: null,
            sortName: 'costAscend',
            isShowProduct: false,
            current: 1,
            isCategory: false,
            pageSize: 150,
            productsGroup: [],
            search: '',
            categoryId: '',
            products: [],
            pageNumber: 1,
            changedPageSize: false
        };
    }
    handleScroll = () => {
        const {pageSize} = this.state;
        const product = {
            superCategoryId: this.props.match.params.id,
            pageNumber: this.state.pageNumber + 1,
            pageSize: pageSize
        }
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight && this.state.products.length <= this.props.totalCountOfProducts) {
        this.setState({
            pageNumber: this.state.pageNumber + 1,
            current: this.state.current + 1
        })
        this.props.productActions.getProducts(product);
        } 
     }
    componentDidMount() {
        this.props.actions.getCategoriesBySuperId(this.props.match.params.id);
        const {pageSize} = this.state;
        const product = {
            superCategoryId: this.props.match.params.id,
            pageNumber: 1,
            pageSize: pageSize
        }
        window.addEventListener('scroll', this.handleScroll);
        this.props.productActions.getProducts(product);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }
    onChangeSort = e => {
        const {pageSize} = this.state;
        const filter = {
            pageNumber: 1,
            pageSize: pageSize,
            sortName: e,
            superCategoryId: this.props.match.params.id,
            categoryId: this.state.categoryId
        }
        this.setState({
            current: 1
        })
        this.props.productActions.getProducts(filter);
    }
    componentDidUpdate(prevProps) {
        const {pageSize} = this.state;
        const product = {
            superCategoryId: this.props.match.params.id,
            pageNumber: 1,
            pageSize: pageSize
        }
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.props.actions.getCategoriesBySuperId(this.props.match.params.id);
            this.props.productActions.getProducts(product);
        }
    }
    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
        if (!this.state.isCategory) {
            localStorage.setItem(`category`,
                nextProps.location.query?.category ? nextProps.location.query.category :
                    localStorage.category);
            localStorage.setItem(`sectionId`,
                nextProps.location.query && nextProps.location.query.sectionId ? nextProps.location.query.sectionId :
                    localStorage.sectionId);
            this.setState({
                isCategory: true,
                
            });
        }
        if (this.state.changedPageSize) {
            this.setState({
                products: nextProps.products,
                changedPageSize: false
            })
        } else if(nextProps.products !== this.props.products && !this.state.changedPageSize) {
            const {products} = nextProps
            this.setState({
                products: [...this.state.products, ...products],
               
            })
        }
    }
    
    onChange = page => {
        const {pageSize} = this.state;
        const product = {
            superCategoryId: this.props.match.params.id,
            pageNumber: page,
            pageSize: pageSize,
            categoryId: this.state.categoryId
        }
        this.setState({
            current: page,
            pageNumber: page
        })
        this.props.productActions.getProducts(product);
    }
    onChangePrice = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.value) {
            this.setState({
                isShowProduct: true
            })
        }
    }
    onChangeSearch = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.value) {
            this.setState({
                isShowProduct: true
            })
        }
    }
    filterProduct = () => {
        const {pageSize} = this.state;
        const filter = {
            pageNumber: 1,
            pageSize: pageSize,
            sortName: this.state.sortName,
            lowCost: this.state.lowCost,
            highCost: this.state.highCost,
            superCategoryId: this.props.match.params.id,
            categoryId: this.state.categoryId,
            search: this.state.search,
           
        }
        this.setState({
            changedPageSize: true
        })
        this.props.productActions.getProducts(filter);
    }
    closeShowProduct = () => {
        this.setState({
            isShowProduct: false,
            lowCost: '',
            highCost: '',
            current: 1,
            categoryId: null,
            search: '',
            changedPageSize: true
        })
        const filter = {
            superCategoryId: this.props.match.params.id,
            sortName: this.state.sortName,
            lowCost: '',
            highCost: '',
            pageNumber: 1,
            search: '',
            categoryId: null
        }
        this.props.productActions.getProducts(filter);
    }
    onSearch = value => {
        const product = {
            subCategoryId: this.props.match.params.id,
            search: value
        }
        this.props.productActions.getProducts(product);
    }
    closeCategory = id => {
        const {pageSize} = this.state;
        document.getElementById(id).className = 'category-element';
        const product = {
            pageNumber: this.state.current,
            categoryId: '',
            superCategoryId: this.props.match.params.id,
            pageSize: pageSize
        }
       
        this.props.productActions.getProducts(product);
    }
    handleCategory = (id, i) => {
        const {pageSize} = this.state;
        // const element = document.getElementsByClassName('category-element');
        // for( let i = 0; i < element.length; i++ ) {
        //     element[i].className = 'category-element';
        // }
        // document.getElementById(i).classList.add('category-element', 'category-element--active');
      
        this.setState({
            categoryId: id,
            current: 1
        })
        if (id) {
            this.setState({
                isShowProduct: true
            })
        }
    }
    
 
    onShowSizeChange = (value) => {
        this.setState({
            pageSize: value,
            changedPageSize: true,
            pageNumber: 1
        })
        const product = {
            pageNumber: 1,
            superCategoryId: this.props.match.params.id,
            pageSize: value,
            categoryId: this.state.categoryId
            // sectionId: this.props.match.params.id
        }
        this.props.productActions.getProducts(product);
    }
    render() {
        let breadCrumb = ``;
        const {pageSize, search, categoryId} = this.state;
        const { match, categoriesSuper, isLoading, error, sections,totalCountOfProducts, } = this.props;
        const {products} = this.state
        
        const { lowCost, highCost, isShowProduct, current } = this.state;
        // const foundSection = sections.find((item) => item.id === this.props.location.query &&
        // this.props.location.query.sectionId ?
        //     this.props.location.query.sectionId : localStorage.sectionId);
        // const categoryItem = categoriesSuper.map((item, i) => (
        //     <div id={i} key={i} className="category-element">
        //         <CloseCircleFilled onClick={() => this.closeCategory(i)}/>
        //         <div style={{display: 'flex', textAlign: 'center', alignItems: 'center'}} onClick={() => this.handleCategory(item.id, i)}  >
        //             <p>{item.name}</p>
        //         </div>
        //     </div>
        // ));
        const filterCategories = categoriesSuper.map((item, i) => <Option value={item.id} key={i}>{item.name}</Option>)
    
        if (error && error.response) {
            message.error(error.response.data);
        }
        const pagination = <div className="pagination-brand">
            <span>Показывать по</span>
             <Select value={pageSize} onChange={this.onShowSizeChange}>
                        <Option value="20">20</Option>
                        <Option value="50">50   </Option>
                        <Option value="100">100</Option>
                        <Option value="150">150</Option>
                    </Select>
            {/* <Pagination 
                onShowSizeChange={this.onShowSizeChange} 
                showSizeChanger 
                current={current} 
                pageSize={pageSize} 
                onChange={this.onChange} 
                pageSizeOptions={[10,20,50,100,150]}
                total={totalCountOfProducts}
            /> */}
        </div>;
        breadCrumb = <div className="breadcrumb">
            <Link className="tiny-text" to={`/`}>Главная</Link>
            <span className="tiny-text"> / </span>
        </div>;
        return (
            <Spin spinning={isLoading} onScroll={this.handleScroll}>
                <HeaderTop/>
                <HeaderMain superCategoryId={match.params.id}/>
                <div className="wrapper">
                <Benefit/>
                    <div className="section">
                        <div className="container">
                            {breadCrumb}
                            <p className="large-text">{products?.length ? products[0]?.superCategory?.name : localStorage.category}</p>
                        </div>
                    </div>
                    {pagination}
                    
                    {/* <Select value={pageSize} >
                        <Option value="20">20</Option>
                        <Option value="50">50   </Option>
                        <Option value="100">100</Option>
                        <Option value="150">150</Option>
                    </Select> */}
                    <div className="showcase-filter">
                        <div className="container">
                            <div className="showcase-filter-cat--inner">
                                <div className="filter-category">
                                    <div className="sticky-filter">
                                        <div className="sticky-filter-inner">       
                                        <p className="large-text"><FilterOutlined style={{color: `#ffc700`}}/> Фильтры</p>
                                        <div className="sort">
                                   
                                         <div>
                                            <label>Поиск по продукту</label>
                                            <Input name="search" onChange={this.onChangeSearch} value={search} placeholder="Введите наименование продукта" />
                                        </div>
                                        <label>Категория</label>
                                        <Select value={categoryId}  onSelect={this.handleCategory} placeholder="Выберите категорию" width='100%'>
                                            {filterCategories}
                                        </Select>
                                       
                                    </div>
                                    <div className="sort">
                                    <label>Сортировка</label>
                                        <Select defaultValue='costAscend' width='100%' onChange={this.onChangeSort}>
                                            <Option value='costAscend'>По возрастанию цены</Option>
                                            <Option value='costDescend'>По убыванию цены</Option>
                                            <Option value='createdDate'>По новинкам</Option>
                                            <Option value='userClick'>По популярности</Option>
                                        </Select>
                                    </div>
                                    <span style={{color: `#9B9B9B`}}>Поиск по цене</span>
                                    <div className="price">
                                        <div>
                                            <label>От</label>
                                            <Input type="number" name="lowCost" value={lowCost} onChange={this.onChangePrice} placeholder="От"/>
                                        </div>
                                        <div>
                                            <label>До</label>
                                            <Input type="number" name="highCost" value={highCost} onChange={this.onChangePrice} placeholder="До"/>
                                        </div>
                                    </div>    
                                   
                                    { isShowProduct ? <div className="filter-but">
                                        <div className="show-filter--category">
                                            <p onClick={this.filterProduct}>Показать товары</p>
                                            <CloseOutlined onClick={this.closeShowProduct}/>
                                        </div></div>: `` }               
                                    </div>
                                </div>
                                </div>

                               
                                <ShowCaseBrand pagination={pagination} products={products?.length > 0 ? products : []}/>
                           
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Spin>
        );
    }
}
Category.propTypes = {
    actions: PropTypes.object.isRequired,
    sections: PropTypes.array,
    location: PropTypes.object,
    query: PropTypes.string,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    categoriesSuper: PropTypes.array
};
const mapStateToProps = state => ({
    categoriesSuper: state.category.categoriesSuper,
    sections: state.category.parentCategories,
    error: state.category.error,
    isLoading: state.product.isLoading,
    products: state.product.products.productResponses,
    totalCountOfProducts: state.product.products.totalCountOfProducts,
});
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(categoryActions, dispatch),
    productActions: bindActionCreators(productActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps) (Category);
