import React from 'react';
import {Button, Input, Modal, Spin} from "antd";
import PropTypes from "prop-types";

function NewPassword(props) {
    const { passwordVisible, isLoading, onChange, newPassword, changePassword } = props;
    return(
        <Modal
            closable={false}
            title="Новый пароль"
            visible={passwordVisible}
            footer={null}
        >
            <Spin spinning={isLoading}>
                <div className="password-forgot">
                    <p className="medium-text" align="center">Введите новый пароль</p>
                    <Input.Password name='newPassword' value={newPassword} onChange={onChange}
                        placeholder="Введите новый пароль"/>
                    <Button onClick={changePassword}
                        style={{width: `100%`, color: `#000`, height: `36px`, borderRadius: `5px`}}
                        type="primary">Отправить</Button>
                </div>
            </Spin>
        </Modal>
    );
}

NewPassword.propTypes = {
    passwordVisible: PropTypes.bool,
    isLoading: PropTypes.bool,
    onChange: PropTypes.func,
    changePassword: PropTypes.func,
    newPassword: PropTypes.string
};

export default NewPassword;

