import '@csstools/normalize.css';
import 'antd/dist/antd.css';
import 'antd-mobile/dist/antd-mobile.css';
import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import './App.scss';
import './font/stylesheet.scss';
import Main from "./containers/main";
import Brands from "./containers/brands";
import Brand from "./containers/brand";
import Product from "./containers/product";
import Products from "./containers/products";
import {Provider} from "react-redux";
import configureStore from './store';
import Section from "./containers/sections";
import Epay from "./containers/e-pay";
import Basket from "./containers/basket";
import Favorite from "./containers/favorite";
import Miscellaneous from "./containers/miscellaneous";
import ResultSearch from './containers/result';
import Category from "./containers/category";
import jwt_decode from 'jwt-decode';
import setAuthToken from "./utils/setAuthToken";
import * as types from './actions/actionTypes';
import Account from "./containers/account";
import Order from "./containers/order";
import Bonus from "./containers/bonus";
import BrandCategory from "./containers/brand-category";
import ScrollIntoView from "./components/scrollView";
import { ConfigProvider } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';
import moment from "moment";
import 'moment/locale/ru';
import { signOut } from './actions/authActions';
import Purchase from './containers/purchase';
const store = configureStore();
moment.locale(`ru`);
if (localStorage.token) {
    setAuthToken(localStorage.token);
    const decoded = jwt_decode(localStorage.token);
    store.dispatch({type: types.SET_CURRENT_USER, payload: decoded});
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        store.dispatch(signOut());
        // window.location.href = `/`;
    }
}
function App() {
    return (
        <ConfigProvider locale={ruRU}>
            <div className="App">
                <Provider store={store}>
                    <Router>
                        <ScrollIntoView>
                            <Switch>
                                <Route exact path={`/`} component={Main}/>
                                <Route exact path={`/brands`} component={Brands}/>
                                <Route exact path={`/bonuses`} component={Bonus}/>
                                <Route exact path={`/brands/:id`} component={Brand}/>
                                <Route exact path={`/product/:id`} component={Product}/>
                                <Route exact path={`/category/:id`} component={Category}/>
                                <Route exact path={`/result`} component={ResultSearch}/>
                                {/* <Route exact path={`/subcategory/:id`} component={SubCategory}/> */}
                                <Route exact path={`/section/:id`} component={Section}/>
                                <Route exact path={`/cart`} component={Basket}/>
                                <Route exact path={`/favorite`} component={Favorite}/>
                                <Route exact path={`/account`} component={Account}/>
                                <Route exact path={`/e-pay`} component={Epay}/>
                                <Route exact path={`/order`} component={Order}/>
                                <Route exact path={`/purchase-list`} component={Purchase}/>
                                {/* <Route exact path={`/products`} component={Products}/> */}
                                <Route exact path={`/sections/brand/:id`} component={BrandCategory}/>
                                <Redirect to={Miscellaneous} />
                            </Switch>
                        </ScrollIntoView>
                    </Router>
                </Provider>
            </div>
        </ConfigProvider>
    );
}

export default App;
