import React from 'react';
import './benefit.scss';

function Benefit(){
    return (
        <div className="benefit">
            {/* <div className="container">
                <div className="benefit-inner">
                    <div className="benefit-first">
                        <div className="benefit-first--items">
                            <div className="benefit-first--item">
                                <img src="/assets/icons/shield.svg" alt='shield'/>
                                <p className="general-text">Защита покупателя</p>
                            </div>
                            <div className="benefit-first--item">
                                <div className="benefit-first--images">
                                    <a href='https://apps.apple.com/kz/app/smartplaza/id1298078049'>
                                        <img src="/assets/icons/appstore.png" alt='appStore'/>
                                    </a>
                                    <a href='https://play.google.com/store/apps/details?id=kz.smartplaza'>
                                        <img src="/assets/icons/playmarket.png" alt='playMarket'/>
                                    </a>
                                </div>
                                <p className="general-text">Скачать приложение</p>
                            </div>
                            <div className="benefit-first--item">
                                <img src="/assets/icons/wallet.svg" alt='wallet'/>
                                <p className="general-text">Рассрочка</p>
                            </div>
                        </div>
                    </div>
                    <div className="benefit-second">
                        <div className="benefit-second--text">
                            <p>Хотите получать выгодные предложения от партнеров?</p>
                            <button>Конечно!</button>
                        </div>
                        <div className="benefit-second--image">
                            <img src="/assets/plaza.svg" alt="plaza"/>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );

}

export default Benefit;