import { combineReducers } from 'redux';
import category from "./categoryReducer";
import product from "./productReducer";
import favorite from "./favoriteReducer";
import cart from "./cartReducer";
import error from "./errorReducer";
import auth from "./authReducer";
import user from "./userReducer";
import payment from "./paymentReducer";
import bonus from "./bonusReducer";
import banner from "./bannerReducer";

export default combineReducers({
    category,
    product,
    favorite,
    error,
    cart,
    auth,
    user,
    payment,
    bonus,
    banner
});