import React from "react";
import './footer.scss';
import {Link} from 'react-router-dom';
function Footer() {
    return(
        <footer className="footer">
            <div className="container">
                <div className="footer-inner">
                    <div className="footer-first">
                        <ul>
                            <li>© 2020</li>
                            <li>Служба поддержки: <span className="footer-number">+7 (727) 222-055</span></li>
                            <li>
                                <a target="_blank" href={`https://instagram.com/smartplaza_kz?igshid=1pshzc9uhrrr7`}>
                                    <img src="/assets/social-media/insta.svg" alt="insta"/></a>
                            </li>
                            {/*<li>О нас</li>*/}
                            {/*<li>Контакты</li>*/}
                            {/*<li>Публичная офферта</li>*/}
                        </ul>
                    </div>
                    <div className="footer-second">
                        {/*<img src="/assets/social-media/telegram.svg" alt="telegram"/>*/}
                        {/*<img src="/assets/social-media/vk.svg" alt="vk"/>*/}

                    </div>
                </div>
            </div>
        </footer>
    );
}
export default Footer;