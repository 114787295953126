import React, {Component} from 'react';
import Modal from "antd/es/modal";
import PropTypes from "prop-types";
import './payment.scss';
import {Button, Input, Select, message, Spin, Radio, Switch, Checkbox} from "antd";
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import * as paymentActions from "../../actions/paymentActions";
import * as userActions from "../../actions/userActions";
import * as bonusActions from "../../actions/bonusActions";
import {
    DeleteOutlined,
    PlusCircleOutlined,
    EditOutlined,
    UserOutlined,
    FieldTimeOutlined,
    CreditCardOutlined, 
    CarOutlined
} from "@ant-design/icons";
import Form from "antd/es/form";
import MaskedInput from "antd-mask-input";
import Empty from "antd/es/empty";
import {withRouter} from "react-router";
import Recipient from "../recipient";
const { Option } = Select;
const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);

class Payment extends Component{
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            addVisible: false,
            name: '',
            surname: '',
            editName: '',
            editSurname: '',
            editPhone: '',
            editAddress: '',
            editDeliveryTime: '',
            phone: '',
            cityId: '2',
            address: '',
            delivery_time: '',
            disabled: false,
            switchBonus: false,
            choseAddress: '',
            recipientId: '',
            chosenUser: '',
            delivery_method: 0,
            editVisible: false,
            editId: null,
            chosenTypePayment: false,
            cash: 0,
            creditCard: 0,
            date: now,
            coordinates: null,
            chosenMethod: '',
            deliveryMethod: 'delivery',
            disabledHour: '',
            numOfBuilding: '',
            deliveryId: '',
            floorDoor: '',
            editNumOfBuilding: '',
            editFloorDoor: '',
            isLoadingButton: false,
            pickup: false,
            bonus: 0,
            bonusText: '',
            isRecipient: false,
            offer: false,
        };
    }

    handleCancel = () => {
        this.setState({
            visible: false
        })
    }
    handleAddCancel = () => {
        this.setState({
            addVisible: false
        })
    }
    openModal = () => {
        this.setState({
            visible: true
        })
    }
    onSelect = e => {
        this.setState({
            cityId: e
        })
    }
    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    componentDidMount() {
        this.props.paymentActions.getRecipients();
        this.props.userActions.getProfile();
        this.props.paymentActions.getCards();
        this.props.bonusActions.getBonus();
        
    }
    backRecipient = () => {
        this.setState({
            addVisible: false,
            visible: true
        })
    }
    editBackRecipient = () => {
        this.setState({
            editVisible: false,
            visible: true
        })
    }
    handleOkRecipient = () => {
        const {  name, surname, phone, address, cityId, numOfBuilding, floorDoor } = this.state;
        const data = {
            name: name,
            surname: surname,
            phoneNumber: phone,
            street: address,
            cityId: cityId,
            numOfBuilding: numOfBuilding,
            floorDoor: floorDoor,
        }
        if (name && surname && phone && cityId && numOfBuilding) {
            this.props.paymentActions.addRecipient(data);
            this.handleAddCancel();
        } else {
            message.error('Заполните все поля!')
        }
    }
    handleEditRecipient = () => {
        const {  editName, editSurname, editPhone, editAddress, cityId, editId, editFloorDoor, editNumOfBuilding } = this.state;
        const data = {
            name: editName,
            surname: editSurname,
            phoneNumber: editPhone,
            street: editAddress,
            cityId: cityId,
            floorDoor: editFloorDoor,
            numOfBuilding: editNumOfBuilding,
            id: editId
        }
        if (editName && editSurname && editPhone && cityId && editFloorDoor && editNumOfBuilding && editAddress) {
            this.props.paymentActions.editRecipient(data);
        } else {
            message.error('Заполните все поля!')
        }
    };
    handleEditCancel = () => {
        this.setState({
            editVisible: false
        })
    };
    onSelectMethod = e => {
        if (e === '1') {
            this.setState({
                address: 'мкр. Самал-2, 111',
                disabled: true,
                deliveryMethod: 'pickup'
            })
        } else {
            this.setState({
                address: '',
                disabled: false,
                deliveryMethod: 'delivery'
            })
        }
    }
    openAddRecipient = () => {
        this.setState({
            addVisible: true,
            visible: false,
        })
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.isRecipient)  {
        this.setState({
            name: nextProps.profile.name,
            surname: nextProps.profile.surname,
            phone: nextProps.profile.phone,
            cityId: String(nextProps.profile.cityId),
            chosenUser: nextProps.recipients[0] ? `${nextProps.recipients[0].surname} ${nextProps.recipients[0].name}` : '',
            choseAddress: nextProps.recipients[0] ?
                `Улица/мкр: ${nextProps.recipients[0].street}, дом/здание: ${nextProps.recipients[0].numOfBuilding}, этаж/кв: ${nextProps.recipients[0].floorDoor} ` : ``,
            recipientId: nextProps.recipients[0] ? nextProps.recipients[0].id : null,
            cardId: nextProps.cards[0] ? nextProps.cards[0].card_id : null,
            cash: 0,
            chosenMethod: nextProps.recipients[0] ? `${nextProps.recipients[0].phoneNumber} ${nextProps.recipients[0].phoneNumber}` : '',
            creditCard: this.props.total
        })
        if (nextProps.editRecipientResponse) {
            this.setState({
                editVisible: false,
            })
        }
        if (nextProps.recipientResponse) {
            this.setState({
                addVisible: false,
                visible: true
            })
        }
    }
        if (nextProps.recipients[0] && nextProps.recipients[0].id && !this.state.isRecipient) {
            this.props.paymentActions.getTime(nextProps.recipients[0] ? nextProps.recipients[0].id : null);
            this.setState({
                isRecipient: true
            })
        }

       
    }
    chooseRecipient = item => {   
       if (item.id) {
        this.setState({
            visible: false,
            choseAddress: `Улица/мкр: ${item.street}, дом/здание: ${item.numOfBuilding}, этаж/кв: ${item.floorDoor} `,
            chosenUser: `${item.surname} ${item.name}`,
            recipientId: item.id,         
        })
        this.props.paymentActions.getTime(item.id);
       }
    }
    openEditModal = item => {
        this.setState({
            editName: item.name,
            editSurname: item.surname,
            editPhone: item.phoneNumber,
            editNumOfBuilding: item.numOfBuilding,
            editFloorDoor: item.floorDoor,
            editAddress: item.street,
            cityId: String(item.cityId),
            editId: item.id,
            editVisible: true
        })
    }
    deleteRecipient = id => {
        this.props.paymentActions.deleteRecipient(id);
        this.setState({
            choseAddress: '',
            chosenUser: '',
        })
    }
    deleteCard = id => {
        this.props.paymentActions.deleteCard(id)
    }
    choosePayment = (id, i) => {
        const { total } = this.props;
        this.setState({
            cardId: id,
        })
        if (i === 'cash') {
            const cardElements = document.getElementsByClassName('payment-user-card');
            for ( let i = 0; i < cardElements.length; i++ ) {
                cardElements[i].className = 'payment-user-card';
            }
            document.getElementById(i).className = 'payment-card-active payment-card';
            this.setState({
                cash: total,
                creditCard: 0
            })
        } else {
            const elements = document.getElementsByClassName('payment-user-card');
            // document.getElementById('cash').className = 'payment-card'
            for ( let i = 0; i < elements.length; i++ ) {
                elements[i].className = 'payment-user-card';
            }
            document.getElementById(i).className = 'payment-user-card-active payment-user-card '
            this.setState({
                creditCard: total,
                cash: 0
            })
        }
    }
    addCard = () => {
        this.props.paymentActions.addCard(this.props.history);
    }
    addOrder = () => {
        const { cash, cardId, bonus, switchBonus, delivery_time, deliveryId } = this.state;
        const { delMethod, sum, total, activeBonus, brandCollectable } = this.props;
        const totalPurchase = (switchBonus && total < activeBonus.activeBonuses) ? 0 : (switchBonus && sum > activeBonus.activeBonuses) ? total - activeBonus.activeBonuses : total;
        const data = {
            bonus: bonus,
            cash: cash,
            fullBonus: bonus === total ? true : false,
            creditCard: (Math.round(totalPurchase * 100) / 100).toFixed(2),
            recipientId: delMethod === "delivery" ?  this.state.recipientId : null,
            deliveryMethod: delMethod,
            deliveryId: deliveryId,
            delivery_time: delivery_time,
            cardId: !brandCollectable ? cardId : null,
            brandCollectable: brandCollectable,
        }
        if (this.state.delivery_time) {
            this.props.paymentActions.addOrder(data, this.props.history);
       }
    }

    selectTime = (e, option) => {
        this.setState({
            delivery_time: option.children,
            deliveryId: option.value
        })
    }
    onSwitch = e => {
        const { activeBonus, total } = this.props;
        this.setState({
            switchBonus: e,
        });
        if (e && total < activeBonus.activeBonuses) {
            this.setState({
                bonus: total,
            })
        } else if (e && total > activeBonus.activeBonuses) {
            this.setState({
                bonus: activeBonus.activeBonuses,
            })
        } else {
            this.setState({
                bonus: 0,
            })
        }
    }
    onChangePublic = e => {
        this.setState({
            offer: e.target.checked
        })
    }
    render() {
        const { switchBonus, bonusText, editNumOfBuilding, offer,  editFloorDoor, visible, addVisible, name, surname, phone, address, cityId, editVisible, bonus,
            choseAddress, chosenUser, editName, editSurname, editPhone, editAddress, delivery_time, floorDoor, numOfBuilding } = this.state;
        const { brandId, time, brandCollectable, activeBonus, pickup, isLoading, recipients, cards, isLoadingOrder, onChangeRadio, delMethod, brandsTrue, delivery, total, sum, comission } = this.props;
        let recipientItems, recipientChosen;
        let userCards = [], totalPurchase = total, sumPurchase = sum;
        let timeOptions = [];
        let service = comission + delivery;
        //const sumPurchase = (switchBonus && sum < activeBonus.activeBonuses) ? 0 : (switchBonus && sum > activeBonus.activeBonuses) ? sum - activeBonus.activeBonuses : sum;
        if (switchBonus && total < activeBonus.activeBonuses) {
            totalPurchase = 0;
        } else if (switchBonus && total > activeBonus.activeBonuses) {
            totalPurchase = total - activeBonus.activeBonuses;
        }  else {
            totalPurchase = total;
        } ;
        
        if (recipients && recipients.length > 0) {
            recipientItems = recipients.map((item, i) => (
                <div key={i}  className="modal-recipient">
                    <div  onClick={() => {this.chooseRecipient(item) }}>{`Улица/мкр: ${item.street}, дом: ${item.numOfBuilding}`}</div>
                    <div>
                        <EditOutlined onClick={() => this.openEditModal(item)}/>
                        <DeleteOutlined onClick={() => this.deleteRecipient(item.id)}/>
                    </div>
                </div>
            ))
        } else {
            recipientItems = <Empty description="Пусто" />
        }
        if (!pickup) {
            recipientChosen = <div className="recipient-item-chosen">
                <div>
                    <span className="tiny-text">Адрес</span>
                    <p className="general-text">{choseAddress}</p>
                </div>
                <div>
                    <span className="tiny-text">Получатель</span>
                    <p className="general-text">{chosenUser}</p>
                </div>
                <div>
                    <span className="tiny-text" onClick={this.openModal}>Редактировать</span>
                </div>
            </div>
        } else {
            recipientChosen = <div className="recipient-item">
                <Button disabled={pickup} type="primary" onClick={this.openModal}><PlusCircleOutlined/>Указать получателя</Button>
            </div>
        }
        if (cards && cards.length > 0 ) {
            userCards = cards.map((item, i) => (
                i === 0 ? <div id={i} className="medium-text payment-user-card payment-user-card-active" key={i}>
                    <span onClick={() => this.choosePayment(item.card_id, i)}>{item.masked_number}</span>
                    <DeleteOutlined onClick={() => this.deleteCard(item.card_id)}/>
                </div> : <div id={i} className="medium-text payment-user-card " key={i}>
                    <span onClick={() => this.choosePayment(item.card_id, i)}>{item.masked_number}</span>
                    <DeleteOutlined onClick={() => this.deleteCard(item.card_id)}/>
                </div>
            ))
        } else {
            userCards = <Empty description="Нет карты"/>
        }
       
        if (brandId == 46) {
            timeOptions = time.map((item, i) => {
                if (item.DISABLED) {
                   return <Option key={i}  disabled={true} value={item.ID}>{item.DATE_TIME_FORMATED}</Option>
                } else {
                   return <Option key={i}  value={item.ID}>{item.DATE_TIME_FORMATED}</Option>
                }
                
            })
        } else {
        for (let i = 10; i < 20; i = i + 2) {
                    if (new Date().getHours() === i || new Date().getHours() > i)  {
                        timeOptions.push(<Option key={i} disabled={true} value={`${i}:00-${i+2}:00`}>{i}:00-{i+2}:00</Option>);
                    } else {
                        timeOptions.push(<Option key={i} value={`${i}:00-${i+2}:00`}>{i}:00-{i+2}:00</Option>);
                    }
                }
        }
      
        
        return (
            <Spin spinning={isLoading}>
                <div className="payment">
                    <div className="payment-inner">
                        <p className="payment-title">Оформление заказа</p>
                        <div className="payment-item">
                            <div className="payment-client">
                                <UserOutlined className="payment-icon"/>
                                <div className="payment-over">
                                    <span className="payment-text">О клиенте</span>
                                    {recipientChosen}
                                </div>
                            </div>
                        </div>
                        <div className="payment-item--inner">
                            <div>
                                <FieldTimeOutlined className="payment-icon"/>
                                <div className="payment-over">
                                    <span className="payment-text">Время доставки</span>
                                    <div className="recipient-item">
                                        <Select onChange={this.selectTime} placeholder="Выберите время">
                                           {timeOptions}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <CarOutlined className="payment-icon"/>
                                <div className="payment-over">
                                    <span className="payment-text">Тип заказа</span>
                                    <div className="recipient-item">
                                        <Radio.Group onChange={onChangeRadio} value={delMethod}>
                                            <Radio value={'delivery'}>Доставка</Radio>
                                            {brandId !== 46 ? <Radio value={'pickup'}>Самовывоз</Radio> : ``}
                                        </Radio.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!brandCollectable ? <div className="payment-item">
                            <div className="payment-item--inner">
                                <div>
                                    <CreditCardOutlined className="payment-icon"/>
                                    <div className="payment-over">
                                        <span className="payment-text">Платежная информация</span>
                                        <div className="payment-method">
                                            <div className="payment-item--inner" onClick={this.addCard}>
                                                <div className="payment-card"><PlusCircleOutlined/>Добавить новую карту
                                                </div>
                                            </div>
                                            {/*<div  onClick={() => this.choosePayment('', 'cash')} className=" payment-item--inner">*/}
                                            {/*    <div id="cash" className="payment-card">Оплата наличными</div>*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className="payment-user-cards">
                                            {userCards}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ''}    
                        <div className="payment-bonus">
                            <div className="payment-bonus--item">
                                <p className="general-text">Ваш бонус: {activeBonus.activeBonuses}тг</p>
                                {!brandCollectable ? 
                                <span className="general-text">Потратить бонус:  <Switch checked={switchBonus} onChange={this.onSwitch}/></span> : 
                                `Оплата бонусами идет при оплате заказа`}
                                <div className="public-offer">
                                    <p>Я согласен(-на) с условиями <a href="http://api.smartplaza.kz/news/api/publicoffer" target="_blank">Публичной Офферты</a></p>
                                    <Checkbox onChange={this.onChangePublic}/>
                                </div>                           
                            </div>
                            <div className="vertical-line"/>
                            <div className="payment-bonus--item">
                                <div className="payment-titles">
                                    <p className="general-text">Сумма:</p>
                                    <span className="general-text">{sumPurchase}тг</span>
                                </div>
                                <div className="payment-titles">
                                    <p className="general-text">Сервисный сбор:</p>
                                    <span className="general-text">{comission+delivery}тг</span>
                                </div>
                                <div className="horizontal-line"/>
                                <div className="payment-titles">
                                    <p className="large-text">К оплате: </p>
                                    <span className="large-text">{(Math.round(totalPurchase * 100) / 100).toFixed(2)}тг</span>
                                </div>
                            </div>

                        </div>
                        <div className="payment-footer">
                            <Button disabled={bonusText.length  || isLoading ||  isLoadingOrder || brandsTrue.includes('false') || !offer || !delivery_time} onClick={this.addOrder}
                                    type="primary">Оформить заказ</Button>
                        </div>
                    </div>
                    <Modal
                        title="Получатели"
                        visible={visible}
                        onCancel={this.handleCancel}
                        footer={null}
                    >
                        <div className="modal-rec">
                            {recipientItems}
                            <Button onClick={this.openAddRecipient} type="primary"><PlusCircleOutlined/>Добавить
                                получателя</Button>
                        </div>
                    </Modal>
                    <Recipient 
                        onSelect={this.onSelect} 
                        handleAddCancel={this.handleAddCancel} 
                        name={name} 
                        addVisible={addVisible} 
                        address={address} 
                        onChange={this.onChange} 
                        backRecipient={this.backRecipient}
                        handleOkRecipient={this.handleOkRecipient} 
                        cityId={cityId} 
                        floorDoor={floorDoor} 
                        numOfBuilding={numOfBuilding}
                        phone={phone} 
                        surname={surname}
                    />
                    <Modal
                        title="Редактирование получателя"
                        visible={editVisible}
                        onOk={this.handleEditRecipient}
                        onCancel={this.handleEditCancel}
                        footer={[
                            <Button onClick={this.editBackRecipient} key="back">Назад</Button>,
                            <Button onClick={this.handleEditRecipient} key="ok">Сохранить</Button>
                        ]}
                    >
                        <Form>
                            <Form.Item>
                                <label className="smallest-text">Фамилия</label>
                                <Input placeholder="Фамилия" name="editSurname" onChange={this.onChange}
                                       value={editSurname}/>
                            </Form.Item>
                            <Form.Item>
                                <label className="smallest-text">Имя</label>
                                <Input placeholder="Имя" name="editName" onChange={this.onChange} value={editName}/>
                            </Form.Item>
                            <Form.Item>
                                <label className="smallest-text">Номер телефона</label>
                                <MaskedInput value={editPhone} name="editPhone" onChange={this.onChange}
                                             mask="+1(111)-111-11-11" placeholder="+7(777)-777-77-77"/>
                            </Form.Item>
                            <Form.Item>
                                <label className="smallest-text">Улица или микрорайон</label>
                                <Input value={editAddress}  name="editAddress"
                                       onChange={this.onChange} placeholder="Укажите улицу или микрорайон"/>
                            </Form.Item>
                            <Form.Item>
                                <label className="smallest-text">Номер дома или название здания</label>
                                <Input value={editNumOfBuilding}  name="editNumOfBuilding"
                                       onChange={this.onChange} placeholder="Укажите номер дома или название здания"/>
                            </Form.Item>
                            <Form.Item>
                                <label className="smallest-text">Этаж, квартира</label>
                                <Input value={editFloorDoor}  name="editFloorDoor"
                                       onChange={this.onChange} placeholder="Укажите этаж/кв"/>
                            </Form.Item>
                            <Form.Item>
                                <label className="smallest-text">Город</label>
                                <Select onChange={this.onSelect} value={cityId} placeholder="Выберите город">
                                    <Option value='2'>Алматы</Option>
                                    <Option value='3'>Шымкент</Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </Spin>
        );
    }
}

Payment.propTypes = {
    visible: PropTypes.bool,
    isLoading: PropTypes.bool,
    recipients: PropTypes.array,
    profile: PropTypes.object,
    cards: PropTypes.array,
    isLoadingOrder: PropTypes.bool,
    activeBonus: PropTypes.object,
    time: PropTypes.array
};
const mapStateToProps = state => ({
    error: state.payment.error,
    isLoading: state.payment.isLoading,
    isLoadingOrder: state.payment.isLoadingOrder,
    recipients: state.payment.recipients,
    recipientResponse: state.payment.recipientResponse,
    editRecipientResponse: state.payment.editRecipientResponse,
    profile: state.user.profile,
    cards: state.payment.cards,
    activeBonus: state.bonus.bonus,
    time: state.payment.time,
});
const mapDispatchToProps = dispatch => ({
    paymentActions: bindActionCreators(paymentActions, dispatch),
    bonusActions: bindActionCreators(bonusActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps) (withRouter(Payment));