
import {all, put, takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import * as types from '../actions/actionTypes';

function* fetchSections() {
    try {
        const sections = yield axios.get(
            `${types.ENDPOINT}/mp/products/sections`).then(res => res.data);
        yield put({type: types.SECTIONS_RECEIVED, payload: sections});
    } catch (error) {
        yield put({type: types.SECTIONS_FAILED, error});
    }
}
function* fetchCategories(action) {
    const { id } = action;
    try {
        const categories = yield axios.get(
            `${types.ENDPOINT}/mp/products/sections/category/${id}`).then(res => res.data);
        yield put({type: types.CATEGORIES_RECEIVED, payload: categories});
    } catch (error) {
        yield put({type: types.CATEGORIES_FAILED, error});
    }
}
function* fetchSuperCategories(action) {
    const { brandId, search } = action;
    try {
        const categories = yield axios.get(
            `${types.ENDPOINT}/mp/products/supercategory/brand?brandId=${brandId}&search=${search}`).then(res => res.data);
        yield put({type: types.SUPERCATEGORIES_RECEIVED, payload: categories});
    } catch (error) {
        yield put({type: types.SUPERCATEGORIES_FAILED, error});
    }
}
function* fetchCategoriesBySuper(action) {
    const { id } = action;
    try {
        const categories = yield axios.get(
            `${types.ENDPOINT}/mp/products/supercategory/categories?superCategoryId=${id}`).then(res => res.data);
        yield put({type: types.CATEGORIES_SUPER_RECEIVED, payload: categories});
    } catch (error) {
        yield put({type: types.CATEGORIES_SUPER_FAILED, error});
    }
}
function* fetchSubcategoriesByCategoryId(action) {
    const { id } = action;
    try {
        const subcategories = yield axios.get(
            `${types.ENDPOINT}/mp/products/subCategories/${id}`).then(res => res.data);
        yield put({type: types.SUBCATEGORIES_RECEIVED, payload: subcategories});
    } catch (error) {
        yield put({type: types.SUBCATEGORIES_FAILED, error});
    }
}
export function* fetchTotalSections(action) {
    const {cityId} = action;
    try {
        const totalSections = yield axios.get(
            `${types.ENDPOINT}/mp/products/sections/totalInfo?cityId=${cityId}`).then(res => res.data);
        yield put({type: types.SECTION_TOTAL_RECEIVED, payload: totalSections});
    } catch (error) {
        yield put({type: types.SECTION_TOTAL_FAILED, error});
    }
}
function* fetchBrandSections(action) {
    const { cityId } = action;
    try {
        const brandSections = yield axios.get(
            `${types.ENDPOINT}/mp/products/sections/brands/web?cityId=${cityId}`).then(res => res.data);
        yield put({type: types.BRAND_SECTION_RECEIVED, payload: brandSections});
    } catch (error) {
        yield put({type: types.BRAND_SECTION_FAILED, error});
    }
}
function* fetchBrandCat(action) {
    const { id, search } = action;
    try {
        const brandCat = yield axios.get(
            `${types.ENDPOINT}/mp/products/categories/brand?brandId=${id}&search=${search}`).then(res => res.data);
        yield put({type: types.BRAND_CAT_RECEIVED, payload: brandCat});
    } catch (error) {
        yield put({type: types.BRAND_CAT_FAILED, error});
    }
}
function* fetchCategoriesAndSubcategories(action) {
    const { id, search } = action;
    try {
        const catAndSubcats = yield axios.get(
            `${types.ENDPOINT}/mp/products/sections/${id}?search=${search}`).then(res => res.data);
        yield put({type: types.SECTION_CAT_SUBCAT_RECEIVED, payload: catAndSubcats});
    } catch (error) {
        yield put({type: types.SECTION_CAT_SUBCAT_FAILED, error});
    }
}

export function* categorySaga() {
    yield all([
        yield takeLatest(types.GET_SECTIONS, fetchSections),
        yield takeLatest(types.GET_SECTION_TOTAL, fetchTotalSections),
        yield takeLatest(types.GET_CAT_SUBCAT, fetchCategoriesAndSubcategories),
        yield takeLatest(types.GET_CATEGORIES, fetchCategories),
        yield takeLatest(types.GET_SUBCATEGORIES, fetchSubcategoriesByCategoryId),
        yield takeLatest(types.GET_BRAND_CAT, fetchBrandCat),
        yield takeLatest(types.GET_BRAND_SECTION, fetchBrandSections),
        yield takeLatest(types.GET_SUPERCATEGORIES, fetchSuperCategories),
        yield takeLatest(types.GET_CATEGORIES_SUPER, fetchCategoriesBySuper),
    ]);
}
