import * as types from './actionTypes';

export function addCart(data, handleVisible, uid) {
    return { type: types.ADD_CART, data, handleVisible, uid };
}
export function addShoppingList(data, isAuth) {
    return { type: types.SHOPPING_LIST_ADD, data, isAuth};
}
export function getCart(uid) {
    return { type: types.GET_CART, uid };
}
export function getShoppingList(uid) {
    return { type: types.GET_SHOPPING_LIST, uid };
}
export function deleteCart(id, uid) {
    return { type: types.REMOVE_CART, id, uid };
}
export function deleteShoppingList(id, isAuth) {
    return { type: types.SHOPPING_LIST_DELETE, id, isAuth };
}
export function deleteAllCart(uid) {
    return { type: types.DELETE_ALL_CART, uid };
}
export function updateCount(data, uid) {
    return { type: types.UPDATE_CART, data, uid };
}
export function updateShoppingList(data, isAuth) {
    return { type: types.SHOPPING_LIST_UPDATE, data, isAuth };
}
export function getTotalPrice(uid) {
    return { type: types.TOTAL_PRICE, uid };
}