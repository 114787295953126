import React, {useState} from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from 'react-router-dom';
import './showcase-favorite.scss';
import {Button, Empty, Modal, Spin, message} from 'antd';
import {bindActionCreators} from "redux";
import * as productActions from "../../actions/productActions";
import { connect } from 'react-redux';
import {ENDPOINT} from "../../actions/actionTypes";
import * as authActions from "../../actions/authActions";
import * as cartActions from "../../actions/cartActions";
import * as favoriteActions from "../../actions/favoriteActions";

function ShowCaseFavorite(props) {
    const [visible, setVisible] = useState(false);
    const [name, setName] = useState(``);
    const [img, setImg] = useState(``);
    const [price, setPrice] = useState(``);
    const createUserClick = id => {
        props.actions.createUserClick(id);
    };
    const handleCancel = () => {
        setVisible(false);
    };
    const handleVisible = () => {
        setVisible(true);
    };
    const handleOkCart = () => {
        props.history.push(`/cart`);
    };
    const handleOk = item => {
        const uid = document.cookie.split(';')[0].slice(8);
        const { cart, cartActions, isAuthenticated } = props;
        const cartItem = {
            cartItem: {
                productId: item.id,
                userId: 0,
                productCount: 1,
                brandId: item.brand.brandId
            },
            productAttributes: []
        };
        if (cart?.length === 0) {
            cartActions.addCart(cartItem, handleVisible, !isAuthenticated ? uid : '');
        } else if (cart && cart?.length > 0 && item.brand.brandId === cart[0]?.brandResponse?.brandId) {
            cartActions.addCart(cartItem, handleVisible, !isAuthenticated ? uid : '');
        } else if (cart && cart?.length > 0 && item.brand.brandId !== cart[0]?.brandResponse?.brandId) {
            message.error(`Для дополнения корзины товаром из другого магазина/заведения,
            завершите текущий и оформите новый, пожалуйста`, 5);
        }
        setName(item.name);
        setPrice(item.promoPrice ? item.promoPrice : item.price);
        setImg(item.photo_1);
    };
    let showcaseGroup;
    if (props.products.length === 0) {
        showcaseGroup = <Empty style={{width: `100%`}}
            description={
                <span>
           Доступных товаров нету
                </span>
            }
        />;
    } else {
        showcaseGroup = props.products.map((item, i) => (
            <div key={i} className="showcase-favorite-item">
                <div className="icon-img">
                    {/* <div className="favorite-icon">
                        <HeartOutlined/>
                    </div> */}
                    <div className="showcase-brand--img">
                        <img src={`${ENDPOINT}/mp/products/photos/${item.photo_1}`} alt={item.name}/>
                    </div>
                </div>
                <p className="tiny-text">{item.brand.brandName}</p>
                <Link onClick={() => createUserClick(item.id)} to={`/product/${item.id}`} style={{textOverflow: `ellipsis`, whiteSpace: `nowrap`, overflow: `hidden`}}
                    className="medium-text"><p style={{textOverflow: `ellipsis`, whiteSpace: `nowrap`, overflow: `hidden`}}
                    className="medium-text">{item.name}</p></Link>
                <div className="product-prices">
                    {item.promoPrice ? <span className="tiny-text price-promo">{item.price}</span> :
                        <span style={{color: `#000000`}} className="general-text">{item.price} тг</span>}
                    {item.promoPrice ? <span className="price-label general-text">{item.promoPrice} тг</span>
                        : ``}
                </div>
                {item.section?.products ?
                    <button onClick={() => handleOk(item)} className="medium-text">В корзину</button>
                    : <Link to={`/product/${item.id}`} className="medium-text">Подробнее</Link>}
            </div>
        ));
    }

    return(
        <Spin spinning={props.isLoading}>
            <div className="showcase-favorite--inner">
                {showcaseGroup}
            </div>
            {props.pagination}
            <Modal
                title="ТОВАР ДОБАВЛЕН В КОРЗИНУ"
                visible={visible}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Продолжить покупку
                    </Button>,
                    <Button style={{color: `#000`}} key="ok" type="primary" onClick={handleOkCart}>
                        Перейти в корзину
                    </Button>
                ]}
            >
                <div className="cart-modal">
                    <div className="cart-modal--img">
                        <img src={`${ENDPOINT}/mp/products/photos/${img}`} alt={`product`}/>
                    </div>
                    <div className="medium-text cart-modal--text">
                        <p>{name}</p>
                        <p>{price}тг</p>
                    </div>
                </div>
            </Modal>
        </Spin>
    );
}
ShowCaseFavorite.propTypes = {
    userClick: PropTypes.string,
    pagination: PropTypes.object,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
    products: PropTypes.array,
    actions: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    history: PropTypes.object,
    cartActions: PropTypes.object
};
const mapStateToProps = state => ({
    userClick: state.product.userClick,
    cart: state.cart.cart,
    error: state.product.error,
    isLoading: state.product.isLoading,
    isAuthenticated: state.auth.isAuthenticated
});
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(productActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch),
    favoriteActions: bindActionCreators(favoriteActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps) (withRouter(ShowCaseFavorite));