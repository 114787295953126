import React, {Component} from "react";
import HeaderTop from "../../components/header/headerTop";
import HeaderMain from "../../components/header/headerMain";
import Benefit from "../../components/benefit";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import {bindActionCreators} from "redux";
import * as categoryActions from "../../actions/categoryActions";
import * as productActions from "../../actions/productActions";
// import './category.scss';
import {message, Spin, Input, Select, Pagination} from "antd";
import ShowCaseBrand from "../../components/showcase-brand";
import {CloseOutlined, FilterOutlined} from "@ant-design/icons";
import Footer from "../../components/footer";
const { Option } = Select;

class ResultSearch extends Component{
    constructor(props) {
        super(props);
        this.state = {
            lowCost: null,
            highCost: null,
            sortName: 'costAscend',
            isShowProduct: false,
            current: 1,
            isCategory: false,
            pageSize: 150,
            productsGroup: [],
            search: decodeURIComponent(props.history.location.search.slice(1)),
            searchInput: '',
            categoryId: '',
            products: []
        };
    }

    onChangeSort = e => {
        const {categoryId, search} = this.state;
        const filter = {
            sortName: e,
            categoryId: categoryId,
            search: search
        }
        this.setState({
            current: 1
        })
        this.props.productActions.getSearchProducts(filter);
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
       
        this.setState({
            products: nextProps.products?.slice(0, 150),
            productsTotal: nextProps.products,
        })
    }
    onChange = page => {
        const {pageSize} = this.state;

        this.setState({
            current: page,
            pageNumber: page,
            products: this.state.productsTotal.slice(pageSize * (page - 1), pageSize * (page - 1) + 150 )
        })
    
    }
    onChangePrice = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.value) {
            this.setState({
                isShowProduct: true
            })
        }
    }
    onChangeSearch = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.value) {
            this.setState({
                isShowProduct: true
            })
        }
    }
    filterProduct = () => {
        const filter = {
            sortName: this.state.sortName,
            lowCost: this.state.lowCost,
            highCost: this.state.highCost,
            categoryId: this.state.categoryId,
            search: this.state.search
        }
        this.props.productActions.getSearchProducts(filter);
    }
    closeShowProduct = () => {
        this.setState({
            isShowProduct: false,
            lowCost: '',
            highCost: '',
            current: 1,
            categoryId: null
        })
        const filter = {
            sortName: this.state.sortName,
            lowCost: '',
            highCost: '',
            pageNumber: 1,
            search: this.state.search,
            categoryId: null
        }
        this.props.productActions.getSearchProducts(filter);
    }
    onSearch = value => {
        const product = {
            subCategoryId: this.props.match.params.id,
            search: value
        }
        this.props.productActions.getProducts(product);
    }
    closeCategory = id => {
        const {pageSize} = this.state;
        document.getElementById(id).className = 'category-element';
        const product = {
            pageNumber: this.state.current,
            categoryId: '',
            superCategoryId: this.props.match.params.id,
            pageSize: pageSize
        }
        this.props.productActions.getProducts(product);
    }
    handleCategory = (id) => {
        console.log(1)
        const {search} = this.state;
        const product = {
            categoryId: id,
            search: decodeURIComponent(search)
        }
        this.setState({
            categoryId: id,
            current: 1
        })
        if (id) {
            this.setState({
                isShowProduct: true
            })
        }
       
        // this.props.productActions.getSearchProducts(product);
    }
   
    render() {
        let breadCrumb = ``;
        const {search, categoryId,  lowCost, highCost, isShowProduct, current} = this.state;
        const { match, isLoading, error, history,totalCountOfProducts,  } = this.props;

        const filterCategories = history.location.state?.categories?.map((item, i) => 
        <Option value={item.id} key={i}>{item.name}</Option>)
    
        if (error && error.response) {
            message.error(error.response.data);
        }
        const pagination = <div className="pagination-brand">
            <Pagination 
                current={current} 
                pageSize={'150'} 
                onChange={this.onChange} 
                total={totalCountOfProducts}
              
            />
        </div>;
        breadCrumb = <div className="breadcrumb">
            <Link className="tiny-text" to={`/`}>Главная</Link>
            <span className="tiny-text"> / </span>
        </div>;

       
        return (
            <Spin spinning={isLoading}>
                <HeaderTop/>
                <HeaderMain search={search} superCategoryId={match.params.id}/>
                <div className="wrapper">
                <Benefit/>
                    <div className="section">
                        <div className="container">
                            {breadCrumb}
                            <p className="large-text">
                                Результаты поиска: "{search}"
                            </p>
                        </div>
                    </div>
                    <div className="showcase-filter">
                        <div className="container">
                            <div className="showcase-filter-cat--inner">
                                <div className="filter-category">
                                    <p className="large-text"><FilterOutlined style={{color: `#ffc700`}}/> Фильтры</p>
                                    { isShowProduct ? <div className="filter-but">
                                        <div className="show-filter--category">
                                            <p onClick={this.filterProduct}>Показать товары</p>
                                            <CloseOutlined onClick={this.closeShowProduct}/>
                                        </div></div>: `` }
                                        <div className="sort">
                                    <label>Категория</label>
                                        <Select 
                                            value={categoryId}  
                                            onSelect={this.handleCategory} 
                                            placeholder="Выберите категорию" 
                                            width='100%'
                                        >
                                            {filterCategories}
                                        </Select>
                                     
                                    </div>
                                    <div className="sort">
                                    <label>Сортировка</label>
                                        <Select 
                                            defaultValue='costAscend' 
                                            width='100%' 
                                            onChange={this.onChangeSort}
                                        >
                                            <Option value='costAscend'>По возрастанию цены</Option>
                                            <Option value='costDescend'>По убыванию цены</Option>
                                            <Option value='createdDate'>По новинкам</Option>
                                            <Option value='userClick'>По популярности</Option>
                                        </Select>
                                    </div>
                                    <span style={{color: `#9B9B9B`}}>Поиск по цене</span>
                                    <div className="price">
                                        <div>
                                            <label>От</label>
                                            <Input 
                                                type="number" 
                                                name="lowCost" 
                                                value={lowCost} 
                                                onChange={this.onChangePrice} 
                                                placeholder="От"/>
                                        </div>
                                        <div>
                                            <label>До</label>
                                            <Input 
                                                type="number" 
                                                name="highCost" 
                                                value={highCost} 
                                                onChange={this.onChangePrice} 
                                                placeholder="До"/>
                                        </div>
                                    </div>                              
                                </div>
                                <ShowCaseBrand pagination={pagination} products={this.state.products}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Spin>
        );
    }
}
ResultSearch.propTypes = {
    actions: PropTypes.object.isRequired,
    sections: PropTypes.array,
    location: PropTypes.object,
    query: PropTypes.string,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    categoriesSuper: PropTypes.array
};
const mapStateToProps = state => ({
    categoriesSuper: state.category.categoriesSuper,
    sections: state.category.parentCategories,
    error: state.category.error,
    isLoading: state.product.isLoadingSearch,
    products: state.product.searchProducts.products,
    totalCountOfProducts: state.product.searchProducts?.products?.length,
});
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(categoryActions, dispatch),
    productActions: bindActionCreators(productActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps) (ResultSearch);
