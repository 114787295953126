import {all, put, takeLatest} from "@redux-saga/core/effects";
import * as types from "../actions/actionTypes";
import axios from "axios";
import { message } from 'antd';

function* addCard(action) {
    const { history } = action;
    try {
        const card = yield axios.post(`${types.ENDPOINT}/transactions/api/v1/card/save`).then(res => res.data);
        yield put({type: types.CARD_RECEIVED, payload: card});
        history.push(`/e-pay`);
    } catch (error) {
        yield put({type: types.CARD_FAILED, error});
    }
}
function* getCards() {
    try {
        const card = yield axios.get(`${types.ENDPOINT}/transactions/api/v1/card/all`).then(res => res.data);
        yield put({type: types.CARDS_SUCCESS, payload: card});
    } catch (error) {
        yield put({type: types.CARDS_FAILED, error});
    }
}

function* deleteCard(action) {
    const { id } = action;
    try {
        const card = yield axios.get(`${types.ENDPOINT}/transactions/api/v1/card/delete?cardId=${id}`)
            .then(res => res.data);
        yield put({type: types.CARD_REMOVED, payload: card});
        yield getCards();
    } catch (error) {
        yield put({type: types.DELETE_CARD_FAILED, error});
    }
}

function* addRecipient(action) {
    const { data } = action;
    try {
        const recipient = yield axios.post(`${types.ENDPOINT}/mp/orders/v1/recipients`, data).then(res => res.data);
        yield put({type: types.RECIPIENT_ADDED, payload: recipient});
        message.success(`Вы успешно добавили получателя!`);
        yield getRecipients();
    } catch (error) {
        yield put({type: types.RECIPIENT_ADD_FAILED, error});
    }
}

function* addOrder(action) {
    const { data, history } = action;
    const dataOrder = {
        bonus: data.bonus,
        cash: data.cash,
        creditCard: data.creditCard,
        recipientId: data.recipientId,
        delivery_time: data.delivery_time,
        deliveryMethod: data.deliveryMethod,
        deliveryId: data.deliveryId
    };

    try {
        const order = yield axios.post(`${types.ENDPOINT}/mp/orders/v1/orders`, dataOrder).then(res => res.data);
        if (data.cardId && !data.brandCollectable) {
            yield axios.post(
                `${types.ENDPOINT}/transactions/api/v1/pay?bonus=${dataOrder.bonus}&cardId=${data.cardId ? data.cardId : 0}&creditCard=${dataOrder.creditCard}&marketId=${order.marketOrderId}`).
                then(res => res.data);
            history.push(`/order`);
            yield put({type: types.ORDER_ADDED, payload: order});
        } else if (!data.cardId && data.brandCollectable) {
            message.success(`Заказ принят – мы сообщим итоговую сумму к оплате, как только Ваш заказ будет готов.`);
            setTimeout(() => history.push(`/order`), 3000);
        } else if (data.creditCard) {
            const cardResponse = yield axios.post(
                `${types.ENDPOINT}/transactions/api/v1/pay?bonus=${dataOrder.bonus}&cardId=0&creditCard=${dataOrder.creditCard}&marketId=${order.marketOrderId}`)
                .then(res => res.data);
            history.push(`/e-pay`);
            yield put({type: types.CARD_RECEIVED, payload: cardResponse});
        } else {
            history.push(`/order`);
        }
    } catch (error) {
        yield put({type: types.ORDER_ADD_FAILED, error});
        message.error(`Извините, произошла ошибка. Просим произвести оплату заново.`);
    }
}


function* getOrders(action) {
    let { pageNumber, pageSize } = action;
    if (!pageNumber) {
        pageNumber = 1;
    }
    try {
        const orders = yield axios.get(
            `${types.ENDPOINT}/mp/orders/v1/orders?pageNumber=${pageNumber}&pageSize=${pageSize}`).then(res => res.data);
        yield put({type: types.ORDER_RECEIVED, payload: orders});
    } catch (error) {
        yield put({type: types.ORDER_GET_FAILED, error});
    }
}

function* reorder(action) {
    const { id, history } = action;
    try {
        const orders = yield axios.get(
            `${types.ENDPOINT}/mp/orders/v1/reorder?orderId=${id}`, id).then(res => res.data);
        yield put({type: types.REORDER_SUCCESS, payload: orders});
        history.push(`/cart`);
    } catch (error) {
        yield put({type: types.REORDER_FAILED, error});
    }
}
function* payOrder(action) {
    const { data, closeModal, history } = action;
    try {
        if (data.cardId) {
            yield axios.post(
                `${types.ENDPOINT}/transactions/api/v1/pay?bonus=${data.bonus}&cardId=${data.cardId ? data.cardId : 0}&creditCard=${data.sum}&marketId=${data.id}&fullBonus=${data.fullBonus}`)
                .then(res => res.data);
            message.success(`Оплата произведена успешно!`);
            closeModal();
        } else {
            yield axios.post(
                `${types.ENDPOINT}/transactions/api/v1/pay?bonus=${data.bonus}&cardId=0&creditCard=${data.sum}&marketId=${data.id}&fullBonus=${data.fullBonus}`).
                then(res => res.data);
            history.push(`/e-pay`);
        }
    } catch (error) {
        yield put({type: types.REORDER_FAILED, error});
    }
}

function* getOrderedProducts(action) {
    const { id } = action;
    try {
        const orders = yield axios.get(
            `${types.ENDPOINT}/mp/orders/v1/orderedProducts/grouped?orderId=${id}`).then(res => res.data);
        yield put({type: types.ORDER_PRODUCT_RECEIVED, payload: orders});
    } catch (error) {
        yield put({type: types.ORDER_PRODUCT_FAILED, error});
    }
}

function* cancelOrder(action) {
    const { id, current } = action;
    try {
        const orders = yield axios.post(
            `${types.ENDPOINT}/mp/orders/v1/orders/cancel?orderId=${id}`).then(res => res.data);
        yield put({type: types.ORDER_CANCELLED, payload: orders});
        message.success(`Заказ отменен!`);
        yield getOrders({pageNumber: current, pageSize: 10});
    } catch (error) {
        message.error(error.response.data.message);
        yield put({type: types.ORDER_CANCEL_FAILED, error});
    }
}

function* cancelPurchase(action) {
    const { id, orderId } = action;
    try {
        const order = yield axios.post(
            `${types.ENDPOINT}/mp/orders/v1/orders/purchase/cancel?purchaseId=${id}&orderId=${orderId}`).then(res => res.data);
        yield put({type: types.PURCHASE_CANCELLED, payload: order});
        if (order.body.status) {
            message.success(`Покупка отменена. Возврат денег будет в течение 3-х рабочих дней`);
        } else {
            message.error(`Ошибка при отмене покупки!`);
        }
        // yield getOrders({pageNumber: current, pageSize: 10});
    } catch (error) {
        yield put({type: types.PURCHASE_CANCEL_FAILED, error});
    }
}

function* refundProduct(action) {
    const { refund } = action;
    try {
        const order = yield axios.post(
            `${types.ENDPOINT}/mp/orders/v1/orders/purchase/refund`, refund).then(res => res.data);
        yield put({type: types.PRODUCT_REFUNDED, payload: order});
        if (order.body.status) {
            message.success(`Ваша заявка на возврат в обработке`);
        } else {
            message.error(`Ошибка при возврате продукта!`);
        }
    } catch (error) {
        yield put({type: types.PRODUCT_REFUND_FAILED, error});
    }
}

function* editRecipient(action) {
    const { data } = action;
    try {
        const recipient = yield axios.patch(`${types.ENDPOINT}/mp/orders/v1/recipients`, data).then(res => res.data);
        yield put({type: types.RECIPIENT_EDITED, payload: recipient});
        message.success(`Успешно изменили данные!`);
        yield getRecipients();
    } catch (error) {
        yield put({type: types.RECIPIENT_EDIT_FAILED, error});
        message.success(`Ошибка при редактировании!`);
    }
}
function* getRecipients() {
    try {
        const recipient = yield axios.get(`${types.ENDPOINT}/mp/orders/v1/recipients`).then(res => res.data);
        yield put({type: types.RECIPIENT_RECEIVED, payload: recipient});
    } catch (error) {
        yield put({type: types.RECIPIENT_GET_FAILED, error});
    }
}

function* getTime(action) {
    const { id } = action;
    try {
        const time = yield axios.get(`${types.ENDPOINT}/mp/orders/v1/recipients/time?recipientId=${id}`).then(res => res.data);
        yield put({type: types.TIME_RECEIVED, payload: time});
    } catch (error) {
        yield put({type: types.TIME_FAILED, error});
    }
}

function* deleteRecipient(action) {
    const { id } = action;
    try {
        const recipient = yield axios.delete(
            `${types.ENDPOINT}/mp/orders/v1/recipients?id=${id}`).then(res => res.data);
        yield put({type: types.RECIPIENT_DELETED, payload: recipient});
        message.success(`Получатель удален!`);
        yield getRecipients();
    } catch (error) {
        yield put({type: types.RECIPIENT_DELETE_FAILED, error});
        message.error(`Ошибка при удалении!`);
    }
}



export function* paymentSaga() {
    yield all([
        takeLatest(types.ADD_CARD, addCard),
        takeLatest(types.GET_CARDS, getCards),
        takeLatest(types.DELETE_CARD, deleteCard),
        takeLatest(types.ADD_RECIPIENT, addRecipient),
        takeLatest(types.GET_RECIPIENT, getRecipients),
        takeLatest(types.DELETE_RECIPIENT, deleteRecipient),
        takeLatest(types.EDIT_RECIPIENT, editRecipient),
        takeLatest(types.ADD_ORDER, addOrder),
        takeLatest(types.GET_ORDER, getOrders),
        takeLatest(types.CANCEL_ORDER, cancelOrder),
        takeLatest(types.GET_ORDER_PRODUCT, getOrderedProducts),
        takeLatest(types.REORDER, reorder),
        takeLatest(types.CANCEL_PURCHASE, cancelPurchase),
        takeLatest(types.REFUND_PRODUCT, refundProduct),
        takeLatest(types.PAY_ORDER, payOrder),
        takeLatest(types.GET_TIME, getTime),
    ]);
}