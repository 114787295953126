import React, {Component} from 'react';
import HeaderTop  from "../../components/header/headerTop";
import HeaderMain from "../../components/header/headerMain";
import Footer from "../../components/footer";
import './product.scss';
import Slider from "react-slick";
import { Checkbox, Modal, Radio, Spin, Collapse, Button, message } from "antd";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import { connect } from 'react-redux';
import * as productActions from "../../actions/productActions";
import * as favoriteActions from "../../actions/favoriteActions";
import * as cartActions from "../../actions/cartActions";
import * as authActions from "../../actions/authActions";
import * as errorActions from "../../actions/errorActions";
import {HeartOutlined, HeartFilled } from "@ant-design/icons";
import {Link, withRouter} from "react-router-dom";
import InputNumber from "antd/es/input-number";
import {ENDPOINT} from "../../actions/actionTypes";
import ReactImageZoom from 'react-image-zoom';
const { Panel } = Collapse;
class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attributeList: [],
            favorite: '',
            showFav: true,
            visible: false,
            modalVisible: false,
            text: '',
            productCount: 1
        };
    }
    componentWillUnmount() {
        this.setState({
            product: {}
        })
    }
    componentDidMount() {
        const uid = document.cookie.split(';')[0].slice(8)
        this.props.favoriteActions.getFavorite();
        this.props.cartActions.getCart(uid);
        this.props.productActions.getProduct(this.props.match.params.id);
    }
    componentDidUpdate(prevProps) {
        if(this.props.match.params.id !== prevProps.match.params.id) {
            this.props.productActions.getProduct(this.props.match.params.id);
        }
    }
    handleOkCart = () => {
        this.props.history.push('/cart');
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
        window.location.reload()
    }
    handleVisible = () => {
        this.setState({
            visible: true
        })
    }
    handleOk = brandId => {
        const uid = document.cookie.split(';')[0].slice(8);
        const { attributeList, productCount } = this.state;
        const { cart, cartActions, isAuthenticated, match, product } = this.props;
        const productAttributes = attributeList.map((item, i) => {
            return {
                attributeId: Number(item.attributeId),
                valueId: item.valueId
            }
        })
        const cartItem = {
            cartItem: {
                productId: Number(match.params.id),
                userId: 0,
                productCount: productCount,
                brandId: brandId
            },
            productAttributes: productAttributes
        }
        console.log(cart)
        if (cart && cart.length > 0 && brandId === cart[0].brandResponse.brandId) {
            cartActions.addCart(cartItem, this.handleVisible, !isAuthenticated ? uid : '');
        } else if (cart && cart.length === 0) {
            cartActions.addCart(cartItem, this.handleVisible, !isAuthenticated ? uid : '');
        } else if (product.attributeList.length !== productAttributes.length) {
            message.error('Выберите все нужные атрибуты!')
        } else if (cart && cart?.length > 0 && brandId !== cart[0]?.brandResponse?.brandId) {
            message.error(`Для дополнения корзины товаром из другого магазина/заведения,
            завершите текущий и оформите новый, пожалуйста`, 5);
        } 
        // this.setState({
        //     text: 'pressed'
        // })
    }
    onChange = e => {
        const { value, name, type } = e.target;
        const obj = {
            type: type,
            attributeId: name,
            valueId: value,
        }
        const values = this.state.attributeList;
        let isDeleted = false;
        if (this.state.attributeList.length === 0) {
            values.push(obj);
        } else {
            values.map((item, i) => {
                if(item.type === obj.type && item.attributeId === obj.attributeId && item.type === 'radio') {
                    values.splice(i--,1)
                }
                if(item.valueId === obj.valueId && item.attributeId === obj.attributeId) {
                    values.splice(i, 1)
                    isDeleted = true;
                }
            })
            if (!isDeleted) {
                values.push(obj);
            }
        }
        this.setState({
            attributeList: values
        })
    }
    onChangeQuantity = e => {
        this.setState({
            productCount: e
        })
    }
    handleFavorite = () => {
        const favorite = {
            productId: this.props.match.params.id,
            userId: 55555
        };
        if (this.props.isAuthenticated) {
            this.props.favoriteActions.addFavorite(favorite);
        } else {
            this.setState({
                modalVisible: !this.state.modalVisible
            })
        }
        this.setState({
            text: 'pressed'
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.favoriteResponse !== this.props.favoriteResponse && this.props.isAuthenticated) {
            this.setState({
                favorite: nextProps.favoriteResponse,
            })
            this.props.favoriteActions.getFavorite();
        } else {
            this.setState({
                favorite: '',
            })
        }
    }

    render() {
        const { product, isLoading, isLoadingFavorite, favorites, cart } = this.props;
        let quantities = []
        for (let i = 0; i < cart.length; i++) {
            for (let j = 0; j < cart[i].cartItems.length; j++) {
                quantities.push(cart[i].cartItems[j])
            }
        }
        const settings = {
            dots: true,
            infinite: false,
            arrows: false,
            speed: 500,
            centered: true,
            autoPlay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true
                    }
                }
            ]
        };
        let cartInfo = '';
        const { favorite, visible, modalVisible } = this.state;
        let attributesList = [];
        let breadCrumb = '';
        let productItem = '';
        let productPrice = '';
        let buttonFavorite = <button onClick={this.handleFavorite} className="button-favorite">В избранное <HeartOutlined style={{color: '#ffc700'}}/></button>;
        if (favorite === "Added") {
            buttonFavorite = <button onClick={this.handleFavorite} className="button-favorite">Добавлено <HeartFilled style={{color: '#ffc700'}}/></button>
        } else if (favorite === "Deleted") {
            buttonFavorite = <button onClick={this.handleFavorite} className="button-favorite">В избранное <HeartOutlined style={{color: '#ffc700'}}/></button>
        }
        if (product && product.product) {
            favorites.map(item => {
                if (item.id === product.product.id) {
                    buttonFavorite = <button onClick={this.handleFavorite} className="button-favorite">Добавлено <HeartFilled style={{color: '#ffc700'}}/></button>
                }
            })
            breadCrumb = <div className="breadcrumb">
                <Link className="tiny-text" to={`/`}>Главная</Link>
                <span className="tiny-text"> / </span>
                <Link className="tiny-text " to={{pathname: `/sections/brand/${product.product.brand.brandId}`,
                    query: {brandName: product.product.brand.brandName}}}>{product.product.brand.brandName}</Link>
                <span className="tiny-text"> / </span>
                <Link className="tiny-text cap-text" to={{pathname: `/category/${product.product.superCategory.id}`,
                        query: {category: product.product.superCategory.name}}}>{product.product.superCategory.name.toLowerCase()}</Link>
                <span className="tiny-text"> / </span>
            </div>
        }
        if (product && product.attributeList) {
             attributesList = product.attributeList.map((item, i) => {
                return (
                    <div key={i} className="attribute-item">
                        <div className="product-attribute--title">
                            <p className='general-text'>{item.productAttribute.attributeName}</p>
                            <span className="tiny-text">{item.productAttribute.required ? '*Обязательно' : ''}</span>
                        </div>
                        {(item.productAttribute.type === `checkbox`) ?
                            <Checkbox.Group name={String(item.productAttribute.attributeId)}>
                                    {item.values.map((itemS, j) =>(
                                            <Checkbox key={i + `` + j} className="checkbox-attribute"
                                                      onChange={this.onChange} value={itemS.id}>
                                            {itemS.name} {itemS.price ? `(${itemS.price}тг)` : ``}</Checkbox>
                                    )) }
                            </Checkbox.Group>
                            : item.productAttribute.type === `radiobutton` ?
                                <Radio.Group name={String(item.productAttribute.attributeId)} style={{display: `flex`, flexWrap: 'wrap'}} onChange={this.onChange}>
                                        {item.values.map((itemAt, j) => (
                                            <Radio.Button className="radio-attribute"  value={itemAt.id} key={i + `` + j}>{itemAt.name} {itemAt.price > 0}</Radio.Button>
                                        ))}
                                </Radio.Group>
                                    : ``
                        }
                    </div>
                );
            })
        }
        if (product && product.product && product.product.promoPrice) {
            productPrice = <div className="product-price">
                <Collapse
                    className="site-collapse-custom-panel"
                    expandIconPosition={'right'}
                >
                    <Panel header={<div className="promo-price"><p className="price-text medium-text">{product.product.price}тг</p>
                        <p className="large-text">{`${product.product.promoPrice}тг`}</p></div>} key="1">
                        <div className="collapse-inner">
                            <span>Скидка</span>
                            <span>-{product.product.price - product.product.promoPrice}тг</span>
                        </div>
                        <div className="collapse-inner">
                            <span>Вы экономите</span>
                            <span>{product.product.price - product.product.promoPrice}тг</span>
                        </div>

                    </Panel>
                </Collapse>
            </div>
        } else if (product && product.product) {
            productPrice = <div className="product-price">
                <p className="enormous-text">{product.product.price}тг</p>
            </div>
        }
        if (product && product.product) {
            productItem = ( <div className="container">
                            {breadCrumb}
                            <p className="enormous-text">{product.product.name}</p>
                            <div className="products-inner">
                                <div className="product-images">
                                    <Slider {...settings}>
                                        {product.product.photos.map((item, i) => (
                                            <div key={i} className="product-image">
                                                {/* <ReactImageZoom {...{width: 500,offset:{ vertical: 1000, horizontal: 1000}, zoomWidth: 500, zoomPosition: 'original', scale: 1, img: `${ENDPOINT}/mp/products/photos/${item}`}} /> */}
                                                <img src={`${ENDPOINT}/mp/products/photos/${item}`} alt="product"/>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            <div className="product-attributes">
                                <div className="product-inform">
                                    {productPrice}
                                    {/*<span className="tiny-text">Товар {product.product.availability ? 'есть' : 'нету'} в наличии</span>*/}
                                </div>
                                <div className="attributes-list">
                                    {attributesList}
                                </div>
                                <div className="product-quantity">
                                    <span className="tiny-text">Количество/кг: </span>
                                    <InputNumber min={1} max={100} defaultValue={1} onChange={this.onChangeQuantity}  />
                                </div>
                                <div className="product-attributes--button">
                                    <Button  onClick={() => this.handleOk(product.product.brand.brandId)} className="button-primary">В корзину</Button>
                                    {buttonFavorite}
                                </div>
                                <p className="general-text">{product.product.description}</p>
                                <p className="tiny-text">Артикул: {product.product.article}</p>
                            </div>
                        </div>
            </div>);
            cartInfo = <div className="cart-modal">
                <div className="cart-modal--img">
                    <img src={`${ENDPOINT}/mp/products/photos/${product.product.photo_1}`} alt={product.product.name}/>
                </div>
                <div className="medium-text cart-modal--text">
                    <p>{product.product.name}</p>
                    <p>{product.product.promoPrice ? product.product.promoPrice : product.product.price}тг</p>
                </div>
            </div>
        }
        return (
            <Spin spinning={isLoading || isLoadingFavorite}>
                <HeaderTop visible={modalVisible} component="product" text={this.state.text}/>
                <HeaderMain/>
                <div className="wrapper">
                    <div className="products">
                        {productItem}
                    </div>
                </div>
               
                {/*<ProductsSlider productsSlider={newProduct}/>*/}
                <Footer/>
                <Modal
                    title="ТОВАР ДОБАВЛЕН В КОРЗИНУ"
                    visible={visible}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button  key="back" onClick={this.handleCancel}>
                            Продолжить покупку
                        </Button>,
                        <Button style={{color: `#000`}} key="ok" type="primary" onClick={this.handleOkCart}>
                            Перейти в корзину
                        </Button>
                    ]}
                >
                   {cartInfo}
                   {<p className="general-text"  align="center">В корзине  
                   {quantities.length === 1 ? `${quantities.length} товар` : quantities.length > 1 && quantities.length < 4
                       ? `${quantities.length} товара` : `${quantities.length} товаров` } </p>}
                </Modal>
            </Spin>
        );
    }
}
Product.propTypes = {
    productActions: PropTypes.object.isRequired,
    favoriteActions: PropTypes.object,
    errorActions: PropTypes.object,
    product: PropTypes.object,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    isAuthenticated: PropTypes.bool
};
const mapStateToProps = state => ({
    product: state.product.product,
    error: state.product.error,
    isLoading: state.product.isLoading,
    favoriteResponse: state.favorite.favoriteResponse,
    isLoadingFavorite: state.favorite.isLoading,
    errorFavorite: state.favorite.error,
    clearError: state.error.error,
    cartResponse: state.cart.cartResponse,
    cart: state.cart.cart,
    favorites: state.favorite.favorites,
    isAuthenticated: state.auth.isAuthenticated
});
const mapDispatchToProps = dispatch => {
    return {
        productActions: bindActionCreators(productActions, dispatch),
        errorActions: bindActionCreators(errorActions, dispatch),
        favoriteActions: bindActionCreators(favoriteActions, dispatch),
        cartActions: bindActionCreators(cartActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch)
    }
};
export default connect(mapStateToProps, mapDispatchToProps) (withRouter(Product));
