import React, {Component} from 'react';
import {AutoComplete, Badge, Button, Cascader, Dropdown, Input, Menu, message, Pagination} from "antd";
import HeaderTop from "../../components/header/headerTop";
import Benefit from "../../components/benefit";
import ShowCaseFavorite from "../../components/showcase-favorite";
import Question from "../../components/question";
import Footer from "../../components/footer";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as productActions from "../../actions/productActions";
import * as categoryActions from "../../actions/categoryActions";
import * as userActions from "../../actions/userActions";
import {connect} from "react-redux";
import './products.scss';
import {
    CloseCircleFilled,
    DownOutlined,
    MenuOutlined,
    SearchOutlined
} from "@ant-design/icons";
import Slider from "react-slick";
import {Link} from "react-router-dom";
import {Divider} from "antd/es";
import * as cartActions from "../../actions/cartActions";
import * as authActions from "../../actions/authActions";
const { Search } = Input;

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            start: 0,
            end: 6,
            categoryId: '',
        };
    }
    componentDidMount() {
        const product = {
            pageNumber: this.state.current,
            pageSize: 150,
            search: this.state.product
        }
        if (this.props.isAuthenticated) {
            this.props.cartActions.getCart(0);
        }
        this.props.categoryActions.getSections();
        !this.props.isAuthenticated && this.props.categoryActions.getTotalSections(localStorage.cityId);
        this.props.productActions.getProducts(product);
        this.props.productActions.getSearchProducts(product);
        // this.props.categoryActions.getCategoriesBySectionId(this.props.match.params.id);
    }
    componentDidUpdate(prevProps) {
        const product = {
            pageNumber: this.state.current,
            pageSize: 150,
            categoryId: this.state.categoryId,
            search: this.state.product
        }
        if(this.props.match.params.id !== prevProps.match.params.id) {
            this.props.productActions.getSearchProducts(product);
            this.props.productActions.getProducts(product);
        }
    }
    onChange = page => {
        const { categoryId } = this.state;
        const product = {
            categoryId: categoryId,
            pageNumber: page,
            pageSize: 150,
            search: this.state.product
        }
        this.setState({
            current: page
        })
        this.props.productActions.getProducts(product);
    }

    handleNext = type => {
        if (type === 'inc') {
            this.setState({
                start: this.state.start + 7,
                end: this.state.end + 7,
            })
        } else if (type === 'dec'){
            this.setState({
                start: this.state.start - 7,
                end: this.state.end - 7,
            })
        }
    }
    onSearch = value => {
        const product = {
            search: value
        }
        this.props.productActions.getProducts(product);
    }
    handleCategory = (id, i) => {
        const element = document.getElementsByClassName('category-element');
        for( let i = 0; i < element.length; i++ ) {
            element[i].className = 'category-element';
        }
        document.getElementById(i).classList.add('category-element', 'category-element--active');
        const product = {
            pageNumber: this.state.current,
            categoryId: id,
            pageSize: 150,
        }
        this.setState({
            categoryId: id
        })
        this.props.productActions.getProducts(product);
    }
    onChangeDrop = (value, selectedOption) => {
        if (value[2]) {
            this.props.history.push(`/subcategory/${value[2]}`)
        }
    }

    onChangeProduct = value => {
        const product = {
            search: value,
            subCategoryId: this.props.subcategoryId ? this.props.subcategoryId : ``
        }
        this.setState({
            product: value,
        })
        this.props.productActions.getProducts(product);
    }

    onSelect = (value, option) => {
        this.props.history.push(`/product/${value}`);
        this.setState({
            product: option.children
        })
    }

    searchProduct = () => {
        const product = {
            search: this.state.product,
            global: true,
            history: this.props.history,
            subCategoryId: this.props.subcategoryId ? this.props.subcategoryId : ``,
            pageNumber: 1
        }
        if (product.search) {
            this.props.productActions.getSearchProducts(product);
        }
    }
    searchProductEnter = e => {
        const product = {
            search: this.state.product,
            global: true,
            history: this.props.history,
            subCategoryId: this.props.subcategoryId ? this.props.subcategoryId : ``,
            pageNumber: 1
        }
        if (product.search && e.key === 'Enter') {
            this.props.productActions.getSearchProducts(product);
        }
    }
    closeCategory = id => {
        document.getElementById(id).className = 'category-element';
        const product = {
            pageNumber: this.state.current,
            categoryId: '',
            pageSize: 150
        }
        this.props.productActions.getProducts(product);
    }

    render() {
        const settings = {
            dots: false,
            arrows: true,
            infinite: false,
            speed: 500,
            autoPlay: true,
            centered: true,
            variableWidth: true,
            slidesToScroll: 3,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        const { categoriesMain, products, isLoading, error, match, totalCountOfProducts, parentCategories,  totalSections, searchProducts, quantity, } = this.props;
        const { start, end } = this.state;

        const pagination = <div className="pagination-brand">
            <Pagination pageSize={'15'} current={this.state.current} onChange={this.onChange} total={totalCountOfProducts}/>
        </div>
        const categoryElements = categoriesMain.map((item, i) => (
            <div id={i} key={i} className="category-element">
                <CloseCircleFilled onClick={() => this.closeCategory(i)}/>
                <div style={{width: '100%', padding: '5px', display: 'flex', textAlign: 'center', alignItems: 'center'}} onClick={() => this.handleCategory(item.id, i)}  >
                    <p>{item.name}</p>
                </div>
            </div>
        ));
        let menuItems = <Menu.Item>Загрузка...</Menu.Item>;
        let productsGroup = []
        if (!isLoading) {
            menuItems = parentCategories.map((item, i) => (
                <Menu.Item key={i}>
                    <Link to={`/section/${item.id}`}>{item.name}</Link>
                </Menu.Item>
            ));
        }
        if (searchProducts && searchProducts.length > 0) {
            productsGroup = searchProducts.map((item, i) => {
                return {
                    key: item?.subCategory?.id,
                    text: item.name,
                    value: String(item.id),
                }
            })
        }

        let options = totalSections.map((item, i) => {
            return {
                value: item.section.id,
                label: item.section.name,
                children: item.categories?.map((itemCat, j) => {
                    return {
                        value: itemCat.category.id,
                        label: itemCat.category.name,
                        children: itemCat.subCategories.map((itemSub, k) => {
                            return {
                                value: itemSub.id,
                                label: itemSub.name,
                                code: i+''+j+''+k
                            };
                        })
                    };
                })
            };
        })
        const displayRender = (labels, selectedOptions) =>
            labels.map((label, i) => {
                const option = selectedOptions[i];
                if (i === labels.length - 1) {
                    return (
                        <Link key={i} to={`/subcategory/${option.value}`}>{option.label}</Link>
                    );
                }
            });
        const menu = (
            <Menu style={{width: '200px'}}>
                {menuItems}
            </Menu>
        );
        if (error && error.response) {
            message.error( error.response.data.message);
        }
        return (
            <div>
                <HeaderTop/>
                <div className="header-main">
                    <div className="container">
                        <div className="header-main--inner">
                            <Link to={`/`}>
                                <div className="logo">
                                    <img src="/assets/smartplazalogo.svg" alt="logo"/>
                                </div>
                            </Link>
                            <Dropdown overlay={menu}>
                                <button className="dropdown-button" onClick={e => e.preventDefault()}>
                                    <MenuOutlined className="menu-outlined" />
                                    <DownOutlined className="down-outlined" />
                                </button>
                            </Dropdown>
                            <div className="header-main--search">
                                <AutoComplete
                                    value={this.state.product}
                                    onChange={this.onChangeProduct}
                                    onSelect={this.onSelect}
                                    dataSource={productsGroup}
                                    onKeyDown={this.searchProductEnter}
                                    className="search-input"
                                    placeholder="Я ищу продукт..."
                            
                                />
                                <div className="cascader-header">
                                    <Divider style={{background: `#C4CBD2`, height: `36px`, width: `1px`}} type={`vertical`}/>
                                    <Cascader
                                        options={options}
                                        expandTrigger="hover"
                                        onChange={this.onChangeDrop}
                                        placeholder='Все категории'
                                        style={{width: `180px`}}
                                        displayRender={displayRender}
                                    />
                                </div>
                                <button onClick={this.searchProduct} className="search-button"><SearchOutlined className="search-icon"/></button>
                            </div>
                            <Link to='/cart' className="cart">
                                <img src="/assets/icons/cart.png" alt="cart"/>
                                <p className="general-text">Корзина</p>
                                <Badge style={{background: `#FFC700`, color: `#2D3E50`}} count={quantity} />
                            </Link>
                        </div>
                    </div>
                </div>
                <Benefit/>
                <div className="categories-tab">
                    <div className="container">
                        <Slider {...settings}>
                            {categoryElements}
                        </Slider>
                    </div>
                </div>
                <div className="container">
                    <p>Бренды</p>
                    <div className="search">
                        <p className="enormous-text">Продукты</p>
                        <Search
                            placeholder="Введите наименование продукта"
                            onSearch={this.onSearch}
                            enterButton = {<Button style={{background: '#FFC700', color: '#000000'}}><SearchOutlined/></Button>}
                            style={{ width: 300, borderRadius: '4px' }}
                        />
                    </div>
                    <ShowCaseFavorite pagination={pagination} products={products ? products : []}/>
                </div>
                <Question/>
                <Footer/>
            </div>
        );
    }
}
Products.propTypes = {
    productActions: PropTypes.object,
    categoryActions: PropTypes.object,
    products: PropTypes.array,
    categoriesMain: PropTypes.array,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    cartActions: PropTypes.object.isRequired,
    parentCategories: PropTypes.array,
    totalSections: PropTypes.array,
    isAuthenticated: PropTypes.bool,
    searchProducts: PropTypes.array
};
const mapStateToProps = state => ({
    products: state.product.products.productResponses,
    categoriesMain: state.category.categoriesMain,
    totalCountOfProducts: state.product.products.totalCountOfProducts,
    error: state.product.error,
    isLoading: state.product.isLoading,
    parentCategories: state.category.parentCategories,
    totalSections: state.category.totalSections,
    cart: state.cart.cart,
    quantity: state.cart.quantityProduct,
    isAuthenticated: state.auth.isAuthenticated,
    searchProducts: state.product.searchProducts.productResponses,
    user: state.user.profile
});
const mapDispatchToProps = dispatch => {
    return {
        productActions: bindActionCreators(productActions, dispatch),
        categoryActions: bindActionCreators(categoryActions, dispatch),
        cartActions: bindActionCreators(cartActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    };
};
export default connect(mapStateToProps, mapDispatchToProps) (Products);