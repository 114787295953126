import React, {useEffect, useState} from 'react';
import {Pagination, Spin, Empty} from "antd";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as bonusActions from "../../actions/bonusActions";
import {connect} from "react-redux";
import HeaderTop from "../../components/header/headerTop";
import HeaderMain from "../../components/header/headerMain";
import Footer from "../../components/footer";
import Aside from "../../components/aside";
import'./bonus.scss';
import {ENDPOINT} from "../../actions/actionTypes";

function Bonus(props) {
    const { isLoading, transaction } = props;
    let transactions = [];
    const [current, setCurrent] = useState(1);
    useEffect(() => {
        props.bonusActions.getBonus();
        props.bonusActions.getTransactions(0);
    }, []);
    const onChange = page => {
        props.bonusActions.getTransactions(page-1);
        setCurrent(page);
    };
    if (transaction.content && transaction.content.length > 0) {
        transactions = transaction.content.map((item, i) => (
            <div key={i} className="bonus-element">
                <div className="bonus-main">
                    <div className="bonus-img">
                        <img src={`${ENDPOINT}/partners/api/file/logo/${item.brandImage}`} alt="brand"/>
                    </div>
                    <div>
                        <h4>{item.brandName}</h4>
                        <p>{item.createdAt}</p>
                    </div>
                </div>
                <div>
                    <p className="bonus-gained">+{item.bonusGained}тг</p>
                    <p className="sum-spent">-{item.sum}тг</p>
                </div>
            </div>
        ));
    } else {
        transactions = <Empty/>;
    }
    return(
        <Spin spinning={isLoading}>
            <HeaderTop/>
            <HeaderMain/>
            <div className="wrapper">
                <div className="bonus">
                    <div className="container">
                        <div className="bonus-inner">
                            <Aside bonus={props.bonus}/>
                            <div className="bonus-items">
                                {transactions}
                                <div className="pagination-brand">
                                    <Pagination pageSize={`50`} current={current} onChange={onChange}
                                        total={transaction.totalElements}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </Spin>
    );
}
Bonus.propTypes = {
    bonusActions: PropTypes.object.isRequired,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
    bonus: PropTypes.object,
    transaction: PropTypes.object
};
const mapStateToProps = state => ({
    error: state.bonus.error,
    isLoading: state.bonus.isLoading,
    bonus: state.bonus.bonus,
    transaction: state.bonus.transaction
});
const mapDispatchToProps = dispatch => ({
    bonusActions: bindActionCreators(bonusActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps) (Bonus);