import React from 'react';
import {Button, DatePicker, Form, Input, Modal, Select, Spin} from "antd";
import PropTypes from "prop-types";
import MaskedInput from "antd-mask-input";
const { Option } = Select;

function Register(props) {
    const { signUpVisible, handleSignUpCancel, handleSignUpOk, formItemLayout, dateFormatList} = props;
    return(
        <Modal
            title="Регистрация"
            visible={signUpVisible}
            onOk={handleSignUpOk}
            onCancel={handleSignUpCancel}
            footer={null}
        >
            <Form
                {...formItemLayout}
                name="register"
                onFinish={handleSignUpOk}
                scrollToFirstError
            >
                <Form.Item
                    name="firstName"
                    label={
                        <span>
                             Имя&nbsp;
                        </span>
                    }
                    rules={[
                        {
                            required: true,
                            message: `Пожалуйста введите имя!`,
                            whitespace: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="lastName"
                    label={
                        <span>
                             Фамилия&nbsp;
                        </span>
                    }
                    rules={[
                        {
                            required: true,
                            message: `Пожалуйста введите фамилию!`,
                            whitespace: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="phone"
                    label="Телефон"
                    rules={[
                        {
                            required: true,
                            message: `Пожалуйста, введите свой номер телефона!`,
                        },
                    ]}
                >
                    <MaskedInput mask="+1(111)-111-11-11" placeholder="+7(777)-777-77-77" />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                        {
                            type: `email`,
                            message: `Неправильный e-mail!`,
                        },
                        {
                            required: true,
                            message: `Пожалуйста введите свой e-mail!`,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Пароль"
                    rules={[
                        {
                            required: true,
                            message: `Пожалуйста, введите пароль!`,
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label="Повторите пароль"
                    dependencies={[`password`]}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: `Пожалуйста, повторите пароль!`,
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue(`password`) === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(`Пароли не совпадают!`);
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="birthDate"
                    label="Дата рождения"
                    rules={[
                        {
                            required: true,
                            message: `Пожалуйста, выберите дату рождения!`,
                        },
                    ]}
                >
                    <DatePicker placeholder="Выберите дату рождения" style={{width: `100%`}} format={dateFormatList} />
                </Form.Item>
                <Form.Item
                    name="gender"
                    label="Пол"
                    rules={[
                        {
                            required: true,
                            message: `Пожалуйста, выберите пол!`,
                        },
                    ]}
                >
                    <Select placeholder="Выберите пол">
                        <Option value='1'>Мужской</Option>
                        <Option value='0'>Женский</Option>
                    </Select>
                </Form.Item>
                {/*<Form.Item*/}
                {/*    name="language"*/}
                {/*    label="Язык"*/}
                {/*    rules={[*/}
                {/*        {*/}
                {/*            required: true,*/}
                {/*            message: `Пожалуйста, выберите язык!`,*/}
                {/*        },*/}
                {/*    ]}*/}
                {/*>*/}
                {/*   <Select placeholder="Выберите язык">*/}
                {/*       <Option value='kazakh'>Казахский</Option>*/}
                {/*       <Option value='russian'>Русский</Option>*/}
                {/*       <Option value='english'>English</Option>*/}
                {/*       <Option value='uzbek'>Узбекский</Option>*/}
                {/*   </Select>*/}
                {/*</Form.Item>*/}
                <Form.Item
                    name="cityId"
                    label="Город"
                    rules={[
                        {
                            required: true,
                            message: `Пожалуйста, выберите город!`,
                        },
                    ]}
                >
                    <Select placeholder="Выберите город">
                        <Option value='2'>Алматы</Option>
                        <Option value='3'>Шымкент</Option>
                    </Select>
                </Form.Item>
                <Form.Item >
                    <Button style={{width: `100%`, color: `#000`, height: `36px`, borderRadius: `5px`}}
                        type="primary" htmlType="submit">
                        Зарегистрироваться
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}

Register.propTypes = {
    signUpVisible: PropTypes.bool,
    formItemLayout: PropTypes.object,
    dateFormatList: PropTypes.array,
    handleSignUpCancel: PropTypes.func,
    handleSignUpOk: PropTypes.func,
};

export default Register;

