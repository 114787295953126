import React, {useEffect} from 'react';
import { Tabs, Spin } from 'antd';
import { Link } from 'react-router-dom';
import './brand-tabs.scss';
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as categoryActions from "../../actions/categoryActions";
import {connect} from "react-redux";
import {ENDPOINT} from "../../actions/actionTypes";
const { TabPane } = Tabs;

function BrandTabs(props) {
    useEffect(() => {
        props.actions.getBrandSections(2);
    }, []);

    const { brandSections, isLoading, breadCrumb } = props;
    const tabPane = brandSections?.map((item, i) => {
        return (
            <TabPane tab={item.section.name} key={i}>
                <div className="tab-pane">
                    {item.brands?.map((itemBrand, j) => {
                        return (
                            <Link key={i+``+j} className="tab-pane--item" to={{pathname: `/brands/${itemBrand.brandId}`,
                                query: itemBrand.brandName}}>
                                <div className="brand-logo">
                                    <img width={40} src={`${ENDPOINT}/partners/api/file/logo/${itemBrand.brandLogo}`}
                                        alt={itemBrand.name}/>
                                </div>
                                <p className="medium-text">{itemBrand.brandName}</p>
                            </Link>
                        );
                    })
                    }
                </div>
            </TabPane>);
    }
    );
    return(
        <Spin spinning={isLoading}>
            <div className="brand-tabs">
                <div className="container">
                    {breadCrumb}
                    <p className="large-text">Наши бренды</p>
                    <Tabs className="brand-tabs--inner" type="card">
                        {tabPane}
                    </Tabs>
                </div>
            </div>
        </Spin>
    );

}
BrandTabs.propTypes = {
    actions: PropTypes.object.isRequired,
    brandSections: PropTypes.array,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
    breadCrumb: PropTypes.object
};
const mapStateToProps = state => ({
    brandSections: state.category.brandSections,
    error: state.category.error,
    isLoading: state.category.isLoading,
});
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(categoryActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps) (BrandTabs);