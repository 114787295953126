import React, {useEffect, useState} from "react";
import {Tabs, Empty, Button, Modal, Spin, Pagination, Form, Input, Switch} from "antd";
import HeaderTop from "../../components/header/headerTop";
import HeaderMain from "../../components/header/headerMain";
import Footer from "../../components/footer";
import './order.scss';
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import * as paymentActions from "../../actions/paymentActions";
import * as bonusActions from "../../actions/bonusActions";
import {ENDPOINT} from "../../actions/actionTypes";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
const { TabPane } = Tabs;

function Order(props) {
    let currentOrdersArray = [], allOrdersArray = [];
    let currentOrders = ``, allOrders = ``;
    const [visible, setVisible] = useState(false);
    const [refundVisible, setRefundVisible] = useState(false);
    const [paymentVisible, setPaymentVisible] = useState(false);
    const [sum, setSum] = useState(0);
    const [textSum, setTextSum] = useState(0);
    const [textBonus, setTextBonus] = useState(0);
    const [bonus, setBonus] = useState(0);
    const [cardId, setCardId] = useState("");
    const [switchBonus, setSwitchBonus] = useState(0);
    const [refundData, setRefundData] = useState({
        count: 1,
        comment: ``,
        purchaseId: null,
        orderId: null,
        productId: null,
        orderProductId: null,
        ownId: null,
        productCount: 1
    });
    const [currentAll, setCurrentAll] = useState(1);
    const [currentCur, setCurrentCur] = useState(1);
    const [id, setId] = useState(0);
    const [status, setStatus] = useState(``);
    const [date, setDate] = useState(``);
    const [courier, setCourier] = useState({});
    const { orders, totalCountFin, totalCountProcess } = props;
    const openRefundModal = (item, product) => {
        setRefundVisible(true);
        setRefundData(prev => ({
            ...prev,
            orderId: item.purchase.orderId,
            purchaseId: item.purchase.id,
            orderProductId: product.orderedProduct.id,
            productId: product.orderedProduct.productId,
            ownId: item.purchase.ownId,
            productCount: product.orderedProduct.productCount
        }));
    }
    let userCards = [];
    const cancelPurchase = item => {
        props.paymentActions.cancelPurchase(item.purchase.id, item.purchase.orderId);
    }
    const openPaymentModal = item => {
        setPaymentVisible(true);
        setId(item.id);
        setSum(item.correctedSum !== null && item.correctedSum != 0.0 ? item.correctedSum : item.sum);
        setTextSum(item.correctedSum !== null && item.correctedSum != 0.0 ? item.correctedSum : item.sum);
    }
    const handleRefundCancel = () => {
        setRefundVisible(false);
    }
    const deleteCard = id => {
        props.paymentActions.deleteCard(id)
    }
    const choosePayment = (id, i) => {
        setCardId(id)
        if (i === 'cash') {
            const cardElements = document.getElementsByClassName('payment-user-card');
            for ( let i = 0; i < cardElements.length; i++ ) {
                cardElements[i].className = 'payment-user-card';
            }
            document.getElementById(i).className = 'payment-card-active payment-card';
          
        } else {
            const elements = document.getElementsByClassName('payment-user-card');
            // document.getElementById('cash').className = 'payment-card'
            for ( let i = 0; i < elements.length; i++ ) {
                elements[i].className = 'payment-user-card';
            }
            document.getElementById(i).className = 'payment-user-card-active payment-user-card '
        
        }
    }
    const handlePaymentCancel = () => {
        setPaymentVisible(false);
        setSum(0);
        setBonus(0)
        setSwitchBonus(false);
    }
    const refundProduct = () => {
        props.paymentActions.refundProduct(refundData);
    }
    useEffect(() => {
        props.bonusActions.getBonus();
        props.paymentActions.getOrders(1, 10);
        props.paymentActions.getCards();
    }, []);
    useEffect(() => {
        setCardId(props?.cards[0]?.card_id);
    }, [props?.cards])
    if (props.cards && props.cards.length > 0 ) {
        userCards = props.cards.map((item, i) => (
            i === 0 ? <div id={i} className="medium-text payment-user-card payment-user-card-active" key={i}>
                <span onClick={() => choosePayment(item.card_id, i)}>{item.masked_number}</span>
                <DeleteOutlined onClick={() => this.deleteCard(item.card_id)}/>
            </div> : <div id={i} className="medium-text payment-user-card " key={i}>
                <span onClick={() => choosePayment(item.card_id, i)}>{item.masked_number}</span>
                <DeleteOutlined onClick={() => deleteCard(item.card_id)}/>
            </div>
        ))
    } else {
        userCards = <Empty description="Нет карты"/>
    }
    const orderedProducts = props.orderedProducts.map((item, i) => {
        return (
            <div key={i}>
                <div className="ordered-product--title">
                    <h4>{item.purchase.brandName}</h4>
                    {/* {item.purchase.status === 0 ? <Button onClick={() => cancelPurchase(item)}>
                        Отменить покупку</Button> : ``} */}
                </div>
                {item.orderedItemWithTotalPriceByItems.map((itemJ, j) => (
                    <div key={`${i}${j}`} className="product-info-detail">
                        <div className="product-order--img">
                            <img src={`${ENDPOINT}/mp/products/photos/${itemJ.orderedProduct.photo_1}`} alt="order"/>
                        </div>
                        <div className="order-attributes">
                            <div>
                                <span className="general-text">{itemJ.orderedProduct.name}</span>
                                <br/>
                                <span className="tiny-text">Количество: {itemJ.orderedProduct.productCount}</span>
                                <br/>
                                <span className="tiny-text">Цена:
                                    <span className="general-text">
                                        {itemJ.orderedProduct.unitPrice * itemJ.orderedProduct.productCount}тг
                                    </span>
                                </span>
                                <div>
                                    {itemJ.productAttribute.map((itemAtt, k) => {
                                        return (
                                            <span key={`${i}${j}${k}`}>{itemAtt.valueResponse.name},</span>
                                        );
                                    })}
                                </div>
                            </div>
                            {/* <div>
                                {item.purchase.status === 1 &&
                                    new Date((new Date() - new Date(item.purchase.createdDate))).getDay() <= 14
                                    ? <Button type="primary" onClick={() => openRefundModal(item, itemJ)}>Возврат продукта</Button> : ``}
                            </div> */}
                        </div>
                    </div>
                ))}
            </div>
        );
    });

    if (orders && orders.length > 0) {
        currentOrdersArray = orders.filter((item) => item.orderStatus === 3);
        allOrdersArray = orders.filter((item) => (item.orderStatus === 2 || item.orderStatus === 1));
    }
    if (currentOrdersArray.length === 0 ) {
        currentOrders = <Empty/>;
    } else {
        currentOrders = currentOrdersArray.map((item, i) => (
            <div key={i} className="order-item">
                <div className="order-info">
                    <div>
                        <span>Номер заказа</span>
                        <h4>#{item.id}</h4>
                    </div>
                    <div>
                        <span>Сумма заказа</span>
                        <h4 className="general-text">{item.correctedSum !== null && item.correctedSum != 0.0 ? item.correctedSum : item.sum} тг</h4>
                    </div>
                    <div>
                        <span>Время доставки</span>
                        <h4 className="general-text">{item.delivery_time}</h4>
                    </div>
                    <div>
                        <span>Статус заказа</span>
                        <h4 style={{color: `#52c41a`}}>В процессе</h4>
                    </div>
                    <div>
                        <span>Статус оплаты</span>
                        {item.paymentStatus === 0 ? <h4 style={{color: `#ff4d3f`}}>Не оплачен</h4>
                        : item.paymentStatus === 1 ? <h4 style={{color: `#52c41a`}}>Оплачен</h4> : <h4 style={{color: `#8b8b8b`}}>Отменен</h4> }
                        
                    </div>
                    <div>
                        <span>Статус доставки</span>
                        <h4>{item.deliveryStatus === 0
                            ? `Неактивный` : item.deliveryStatus === 1
                                ? `Доставлен` : item.deliveryStatus === 3 ?
                                    `У курьера` : item.deliveryStatus === 4 ? `Курьер принял` : `Неизвестно`}</h4>
                    </div>
                </div>
                <div className="product-info">
                    {item.paymentStatus === 0 && item.purchases.some(item => item.brandCollectable === true) && item.purchases.every(item => item.status === 1) ? 
                    <Button type="primary" onClick={() => openPaymentModal(item)} type="default">Оплатить</Button> 
                     : ``}
                    <Button onClick={() => openModal(item)} type="default">Подробнее о заказе</Button>
                    {/* {item.purchases.some((item) => item.status === 0) ?
                        <Button onClick={() => cancelOrder(item.id, currentCur)}
                            type="danger">Отменить заказ</Button> : ``} */}
                              {item.paymentStatus !== 1 ?
                        <Button onClick={() => cancelOrder(item.id, currentCur)}
                            type="danger">Отменить заказ</Button> : ``}
                </div>
            </div>
        ));
    }
    if (allOrdersArray.length === 0 ) {
        allOrders = <Empty/>;
    } else {
        allOrders = allOrdersArray.map((item, i) => (
            <div key={i} className="order-item">
                <div className="order-info">
                    <div>
                        <span>Номер заказа</span>
                        <h4>#{item.id}</h4>
                    </div>
                    <div>
                        <span>Сумма заказа</span>
                        <h4 className="general-text">{item.correctedSum !== null && item.correctedSum != 0.0 ? item.correctedSum : item.sum} тг</h4>
                    </div>
                    <div>
                        <span>Время доставки</span>
                        <h4 className="general-text">{item.delivery_time}</h4>
                    </div>
                    <div>
                        <span>Статус заказа</span>
                        {item.status === `finished` ?  <h4 style={{color: `#52c41a`}}>Завершен</h4> : <h4 style={{color: `#8b8b8b`}}>Отменен</h4>}
                       
                    </div>
                    <div>
                        <span>Статус оплаты</span>
                        {item.paymentStatus === 0 ? <h4 style={{color: `#ff4d3f`}}>Не оплачен</h4>
                        : item.paymentStatus === 1 ? <h4 style={{color: `#52c41a`}}>Оплачен</h4> : <h4 style={{color: `#8b8b8b`}}>Отменен</h4> }
                        
                    </div>
                    <div>
                        <span>Статус доставки</span>
                        <h4>{item.deliveryStatus === 0
                            ? `Неактивный` : item.deliveryStatus === 1
                                ? `Доставлен` : item.deliveryStatus === 3 ?
                                    `У курьера` : item.deliveryStatus === 4 ? `Курьер принял` : `Неизвестно`}</h4>
                    </div>
                </div>
                <div className="product-info">
                    <Button onClick={() => openModal(item)} type="default">Подробнее о заказе</Button>
                    <Button className="reorder-button" onClick={() => reorder(item.id)} type="default">Перезаказать</Button>
                </div>
            </div>
        ));
    }
    const onChangeCurrent = page => {
        setCurrentCur(page);
        props.paymentActions.getOrders(page, 10);
    };
    const payOrder = () => {
         const { activeBonus } = props;
        let sumOrder = 0, bonusOrder = 0;
        if (switchBonus && sum < activeBonus.activeBonuses) {
            bonusOrder = sum;
            sumOrder = 0;
           
        } else if (switchBonus && sum > activeBonus.activeBonuses) {
            bonusOrder = activeBonus.activeBonuses;
            sumOrder = sum - activeBonus.activeBonuses;          
        } else {     
            bonusOrder = 0;
            sumOrder = sum;           
        }

        const payment = {
            id: id,
            sum: sumOrder   , 
            bonus: bonusOrder, 
            cardId: cardId,
            fullBonus: bonusOrder === sum ? true : false,
        }
        props.paymentActions.payOrder(payment, handlePaymentCancel, props.history);
    }
    const onChange = e => {
        const { name, value } = e.target;
        setRefundData(prev => ({
            ...prev,
            [name]: value
        }));
    }
    const reorder = id => {
        props.paymentActions.reorder(id, props.history);
    };
    const onSwitch = e => {      
        const { activeBonus } = props;
        if (e && sum < activeBonus.activeBonuses) {
           setTextBonus(sum)
           setTextSum(0)          
        } else if (e && sum > activeBonus.activeBonuses) {
            setTextBonus(activeBonus.activeBonuses);
            setTextSum(sum - activeBonus.activeBonuses);          
        } else {     
            setTextBonus(0);
            setTextSum(sum);           
        }  
        setSwitchBonus(e);
                
    }
    const addCard = () => {
        props.paymentActions.addCard(props.history);
    }
    const onChangeAll = page => {
        setCurrentAll(page);
        props.paymentActions.getOrders(page, 10);
    };
    const onInput = e => {
        if (e.target.value > refundData.productCount) {
            e.target.value = refundData.productCount
        }
    }
    const paginationAll = <div className="pagination-brand">
        <Pagination current={currentAll} pageSize={`10`} onChange={onChangeAll} total={totalCountFin}/>
    </div>;
    const paginationCurrent = <div className="pagination-brand">
        <Pagination current={currentCur} pageSize={`10`} onChange={onChangeCurrent} total={totalCountProcess}/>
    </div>;
    const cancelOrder = (id, current) => {
        props.paymentActions.cancelOrder(id, current);
    };
    const openModal = item => {
        setVisible(true);
        setSum(item.correctedSum !== null && item.correctedSum != 0.0 ? item.correctedSum : item.sum);
        setDate(item.delivery_time);
        setId(item.id);
        setStatus(item.status);
        setCourier(item.courier);
        props.paymentActions.getOrderedProducts(item.id);
    };
    const handleCancel = () => {
        setVisible(false);
    };
    return (
       
        <Spin spinning={props.isLoading}>
           
            <div className="order">
                <HeaderTop/>
                <HeaderMain/>
                
                <div className="order-inner">
                <div className="wrapper">
                    <div className="container-basket">
                        <div className='order-tabs'>
                            <Tabs>
                                <TabPane key='1' tab='Текущие заказы'>
                                    {currentOrders}
                                    {paginationCurrent}
                                </TabPane>
                                <TabPane key='2' tab='Все заказы'>
                                    {allOrders}
                                    {paginationAll}
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                    </div>  
                </div>
                <Footer/>
                <Modal
                    className="order-product--modal"
                    title={`Детали заказа #${id}`}
                    visible={visible}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <Spin spinning={props.isLoadingOrdered} >
                        <div className="order-detail-item">
                            <span className="medium-text">Сумма заказа</span>
                            <h4>{sum} тг</h4>
                        </div>
                        <div className="order-detail-item">
                            <span className="medium-text">Время доставки</span>
                            <h4>{date}</h4>
                        </div>
                        <div className="order-detail-item">
                            <span className="medium-text">Статус заказа</span>
                            <h4>{status === `processing` ? `В процессе` : status ===
                            `finished` ? `Завершен` : `Отменен`}</h4>
                        </div>
                        <div className="order-detail-item">
                            <div className='order-detail-item--inner'>
                                <div>
                                    <span className="medium-text">Имя курьера</span>
                                    <h4>{courier ? courier.name : `Неизвестно`}</h4>
                                </div>
                                <div>
                                    <span className="medium-text">Телефон курьера</span>
                                    <h4>{courier ? courier.phoneNumber : `Неизвестно`}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="product-details">
                            <h3>Заказанные продукты</h3>
                            {orderedProducts}
                        </div>
                    </Spin>
                </Modal>
                <Modal
                    className="order-product--modal"
                    title={`Возврата продукта #${refundData.productId}`}
                    visible={refundVisible}
                    onCancel={handleRefundCancel}
                    footer={[
                        <Button key="ok" onClick={refundProduct}>Отправить</Button>,
                        <Button key="back">Закрыть</Button>
                    ]}
                >
                    <Spin spinning={false}>
                        <Form>
                            <Form.Item>
                                <label className="smallest-text">Комментарий</label>
                                <Input onChange={onChange} name="comment" value={refundData.comment} placeholder="Введите комментарий"/>
                            </Form.Item>
                            <Form.Item>
                                <label className="smallest-text">Количество(максимальное количество: {refundData.productCount})</label>
                                <Input onInput={onInput} min={1} max={refundData.productCount} onChange={onChange} name="count" value={refundData.count} placeholder="Введите количество продукта"/>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
                <Modal
                    className="order-product--modal"
                    title={`Оплата заказа #${id}`}
                    visible={paymentVisible}
                    onCancel={handlePaymentCancel}
                    footer={[
                        <Button key="ok" onClick={payOrder}>Отправить</Button>,
                        <Button key="back">Закрыть</Button>
                    ]}
                >
                    <Spin spinning={false}>   
                        <div className="payment-user-cards">{userCards}</div>
                        <div className="payment-item--inner" onClick={addCard}>
                            <div className="payment-card"><PlusCircleOutlined/>Добавить новую карту</div>
                        </div>
                         <div style={{margin: "10px 0"}}>
                            <p className="general-text">Ваш бонус: {props.activeBonus.activeBonuses}тг</p>
                            <span className="general-text">Потратить бонус:  <Switch checked={switchBonus} onChange={onSwitch}/></span> 
                            <p>Списывается {textBonus} тг бонус + с карты {textSum} тг</p>
                            <h3>К оплате: <span>{sum}тг</span></h3>
                        </div>
                    </Spin>
                </Modal>
               
            </div>
           
        </Spin>
       
    );
}

Order.propTypes = {
    cancelPurchase: PropTypes.func,
    orders: PropTypes.array,
    paymentActions: PropTypes.object,
    isLoading: PropTypes.bool,
    isLoadingOrdered: PropTypes.bool,
    orderedProducts: PropTypes.array,
    totalCountProcess: PropTypes.number,
    totalCountFin: PropTypes.number,
    history: PropTypes.object,
    cards: PropTypes.array,
    activeBonus: PropTypes.object
};
const mapStateToProps = (state) => ({
    orders: state.payment.orders.orders,
    totalCountProcess: state.payment.orders.totalCountOfProcessing,
    totalCountFin: state.payment.orders.totalOfFinAndCan,
    isLoading: state.payment.isLoading,
    isLoadingOrdered: state.payment.isLoadingOrdered,
    orderedProducts: state.payment.orderedProducts,
    cards: state.payment.cards,
    activeBonus: state.bonus.bonus,
});
const mapDispatchToProps = dispatch => {
    return {
        paymentActions: bindActionCreators(paymentActions, dispatch),
        bonusActions: bindActionCreators(bonusActions, dispatch),
    };
};
export default connect(mapStateToProps, mapDispatchToProps) (Order);