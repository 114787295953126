import {all, put, takeLatest} from "@redux-saga/core/effects";
import * as types from "../actions/actionTypes";
import axios from "axios";

function* getBanners() {
    try {
        const banners = yield axios.get(`${types.ENDPOINT}/news/api/banners/type/4`).then(res => res.data);
        yield put({type: types.BANNERS_RECEIVED, payload: banners});
    } catch (error) {
        yield put({type: types.BANNERS_FAILED, error});
    }
}

export function* bannerSaga() {
    yield all([
        takeLatest(types.GET_BANNERS, getBanners),
    ]);
}