
import * as types from '../actions/actionTypes';

const initialState = {
    error: '',
    isLoading: false,
}
export default function errorReducer(state = initialState, action = null) {
    switch (action.type) {
        case types.ADD_FAVORITE_SUCCESS:
            return { ...state, error: action.payload };
        default:
            return state;
    }
}