import {all, put, takeLatest} from "@redux-saga/core/effects";
import * as types from "../actions/actionTypes";
import {message} from "antd";
import axios from "axios";

function* getBonus() {
    try {
        const bonus = yield axios.get(`${types.ENDPOINT}/bonuses/bonus/user`).then(res => res.data);
        yield put({type: types.BONUS_RECEIVED, payload: bonus});
    } catch (error) {
        yield put({type: types.BONUS_FAILED, error});
    }
}

function* sendQuestion(action) {
    const { data } = action;
    try {
        const feedback = yield axios.post(`${types.ENDPOINT}/mp/orders/v1/contactus`, data).then(res => res.data);
        yield put({type: types.QUESTION_SENT, payload: feedback});
        message.success(`Ваш вопрос успешно отправлен!`);
    } catch (error) {
        yield put({type: types.QUESTION_FAILED, error});
        message.error(`Ошибка при отправке!`);
    }
}

function* getTransactions(action) {
    let { pageNumber } = action;
    if (!pageNumber) {
        pageNumber = 0;
    }
    try {
        const transaction = yield axios.get(`${types.ENDPOINT}/orders/api/v1/order/user?page=${pageNumber}&size=50 `)
            .then(res => res.data);
        yield put({type: types.TRANSACTION_RECEIVED, payload: transaction});
    } catch (error) {
        yield put({type: types.TRANSACTION_FAILED, error});
    }
}

export function* bonusSaga() {
    yield all([
        takeLatest(types.GET_BONUS, getBonus),
        takeLatest(types.GET_TRANSACTION, getTransactions),
        takeLatest(types.SEND_QUESTION, sendQuestion),
    ]);
}