import React, {useState} from "react";
import './question.scss';
import {bindActionCreators} from "redux";
import * as questionActions from "../../actions/bonusActions";
import {connect} from 'react-redux';

function Question(props) {
    const [data, setData] = useState({
        name: ``,
        email: ``,
        phone: ``,
        question: ``
    });
    const sendQuestion = () => {
        const dataElem = {
            name: data.name,
            email: data.email,
            phoneNumber: data.phone,
            text : data.question
        };
        props.questionActions.sendQuestion(dataElem);
    }
    const onChange = e => {
        const { name, value } = e.target;
        setData(prev => ({
            ...prev,
            [name]: value
        }));
    }
    return(
        <div className="question">
            <div className="container">
                <div className="question-inner">
                    <div className="partner">
                        <img src="/assets/Union.svg" alt="union"/>
                        <div className="ask-question">
                            <p className="large-text">У вас остались вопросы? С радостью ответим на них!</p>
                            <div className="question-inputs">
                                <input name="name" onChange={onChange} value={data.name} className="medium-text"
                                       placeholder="Ваше имя"/>
                                <input name="email" onChange={onChange} value={data.email} className="medium-text"
                                       placeholder="Адрес эл. почты"/>
                                <input name="phone" onChange={onChange} value={data.phone} className="medium-text"
                                       placeholder="Телефон"/>
                            </div>
                            <div className="question-area">
                            <textarea name="question" onChange={onChange}
                                      value={data.question} placeholder="Вопрос или обращение"/>
                            </div>
                            <button onClick={sendQuestion}>Отправить</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = state => ({
    userClick: state.product.userClick,
    error: state.product.error,
    isLoading: state.product.isLoading,
    isAuthenticated: state.auth.isAuthenticated
});
const mapDispatchToProps = dispatch => ({
    questionActions: bindActionCreators(questionActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps) (Question);