export const ENDPOINT = `https://api.smartplaza.kz`;

export const GET_SECTIONS = `GET_SECTIONS`;
export const SECTIONS_RECEIVED = `SECTIONS_RECEIVED`;
export const SECTIONS_FAILED = `SECTIONS_FAILED`;

export const GET_SUPERCATEGORIES = `GET_SUPERCATEGORIES`;
export const SUPERCATEGORIES_RECEIVED = `SUPERCATEGORIES_RECEIVED`;
export const SUPERCATEGORIES_FAILED = `SUPERCATEGORIES_FAILED`;

export const GET_CATEGORIES_SUPER = `GET_CATEGORIES_SUPER`;
export const CATEGORIES_SUPER_RECEIVED = `CATEGORIES_SUPER_RECEIVED`;
export const CATEGORIES_SUPER_FAILED = `CATEGORIES_SUPER_FAILED`;

export const GET_SECTION_TOTAL = `GET_SECTION_TOTAL`;
export const SECTION_TOTAL_RECEIVED = `SECTION_TOTAL_RECEIVED`;
export const SECTION_TOTAL_FAILED = `SECTION_TOTAL_FAILED`;

export const GET_CAT_SUBCAT = `GET_CAT_SUBCAT`;
export const SECTION_CAT_SUBCAT_RECEIVED = `SECTION_CAT_SUBCAT_RECEIVED`;
export const SECTION_CAT_SUBCAT_FAILED = `SECTION_CAT_SUBCAT_FAILED`;

export const GET_PRODUCT = `GET_PRODUCT`;
export const PRODUCT_RECEIVED = `PRODUCT_RECEIVED`;
export const PRODUCT_FAILED = `PRODUCT_FAILED`;

export const GET_PRODUCTS = `GET_PRODUCTS`;
export const PRODUCTS_RECEIVED = `PRODUCTS_RECEIVED`;
export const PRODUCTS_FAILED = `PRODUCTS_FAILED`;

export const GET_PRODUCTS_SEARCH = `GET_PRODUCTS_SEARCH`;
export const PRODUCTS_SEARCH_RECEIVED = `PRODUCTS_SEARCH_RECEIVED`;
export const PRODUCTS_SEARCH_FAILED = `PRODUCTS_SEARCH_FAILED`;

export const CREATE_USER_CLICK = `CREATE_USER_CLICK`;
export const CREATE_USER_CLICK_SUCCESS = `CREATE_USER_CLICK_SUCCESS`;
export const CREATE_USER_CLICK_FAILED = `CREATE_USER_CLICK_FAILEd`;

export const ADD_FAVORITE = `ADD_FAVORITE`;
export const ADD_FAVORITE_SUCCESS = `ADD_FAVORITE_SUCCESS`;
export const ADD_FAVORITE_FAILED = `ADD_FAVORITE_FAILED`;

export const GET_FAVORITE = `GET_FAVORITE`;
export const GET_FAVORITE_SUCCESS = `GET_FAVORITE_SUCCESS`;
export const GET_FAVORITE_FAILED = `GET_FAVORITE_FAILED`;

export const ADD_CART = `ADD_CART`;
export const ADD_CART_SUCCESS = `ADD_CART_SUCCESS`;
export const ADD_CART_FAILED = `ADD_CART_FAILED`;

export const FORGOT_PASSWORD = `FORGOT_PASSWORD`;
export const FORGOT_PASSWORD_SUCCESS = `FORGOT_PASSWORD_SUCCESS`;
export const FORGOT_PASSWORD_FAILED = `FORGOT_PASSWORD_FAILED`;

export const FORGOT_CONFIRM = `FORGOT_CONFIRM`;
export const FORGOT_PASSWORD_CONFIRMED = `FORGOT_PASSWORD_SUCCESS`;
export const FORGOT_PASSWORD_CONFIRM_FAILED = `FORGOT_PASSWORD_CONFIRM_FAILED`;

export const CLEAR_ERROR = `CLEAR_ERROR`;

export const GET_CART = `GET_CART`;
export const CART_RECEIVED = `CART_RECEIVED`;
export const CART_FAILED = `CART_FAILED`;

export const GET_CATEGORIES = `GET_CATEGORIES`;
export const CATEGORIES_RECEIVED = `CATEGORIES_RECEIVED`;
export const CATEGORIES_FAILED = `CATEGORIES_FAILED`;

export const GET_SUBCATEGORIES = `GET_SUBCATEGORIES`;
export const SUBCATEGORIES_RECEIVED = `SUBCATEGORIES_RECEIVED`;
export const SUBCATEGORIES_FAILED = `SUBCATEGORIES_FAILED`;

export const GET_BRAND_CAT = `GET_BRAND_CAT`;
export const BRAND_CAT_RECEIVED = `BRAND_CAT_RECEIVED`;
export const BRAND_CAT_FAILED = `BRAND_CAT_FAILED`;

export const GET_CARDS = `GET_CARDS`;
export const CARDS_SUCCESS = `CARDS_SUCCESS`;
export const CARDS_FAILED = `CARDS_FAILED`;

export const ADD_CARD = `ADD_CARD`;
export const CARD_RECEIVED = `CARD_RECEIVED`;
export const CARD_FAILED = `CARD_FAILED`;

export const DELETE_CARD = `DELETE_CARD`;
export const CARD_REMOVED = `CARD_REMOVED`;
export const DELETE_CARD_FAILED = `DELETE_CARD_FAILED`;

export const REMOVE_CART = `REMOVE_CART`;
export const DELETE_ALL_CART = `DELETE_ALL_CART`;
export const CART_REMOVE_SUCCESS = `CART_REMOVE_SUCCESS`;
export const CART_REMOVE_FAILED = `CART_REMOVE_FAILED`;

export const UPDATE_CART = `UPDATE_CART`;
export const UPDATE_CART_SUCCESS = `UPDATE_CART_SUCCESS`;
export const UPDATE_CART_FAILED = `UPDATE_CART_FAILED`;

export const TOTAL_PRICE = `TOTAL_PRICE`;
export const TOTAL_PRICE_SUCCESS = `TOTAL_PRICE_SUCCESS`;
export const TOTAL_PRICE_FAILED = `TOTAL_PRICE_FAILED`;

export const SIGN_IN = `SIGN_IN`;
export const SET_CURRENT_USER = `SET_CURRENT_USER`;
export const SIGN_IN_FAILED = `SIGN_IN_FAILED`;

export const SIGN_UP = `SIGN_UP`;
export const SIGN_UP_SUCCESS = `SIGN_UP_SUCCESS`;
export const SIGN_UP_FAILED = `SIGN_UP_FAILED`;

export const GET_PROFILE = `GET_PROFILE`;
export const PROFILE_RECEIVED = `PROFILE_RECEIVED`;
export const PROFILE_FAILED = `PROFILE_FAILED`;

export const CHANGE_PASSWORD = `CHANGE_PASSWORD`;
export const PASSWORD_CHANGED = `PASSWORD_CHANGED`;
export const PASSWORD_FAILED = `PASSWORD_FAILED`;

export const EDIT_PROFILE = `EDIT_PROFILE`;
export const PROFILE_EDITED = `PROFILE_EDITED`;
export const PROFILE_EDIT_FAILED = `PROFILE_FAILED`;

export const ADD_RECIPIENT = `ADD_RECIPIENT`;
export const RECIPIENT_ADDED = `RECIPIENT_ADDED`;
export const RECIPIENT_ADD_FAILED = `RECIPIENT_ADD_FAILED`;

export const GET_RECIPIENT = `GET_RECIPIENT`;
export const RECIPIENT_RECEIVED = `RECIPIENT_RECEIVED`;
export const RECIPIENT_GET_FAILED = `RECIPIENT_GET_FAILED`;

export const EDIT_RECIPIENT = `EDIT_RECIPIENT`;
export const RECIPIENT_EDITED = `RECIPIENT_EDITED`;
export const RECIPIENT_EDIT_FAILED = `RECIPIENT_EDIT_FAILED`;

export const DELETE_RECIPIENT = `DELETE_RECIPIENT`;
export const RECIPIENT_DELETED = `RECIPIENT_DELETED`;
export const RECIPIENT_DELETE_FAILED = `RECIPIENT_DELETE_FAILED`;

export const ADD_ORDER = `ADD_ORDER`;
export const ORDER_ADDED = `ORDER_ADDED`;
export const ORDER_ADD_FAILED = `ORDER_ADD_FAILED`;

export const GET_ORDER = `GET_ORDER`;
export const ORDER_RECEIVED = `ORDER_RECEIVED`;
export const ORDER_GET_FAILED = `ORDER_GET_FAILED`;

export const GET_ORDER_ID = `GET_ORDER_ID`;
export const ORDER_ID_RECEIVED = `ORDER_ID_RECEIVED`;
export const ORDER_GETID_FAILED = `ORDER_GETID_FAILED`;

export const GET_ORDER_PRODUCT = `GET_ORDER_PRODUCT`;
export const ORDER_PRODUCT_RECEIVED = `ORDER_PRODUCT_RECEIVED`;
export const ORDER_PRODUCT_FAILED = `ORDER_PRODUCT_FAILED`;

export const CANCEL_ORDER = `CANCEL_ORDER`;
export const ORDER_CANCELLED = `ORDER_CANCELLED`;
export const ORDER_CANCEL_FAILED = `ORDER_CANCEL_FAILED`;

export const REORDER = `REORDER`;
export const REORDER_SUCCESS = `REORDER_SUCCESS`;
export const REORDER_FAILED = `REORDER_FAILED`;

export const SIGN_UP_CONFIRM = `SIGN_UP_CONFIRM`;
export const SIGN_UP_CONFIRMED = `SIGN_UP_CONFIRMED`;
export const SIGN_UP_CONFIRM_FAILED = `SIGN_UP_CONFIRM_FAILED`;

export const GET_BONUS = `GET_BONUS`;
export const BONUS_RECEIVED = `BONUS_RECEIVED`;
export const BONUS_FAILED = `BONUS_FAILED`;

export const GET_BRANDS = `GET_BRANDS`;
export const BRANDS_RECEIVED = `BRANDS_RECEIVED`;
export const BRANDS_FAILED = `BRANDS_FAILED`;

export const GET_POPULAR_PRODUCTS = `GET_POPULAR_PRODUCTS`;
export const POPULAR_PRODUCTS_RECEIVED = `POPULAR_PRODUCTS_RECEIVED`;
export const POPULAR_PRODUCTS_FAILED = `POPULAR_PRODUCTS_FAILED`;

export const GET_NEW_PRODUCTS = `GET_NEW_PRODUCTS`;
export const NEW_PRODUCTS_RECEIVED = `NEW_PRODUCTS_RECEIVED`;
export const NEW_PRODUCTS_FAILED = `NEW_PRODUCTS_FAILED`;

export const GET_TRANSACTION = `GET_TRANSACTION`;
export const TRANSACTION_RECEIVED = `TRANSACTION_RECEIVED`;
export const TRANSACTION_FAILED = `TRANSACTION_FAILED`;

export const GET_BRAND_SECTION = `GET_BRAND_SECTION`;
export const BRAND_SECTION_RECEIVED = `BRAND_SECTION_RECEIVED`;
export const BRAND_SECTION_FAILED = `BRAND_SECTION_FAILED`;

export const SEND_QUESTION = `SEND_QUESTION`;
export const QUESTION_SENT = `QUESTION_SENT`;
export const QUESTION_FAILED = `QUESTION_FAILED`;

export const CANCEL_PURCHASE = `CANCEL_PURCHASE`;
export const PURCHASE_CANCELLED = `PURCHASE_CANCELLED`;
export const PURCHASE_CANCEL_FAILED = `PURCHASE_CANCEL_FAILED`;

export const REFUND_PRODUCT = `REFUND_PRODUCT`;
export const PRODUCT_REFUNDED = `PRODUCT_REFUNDED`;
export const PRODUCT_REFUND_FAILED = `PRODUCT_REFUND_FAILED`;

export const PAY_ORDER = `PAY_ORDER`;
export const PAY_ORDER_SUCCESS = `PAY_ORDER_SUCCESS`;
export const PAY_ORDER_FAILED= `PAY_ORDER_FAILED`;

export const GET_BANNERS = `GET_BANNERS`;
export const BANNERS_RECEIVED = `BANNERS_RECEIVED`;
export const BANNERS_FAILED = `BANNERS_FAILED`;

export const GET_TIME = `GET_TIME`;
export const TIME_RECEIVED = `TIME_RECEIVED`;
export const TIME_FAILED = `TIME_FAILED`;

export const GET_SHOPPING_LIST = `GET_SHOPPING_LIST`;
export const SHOPPING_LIST_RECEIVED = `SHOPPING_LIST_RECEIVED`;
export const SHOPPING_LIST_FAILED = `SHOPPING_LIST_FAILED`;

export const SHOPPING_LIST_ADD = `SHOPPING_LIST_ADDED`;
export const SHOPPING_LIST_ADD_SUCCESS = `SHOPPING_LIST_ADD_SUCCESS`;
export const SHOPPING_LIST_ADD_FAILED = `SHOPPING_LIST_ADD_FAILED`;

export const SHOPPING_LIST_DELETE = `SHOPPING_LIST_DELETE`;
export const SHOPPING_LIST_DELETE_SUCCESS = `SHOPPING_LIST_DELETE_SUCCESS`;
export const SHOPPING_LIST_DELETE_FAILED = `SHOPPING_LIST_DELETE_ FAILED`;

export const SHOPPING_LIST_UPDATE = `SHOPPING_LIST_UPDATE`;
export const SHOPPING_LIST_UPDATE_SUCCESS = `SHOPPING_LIST_UPDATE_SUCCESS`;
export const SHOPPING_LIST_UPDATE_FAILED = `SHOPPING_LIST_UPDATE_FAILED`;


export const SIGN_OUT = `SIGN_OUT`;