import {connect} from 'react-redux';
import React from 'react';
import PropTypes from "prop-types";
function Epay(props) {
    return(
        <iframe style={{width: `100%`, height: `100vh`}} srcDoc={props.cardResponse}>
        </iframe>
    );
}
Epay.propTypes = {
    cardResponse: PropTypes.string
};
const mapStateToProps = (state) => ({
    cardResponse: state.payment.cardResponse
});
export default connect(mapStateToProps) (Epay);