import React, {Component} from "react";
import {Pagination, Spin} from "antd";
import HeaderTop from "../../components/header/headerTop";
import HeaderMain from "../../components/header/headerMain";
import Question from "../../components/question";
import Footer from "../../components/footer";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as favoriteActions from "../../actions/favoriteActions";
import {connect} from "react-redux";
import ShowCaseFavorite from "../../components/showcase-favorite";

class Favorite extends Component{
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount() {
        this.props.favoriteActions.getFavorite();
    }

    render() {
        const { favorites, isLoading } = this.props;
        // const pagination = <div className="pagination-brand">
        //     <Pagination current={this.state.current} onChange={this.onChange} total={10} pageSize={'15'}/>
        // </div>
        return (
            <Spin spinning={isLoading}>
                <HeaderTop/>
                <HeaderMain/>
                <div className="container">
                    <div className="wrapper">
                        <ShowCaseFavorite products={favorites}/>
                    </div>
                </div>
                <Question/>
                <Footer/>
            </Spin>
        );
    }
}
Favorite.propTypes = {
    favoriteActions: PropTypes.object,
    favorites: PropTypes.array,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
};
const mapStateToProps = state => ({
    favorites: state.favorite.favorites,
    error: state.favorite.error,
    isLoading: state.favorite.isLoading,
});
const mapDispatchToProps = dispatch => {
    return {
        favoriteActions: bindActionCreators(favoriteActions, dispatch)
    };
};
export default connect(mapStateToProps, mapDispatchToProps) (Favorite);