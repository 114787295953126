
import * as types from '../actions/actionTypes';

const initialState = {
    bonus: {},
    isLoading: false,
    transaction: {}
}
export default function bonusReducer(state = initialState, action = null) {
    switch (action.type) {
        case types.GET_BONUS:
            return { ...state, isLoading: true };
        case types.BONUS_RECEIVED:
            return { ...state, isLoading: false, bonus: action.payload };
        case types.BONUS_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_TRANSACTION:
            return { ...state, isLoading: true };
        case types.TRANSACTION_RECEIVED:
            return { ...state, isLoading: false, transaction: action.payload };
        case types.TRANSACTION_FAILED:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}