import Modal from "antd/es/modal";
import {Button, Input, Select} from "antd";
import Form from "antd/es/form";
import MaskedInput from "antd-mask-input";
import React from "react";
import PropTypes from "prop-types";

const { Option } = Select;

function Recipient(props) {
    const {
        handleAddCancel,
        onSelect,
        name,
        addVisible,
        backRecipient,
        handleOkRecipient,
        surname,
        onChange,
        phone,
        address,
        numOfBuilding,
        floorDoor,
        cityId } = props;
    return(
        <Modal
            title="Добавление получателя"
            visible={addVisible}
            onOk={handleOkRecipient}
            onCancel={handleAddCancel}
            footer={[
                <Button onClick={backRecipient} key="back">Назад</Button>,
                <Button onClick={handleOkRecipient} key="ok">Сохранить</Button>
            ]}
        >
            <Form>
                <Form.Item>
                    <label className="smallest-text">Фамилия</label>
                    <Input placeholder="Фамилия" name="surname" onChange={onChange} value={surname}/>
                </Form.Item>
                <Form.Item>
                    <label className="smallest-text">Имя</label>
                    <Input placeholder="Имя" name="name" onChange={onChange} value={name}/>
                </Form.Item>
                <Form.Item>
                    <label className="smallest-text">Номер телефона</label>
                    <MaskedInput value={phone} name="phone" onChange={onChange}
                        mask="+1(111)-111-11-11" placeholder="+7(777)-777-77-77"/>
                </Form.Item>
                <Form.Item>
                    <label className="smallest-text">Улица или микрорайон</label>
                    <Input value={address} name="address" onChange={onChange}
                        placeholder="Укажите улицу или микрорайон"/>
                </Form.Item>
                <Form.Item>
                    <label className="smallest-text">Номер дома или название здания</label>
                    <Input value={numOfBuilding} name="numOfBuilding" onChange={onChange}
                        placeholder="Укажите номер дома или название здания"/>
                </Form.Item>
                <Form.Item>
                    <label className="smallest-text">Этаж, квартира</label>
                    <Input value={floorDoor} name="floorDoor" onChange={onChange}
                        placeholder="Укажите этаж/кв"/>
                </Form.Item>
                <Form.Item>
                    <label className="smallest-text">Город</label>
                    <Select onChange={onSelect} value={cityId} placeholder="Выберите город">
                        <Option value='2'>Алматы</Option>
                        <Option value='3'>Шымкент</Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
}
Recipient.propTypes = {
    addVisible: PropTypes.bool,
    backRecipient: PropTypes.func,
    handleOkRecipient: PropTypes.func,
    handleAddCancel: PropTypes.func,
    onSelect: PropTypes.func,
    onChange: PropTypes.func,
    surname: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    numOfBuilding: PropTypes.string,
    floorDoor: PropTypes.string,
    cityId: PropTypes.string,
    address: PropTypes.string
};
export default Recipient;