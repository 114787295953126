import * as types from './actionTypes';

export function getSections() {
    return { type: types.GET_SECTIONS };
}
export function getCategoriesAndSubcategories(id, search) {
    return {type: types.GET_CAT_SUBCAT, id, search};
}
export function getTotalSections(cityId) {
    return { type: types.GET_SECTION_TOTAL, cityId };
}
export function getCategoriesBySectionId(id) {
    return { type: types.GET_CATEGORIES, id };
}
export function getSubCategoriesByCategoryId(id) {
    return { type: types.GET_SUBCATEGORIES, id };
}
export function getCategoriesByBrand(id, search) {
    return { type: types.GET_BRAND_CAT, id, search };
}
export function getBrandSections(cityId) {
    return { type: types.GET_BRAND_SECTION, cityId };
}
export function getSuperCategories(brandId, search) {
    return { type: types.GET_SUPERCATEGORIES, brandId, search };
}
export function getCategoriesBySuperId(id) {
    return { type: types.GET_CATEGORIES_SUPER, id };
}

