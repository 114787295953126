import React, {Component} from "react";
import HeaderTop from "../../components/header/headerTop";
import HeaderMain from "../../components/header/headerMain";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import {bindActionCreators} from "redux";
import * as categoryActions from "../../actions/categoryActions";
import {message, Spin} from "antd";
import Footer from "../../components/footer";
import './brand-category.scss';


class BrandCategory extends Component{
    constructor(props) {
        super(props);
        this.state = {
            brand: false,
            search: ''
        };
    }
    componentDidMount() {
        this.props.actions.getSuperCategories(this.props.match.params.id, '');
    }
    componentDidUpdate(prevProps) {
        if(this.props.match.params.id !== prevProps.match.params.id) {
            this.props.actions.getSuperCategories(this.props.match.params.id, '');
        }
    }
    onSearch = value => {
        this.props.actions.getSuperCategories(this.props.match.params.id, value);
    }
    componentWillReceiveProps(nextProps) {
        if (!this.state.brand || ( nextProps.location.query
            && nextProps.location.query.brandName !== localStorage.brandName)) {
            localStorage.setItem(`brandName`,
                nextProps.location.query ? nextProps.location.query.brandName : localStorage.brandName);
            this.setState({
                brand: true
            });
        }
    }

    render() {
        const { supercategories, isLoading, error, location } = this.props;
        const categoryItem = supercategories.map((item, i) => {
            return(
                <div key={i} className="section-item">
                    <Link className="category-name general-text" to={{pathname: `/category/${item.superCategory.id}`,
                            query: {category: item.superCategory.name, sectionId: item.superCategory.sectionId }}}>
                        <span className="" >
                            {item.superCategory.name}</span>
                            {/* <div className="supercategory-img">
                                <img src={`${ENDPOINT}/mp/products/photos/${item.imageUrl}`}/>
                            </div> */}
                    </Link>
                    {/* <div className="category-items">
                        {item.categories.map((itemSub, j) => {
                            return (
                                <div key={i + `` + j} className="medium-text">
                                    <Link to={{pathname: `/subcategory/${itemSub.id}`,
                                        query: {category: item.superCategory.name,
                                            categoryId: item.superCategory.id,
                                            sectionId: item.superCategory.sectionId,
                                            subCategory: itemSub.name}}}>{itemSub.name}</Link>
                                </div>
                            );
                        })}
                    </div> */}
                </div>
            );
        });
        if (error && error.response) {
            message.error(error.response.data);
        }
        return (
            <Spin spinning={isLoading}>
                <HeaderTop/>
                <HeaderMain/>
                <div className="wrapper">
                    <div className="section">
                        <div className="container">
                            <div className="brand-category-title">
                                <p className="large-text">{location.query ?
                                    location.query.brandName :localStorage.brandName}</p>
                                {/* <Search
                                    placeholder="Введите наименование категории"
                                    onSearch={this.onSearch}
                                    enterButton = {<Button style={{background: '#FFC700', color: '#000000'}}><SearchOutlined/></Button>}
                                /> */}
                            </div>
                            <div className="section-inner">
                                {categoryItem}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Spin>
        );
    }
}
BrandCategory.propTypes = {
    actions: PropTypes.object.isRequired,
    supercategories: PropTypes.array,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    location: PropTypes.object
};
const mapStateToProps = state => ({
    supercategories: state.category.supercategories,
    error: state.category.error,
    isLoading: state.category.isLoading,
});
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(categoryActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps) (BrandCategory);
