import React from 'react';
import {Button, Form, Input, Modal} from "antd";
import PropTypes from "prop-types";
import MaskedInput from "antd-mask-input";
function Login(props) {
    const {
        signInVisible,
        handleSignInCancel,
        handleSignInOk,
        formItemLayout,
        openSignUpModal,
        openForgotModal } = props;
    return(
        <Modal
            title="Войти в аккаунт"
            visible={signInVisible}
            onCancel={handleSignInCancel}
            footer={null}
        >
            <Form
                {...formItemLayout}
                className="login-form"
                name="login"
                onFinish={handleSignInOk}
                scrollToFirstError
            >
                <Form.Item
                    name="phone"
                    label="Телефон"
                    rules={[
                        {
                            required: true,
                            message: `Пожалуйста, введите свой номер телефона!`,
                        },
                    ]}
                >
                    <MaskedInput mask="+1(111)-111-11-11" placeholder="+7(777)-777-77-77" />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Пароль"
                    rules={[
                        {
                            required: true,
                            message: `Пожалуйста, введите свой пароль!`,
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="Введите пароль"/>
                </Form.Item>
                <Form.Item>
                    <Button
                        style={{width: `100%`, color: `#000`, height: `36px`, borderRadius: `5px`}}
                        type="primary"
                        htmlType="submit"
                    >
                        Войти
                    </Button>
                </Form.Item>
            </Form>
            <p
                className="account-text"
                onClick={openSignUpModal}>
                    Создайте акккаунт
            </p>
            <p
                className="account-text forget-pass"
                onClick={openForgotModal}>
                    Забыли пароль?
            </p>
        </Modal>
    );
}

Login.propTypes = {
    signInVisible: PropTypes.bool,
    formItemLayout: PropTypes.object,
    handleSignInCancel: PropTypes.func,
    handleSignInOk: PropTypes.func,
    openSignUpModal: PropTypes.func,
    openForgotModal: PropTypes.func,
};

export default Login;

