import React from 'react';
import PropTypes from 'prop-types';
import './aside.scss';

function Aside(props) {
    return(
        <div className="aside">
            <h2>Бонусы</h2>
            <div className="bonus-item">
                <div className="bonus-text">
                    <span >Баланс: </span>
                    <span>{props.bonus.activeBonuses}</span>
                </div>
                <div className="aside-img">
                    <img src="/assets/bonus.jpg" alt="bonus"/>
                </div>
            </div>
        </div>
    );
}

Aside.propTypes = {
    bonus: PropTypes.object
};
export default Aside;