import React, {useEffect} from 'react';
import HeaderTop from "../../components/header/headerTop";
import HeaderMain from "../../components/header/headerMain";
import Benefit from "../../components/benefit";
import ProductsSlider from "../../components/products-slider";
import Question from "../../components/question";
import Footer from "../../components/footer";
import BrandTabs from "../../components/brand-tabs";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as productActions from "../../actions/productActions";
import {connect} from "react-redux";

function Brands(props) {
    useEffect(() => {
        const product = {
            pageNumber: 1,
            pageSize: 15
        };
        props.productActions.getPopularProducts(product);
    }, []);

    const { popularProducts } = props;
    let breadCrumb = <div className="breadcrumb">
        <Link className="tiny-text" to={`/`}>Главная</Link>
        <span className="tiny-text"> / </span>
    </div>;
    let popularProduct = [], popularProd = {};
    if (popularProducts && popularProducts.length > 0) {
        popularProduct = popularProducts.map((item) => {
            return {
                id: item.id,
                img: item.photo_1,
                label: item.brand.brandName,
                title: item.name,
                price: item.price,
                promoPrice: item.promoPrice,
                want: item.section?.products
            };
        });
    }
    popularProd = {
        title: `Популярные товары`,
        popularProduct
    };
    return (
        <div>
            <HeaderTop/>
            <HeaderMain/>
            <div className="wrapper">
                <Benefit/>
                <BrandTabs breadCrumb={breadCrumb}/>
                <ProductsSlider productsSlider={popularProd.popularProduct &&
                    popularProd.popularProduct.length > 0 ? popularProd : {}}/>
                <Question/>
            </div>
            <Footer/>
        </div>
    );

}

Brands.propTypes = {
    popularProducts: PropTypes.array,
    productActions: PropTypes.object,
};

const mapStateToProps = (state) => ({
    popularProducts: state.product.popularProducts.productResponses,
    isLoading: state.product.isLoading,
    error: state.product.error
});

const mapDispatchToProps = dispatch => ({
    productActions: bindActionCreators(productActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps) (Brands);
