
import * as types from '../actions/actionTypes';

const initialState = {
    cartResponse: '',
    cart: [],
    isLoading: false,
    totalPrice: 0,
    shopListResponse: '',
    shoppingList: []
}
export default function cartReducer(state = initialState, action = null) {
    switch (action.type) {
        case types.ADD_CART:
            return { ...state, isLoading: true };
        case types.ADD_CART_SUCCESS:
            return { ...state, isLoading: false, cartResponse: action.payload};
        case types.DELETE_ALL_CART:
            return { ...state, isLoading: false, cartResponse: action.payload};
        case types.SHOPPING_LIST_ADD:
            return { ...state, isLoading: true };
        case types.SHOPPING_LIST_ADD_SUCCESS:
            return { ...state, isLoading: false, shopListResponse: action.payload};
        case types.SHOPPING_LIST_ADD_FAILED:
            return { ...state, isLoading: false, error: action.error};
        case types.ADD_CART_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.REMOVE_CART:
            return { ...state, isLoading: true };
        case types.CART_REMOVE_SUCCESS:
            return { ...state, isLoading: false, cartResponse: action.payload};
        case types.CART_REMOVE_FAILED:
            return { ...state, isLoading: false, error: action.error};
        case types.UPDATE_CART:
            return { ...state, isLoading: true };
        case types.UPDATE_CART_SUCCESS:
            return { ...state, isLoading: false, cartResponse: action.payload};
        case types.UPDATE_CART_FAILED:
            return { ...state, isLoading: false, error: action.error};
        case types.GET_CART:
            return { ...state, isLoading: true };
        case types.CART_RECEIVED:
            return { ...state, isLoading: false, cart: action.payload};
        case types.CART_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_SHOPPING_LIST:
            return { ...state, isLoading: true };
        case types.SHOPPING_LIST_RECEIVED:
            return { ...state, isLoading: false, shoppingList: action.payload};
        case types.SHOPPING_LIST_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.SHOPPING_LIST_DELETE:
            return { ...state, isLoading: true };
        case types.SHOPPING_LIST_DELETE_SUCCESS:
            return { ...state, isLoading: false, shopListResponse: action.payload};
        case types.SHOPPING_LIST_DELETE_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.SHOPPING_LIST_UPDATE:
            return { ...state, isLoading: true };
        case types.SHOPPING_LIST_UPDATE_SUCCESS:
            return { ...state, isLoading: false, shopListResponse: action.payload};
        case types.SHOPPING_LIST_UPDATE_FAILED:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}