import {all, put, takeLatest} from "@redux-saga/core/effects";
import * as types from "../actions/actionTypes";
import axios from "axios";
import jwt_decode from 'jwt-decode';
import setAuthToken from "../utils/setAuthToken";
import { message } from 'antd';

function* signIn(action) {
    const { data } = action;
    try {
        const authResponse = yield axios.post(`${types.ENDPOINT}/auth/login`, data).then(res => res.data);
        const { token } = authResponse;
        setAuthToken(token);
        localStorage.setItem(`token`, token);
        const decoded = jwt_decode(token);
        yield put({type: types.SET_CURRENT_USER, payload: decoded});
        yield axios.put(`${types.ENDPOINT}/mp/carts/v1/orderlist?uid=${document.cookie.split(`;`)[0].slice(8)}`, null, {header: new Headers({[`Authorization`]: token})});
        yield axios.put(`${types.ENDPOINT}/mp/carts/v1/cartItems/uid?uid=${document.cookie.split(`;`)[0].slice(8)}`, null, {header: new Headers({[`Authorization`]: token})});
        window.location.reload();
    } catch (error) {
        yield put({type: types.SIGN_IN_FAILED, error});
        message.error(`Ошибка при авторизации!`);
    }
}
function* signUp(action) {
    const { data, openCode } = action;
    try {
        const authResponse = yield axios.post(`${types.ENDPOINT}/users/api/v1/register`, data).then(res => res.data);
        yield put({type: types.SIGN_UP_SUCCESS, payload: authResponse});
        openCode();
    } catch (error) {
        yield put({type: types.SIGN_UP_FAILED, error});
        // message.error(error.response.data.message);
    }
}
function* signUpConfirm(action) {
    const { data } = action;
    try {
        const authResponse = yield axios.put(`${types.ENDPOINT}/users/api/v1/register/confirm?phone=${data.phone}&registrationCode=${data.regCode}`).then(res => res.data);
        yield put({type: types.SIGN_UP_CONFIRMED, payload: authResponse});
        window.location.reload();
    } catch (error) {
        yield put({type: types.SIGN_UP_CONFIRM_FAILED, error});
    }
}

export function* signOut() {
    localStorage.removeItem(`token`);
    setAuthToken(false);
    yield put ({type: types.SET_CURRENT_USER, payload: {}});
    window.location.reload();
}

export function* authSaga() {
    yield all([
        yield takeLatest(types.SIGN_IN, signIn),
        yield takeLatest(types.SIGN_UP, signUp),
        yield takeLatest(types.SIGN_OUT, signOut),
        yield takeLatest(types.SIGN_UP_CONFIRM, signUpConfirm)
    ]);
}