import * as types from './actionTypes';

export function addCard(history) {
    return { type: types.ADD_CARD, history };
}

export function deleteCard(id) {
    return { type: types.DELETE_CARD, id };
}

export function getCards() {
    return { type: types.GET_CARDS };
}

export function addRecipient(data) {
    return { type: types.ADD_RECIPIENT, data };
}

export function getTime(id) {
    return { type: types.GET_TIME, id };
}


export function addOrder(data, history) {
    return { type: types.ADD_ORDER, data, history };
}

export function payOrder(data, closeModal, history) {
    return { type: types.PAY_ORDER, data, closeModal, history };
}

export function getOrders(pageNumber, pageSize) {
    return { type: types.GET_ORDER, pageNumber, pageSize };
}

export function getOrderedProducts(id) {
    return { type: types.GET_ORDER_PRODUCT, id };
}

export function getOrderById(id) {
    return { type: types.GET_ORDER };
}

export function reorder(id, history) {
    return { type: types.REORDER, id, history };
}

export function cancelOrder(id, orderId) {
    return { type: types.CANCEL_ORDER, id, orderId };
}

export function cancelPurchase(id, orderId) {
    return { type: types.CANCEL_PURCHASE, id, orderId };
}

export function refundProduct(refund) {
    return { type: types.REFUND_PRODUCT, refund };
}

export function editRecipient(data) {
    return { type: types.EDIT_RECIPIENT, data };
}

export function deleteRecipient(id) {
    return { type: types.DELETE_RECIPIENT, id };
}

export function getRecipients() {
    return { type: types.GET_RECIPIENT };
}