import React from 'react';
import {Button, Input, Modal, Spin} from "antd";
import PropTypes from "prop-types";

function RecoverCode(props) {
    const { forgotConfirmVisible, isLoading, onChange, regCodeForgot, sendForgot, sendForgotConfirm} = props;
    return(
        <Modal
            closable={false}
            title="Введите код"
            visible={forgotConfirmVisible}
            footer={null}
        >
            <Spin spinning={isLoading}>
                <div className="code-register">
                    <p className="medium-text" align="center">Код восстановления</p>
                    <Input name='regCodeForgot' value={regCodeForgot} onChange={onChange}
                        placeholder="Введите код сюда"/>
                    <Button onClick={sendForgotConfirm}
                        style={{width: `100%`, color: `#000`, height: `36px`, borderRadius: `5px`}}
                        type="primary">Отправить</Button>
                    <Button className="forget-pass"
                        onClick={sendForgot} style={{width: `100%`, height: `36px`, borderRadius: `5px`}}
                        type="link">Отправить код заново</Button>
                </div>
            </Spin>
        </Modal>
    );
}

RecoverCode.propTypes = {
    forgotConfirmVisible: PropTypes.bool,
    isLoading: PropTypes.bool,
    onChange: PropTypes.func,
    sendForgot: PropTypes.func,
    regCodeForgot: PropTypes.string,
    sendForgotConfirm: PropTypes.func
};

export default RecoverCode;

