import React from 'react';
import {Button, Modal, Spin} from "antd";
import PropTypes from "prop-types";

function Card(props) {
    const { cardVisible, isLoading, addCard, handleCardCancel,userCards } = props;
    return(
        <Modal
            title="Мои карты"
            onCancel={handleCardCancel}
            visible={cardVisible}
            footer={[
                <Button onClick={addCard} key="ok" type="primary">Добавить карту</Button>,
                <Button onClick={handleCardCancel} key="back">Отмена</Button>,
            ]}
        >
            <Spin spinning={isLoading}>
                {userCards}
            </Spin>
        </Modal>
    );
}

Card.propTypes = {
    cardVisible: PropTypes.bool,
    isLoading: PropTypes.bool,
    handleCardCancel: PropTypes.func,
    addCard: PropTypes.func,
    userCards: PropTypes.array
};

export default Card;

