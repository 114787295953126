import React, {Component} from "react";
import HeaderTop from "../../components/header/headerTop";
import HeaderMain from "../../components/header/headerMain";
import Footer from "../../components/footer";

import {DeleteOutlined, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as cartActions from "../../actions/cartActions";
import * as authActions from "../../actions/authActions";
import {Button, Empty, Spin, message, Modal} from "antd";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {ENDPOINT} from "../../actions/actionTypes";

import './purchase.scss';

class Purchase extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            favorite: '',
            visible: false,
            deliveryMethod: 'delivery',
            pickup: false,
            recipientId: null,
        };
    }
    componentDidMount() {    
        const uid = !this.props.isAuthenticated ? document.cookie.split(`;`)[0].slice(8) : ``
        this.props.cartActions.getShoppingList(uid);   
        this.props.cartActions.getCart(uid);
    }

    handleDelete = id => {
        this.props.cartActions.deleteShoppingList(id, this.props.isAuthenticated ? true : false);
    }

    handleCount = (type, item) => {
        let count = 0;
        if (type === 'inc') {
            count = 1;
        } else if (type === 'dec') {
            count = -1;
        }
        const cartItem = {
            id: item.id,
            productId: item.productId,
            userId: 0,
            productCount: count
        }
        this.props.cartActions.updateShoppingList(cartItem, this.props.isAuthenticated ? true : false);
    }

    handleDeleteAll = () => {
        this.props.cartActions.deleteAllCart();
    }
    onChangeRadio = e => {
        this.setState({
            deliveryMethod: e.target.value
        })
        if (e.target.value === 'pickup') {
            this.setState({
                pickup: true,
            })
        } else {
            this.setState({
                pickup: false,
            })
        }
    };
    addCart = item => {
        const uid = document.cookie.split(';')[0].slice(8);
        const { cart, cartActions, isAuthenticated } = this.props;
        const cartItem = {
            cartItem: {
                productId: item.productId,
                userId: 0,
                productCount: item.productCount,
                brandId: item.brand.brandId
            },
            productAttributes: []
        };
        if (cart?.length === 0) {
            cartActions.addCart(cartItem, () => {}, !isAuthenticated ? uid : '');
            message.success('Добавлено в корзину!')
        } else if (cart && cart?.length > 0 && item.brand.brandId === cart[0]?.brandResponse?.brandId) {
            cartActions.addCart(cartItem, () => {}, !isAuthenticated ? uid : '');
            message.success('Добавлено в корзину!')
        } else if (cart && cart?.length > 0 && item.brandId !== cart[0]?.brandResponse?.brandId) {
            message.error(`Для дополнения корзины товаром из другого магазина/заведения,
            завершите текущий и оформите новый, пожалуйста`, 5);
        } 
    }
    render() {
        const {isLoading} = this.props;  
        let isEmpty = '';

        // cart.forEach((item) => {
        //     item.brandResponse.minOrderSum > item.totalPrice ? brandsTrue.push('false') : brandsTrue.push('true');
        //     sum = sum + item.totalPrice;
        //     if (deliveryMethod === "delivery") {
                
        //         comission = comission + item.totalPrice * item.brandResponse.userDeliveryCommission/100;
        //         delivery = delivery + (item.brandResponse.deliveryFixedSum !== null && item.brandResponse.deliveryFixedSum != 0.0 ? item.brandResponse.deliveryFixedSum :  item.totalPrice *  item.brandResponse.deliverySum/100);
        //     } else {
        //         comission = comission + item.totalPrice * item.brandResponse.userPickupCommission/100
        //     }
        // });
        const cartItems = this.props.shoppingList.map((item, i) => {
            return (
                <div key={i} className="purchase-item">
                            <div key={i} className="basket-item--product">
                                <div className="basket-elements">
                                    <div className="list-item basket-items">
                                        <div className="basket-item">
                                            <Link to={`/product/${item.cartItem.productId}`} className="basket-img">
                                                <img src={`${ENDPOINT}/mp/products/photos/${item.cartItem.photo_1}`} alt="product"/>
                                            </Link>
                                            <Link to={`/product/${item.cartItem.productId}`} className="basket-content">
                                                <p className="general-text">{item.cartItem.name}</p>
                                                <p style={{marginTop: '10px'}} className="medium-text">Артикул: {item.cartItem.article}</p>
                                            </Link>                                
                                        </div>                               
                                    </div>
                                    <div className="list-item basket-count">
                                        {item.cartItem.productCount > 1 ? <MinusCircleOutlined
                                            onClick={() => this.handleCount('dec', item.cartItem)}
                                            style={{
                                                color: `#000`, cursor: `pointer`,
                                                fontSize: `28px`, marginRight: `10px`
                                            }}/> : ''}
                                        <span>{item.cartItem.productCount}</span>
                                        <PlusCircleOutlined
                                            onClick={() => this.handleCount('inc', item.cartItem)}
                                            style={{
                                                color: `#000`, cursor: `pointer`,
                                                fontSize: `28px`, marginLeft: `10px`
                                            }}/>
                                    </div>
                                    <div className="general-text list-item basket-price">
                                        <div  className="general-text list-item basket-price">
                                            {item.cartItem.promoPrice ? <span className="tiny-text"
                                                style={{textDecoration: 'line-through'}}>{item.cartItem.price}тг</span> : 
                                                item.cartItem.price}
                                        </div>
                                        <div className="cart-price">
                                        <p 
                                            style={{marginRight: '5px', fontSize: '22px'}}>{item.cartItem.unitPrice ? `${item.cartItem.unitPrice * item.cartItem.productCount}тг` : ''}</p>
                                              <Button onClick={() => this.addCart(item.cartItem)} type="primary" style={{height: '40px', width: '200px', color: '#000'}}>В корзину</Button> 
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="footer-basket">
                                    <p onClick={() => this.handleDelete(item.cartItem.id)}><DeleteOutlined/>Удалить</p>
                                </div>                              
                            </div>                    
                </div>
            )
        });
        if (this.props.shoppingList.length === 0) {
            isEmpty = <Empty style={{width: `100%`, marginTop: "20px"}}
                                   description={
                                       <span>
                                             Пусто
                                       </span>
                                   }/>;
        }
        return (
            <Spin spinning={isLoading}>
                <HeaderTop/>
                <HeaderMain/>
                <div className="wrapper-white">             
                    <div className="basket">
                        <div className="container-basket">
                            {/* <div className="basket-title">
                                <span onClick={this.handleDeleteAll} className="medium-text">Удалить все</span>
                            </div> */}
                            <div className="purchase-inner">
                            <h2>Список покупок</h2>
                                <div className="basket-header">
                                    {/* <p className="list-item large-text">{quantities.length === 1 ? `${quantities.length} товар` : quantities.length > 1 && quantities.length <5 ?  `${quantities.length} товара` : `${quantities.length} товаров`} </p> */}
                                    {/*<p className="list-item tiny-text">Количество</p>*/}
                                    {/*<p className="list-item tiny-text">Цена</p>*/}
                                </div>
                                {isEmpty}
                                {cartItems}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Spin>
        );
    }
}
Purchase.propTypes = {
    cartActions: PropTypes.object.isRequired,
    shoppingList: PropTypes.array,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
    quantity: PropTypes.number
};
const mapStateToProps = state => ({
    shoppingList: state.cart.shoppingList,
    quantity: state.cart.quantityProduct,
    isLoading: state.cart.isLoading,
    error: state.cart.error,
    isAuthenticated: state.auth.isAuthenticated,
    cart: state.cart.cart
});
const mapDispatchToProps = dispatch => ({
    cartActions: bindActionCreators(cartActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps) (withRouter(Purchase));
