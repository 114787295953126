import {all, put, takeLatest} from "@redux-saga/core/effects";
import * as types from "../actions/actionTypes";
import axios from "axios";
import {message} from 'antd';

function* fetchProduct(action) {
    const { id } = action;
    try {
        const product = yield axios.get(`${types.ENDPOINT}/mp/products/products/${id}`).then(res => res.data);
        yield put({type: types.PRODUCT_RECEIVED, payload: product});
    } catch (error) {
        yield put({type: types.PRODUCT_FAILED, error});
    }
}

export function* getMarketBrands(action) {
    const {cityId} = action;
    try {
        const brands = yield axios.get(`${types.ENDPOINT}/mp/products/sections/brands/all?cityId=${cityId}`).then(res => res.data);
        yield put({type: types.BRANDS_RECEIVED, payload: brands});
    } catch (error) {
        yield put({type: types.BRANDS_FAILED, error});
    }
}

function* createUserClick(action) {
    const { productId } = action;
    try {
        const userClick = yield axios.patch(`${types.ENDPOINT}/mp/products/userClicks?productId=${productId}`)
            .then(res => res.data);
        yield put({type: types.CREATE_USER_CLICK_SUCCESS, payload: userClick});
    } catch (error) {
        yield put({type: types.CREATE_USER_CLICK_FAILED, error});
    }
}
function* fetchProducts(action) {
    let { brandId, categoryId, highCost, lowCost, pageNumber, pageSize, sortName,
        subCategoryId, sectionId, search, history, global, superCategoryId, promo } = action.product;
    if (!brandId) {
        brandId = ``;
    }
    if (!search) {
        search = ``;
    }
    if(!categoryId) {
        categoryId = ``;
    }
    if (!sectionId) {
        sectionId = ``;
    }
    if(!highCost) {
        highCost = ``;
    }
    if(!lowCost) {
        lowCost = ``;
    }
    if (!pageNumber) {
        pageNumber = 1;
    }
    if(!pageSize) {
        pageSize = 150;
    }
    if(!sortName) {
        sortName = `costAscend`;
    }
    if(!subCategoryId) {
        subCategoryId = ``;
    }
    if(!superCategoryId) {
        superCategoryId = ``;
    }
    try {
        const products = yield axios.get(`${types.ENDPOINT}/mp/products/products`,
            {params:
                {
                    pageNumber: pageNumber,
                    pageSize: pageSize,
                    superCategoryId: superCategoryId,
                    brandId: brandId,
                    categoryId: categoryId,
                    lowCost: lowCost,
                    highCost: highCost,
                    sortName: sortName,
                    subCategoryId: subCategoryId,
                    sectionId: sectionId,
                    search: search
                }
            }).then(res => res.data);
        yield put({type: types.PRODUCTS_RECEIVED, payload: products});
        if (global && products.productResponses.length > 0) {
            history.push(`/category/${products.productResponses[0].superCategory.id}`);
        } 
    } catch (error) {
        yield put({type: types.PRODUCTS_FAILED, error});
    }
}

function* fetchProductsSearch(action) {
    let { brandId, categoryId, highCost, lowCost, pageNumber, pageSize, sortName,
        subCategoryId, sectionId, search, history, global, superCategoryId } = action.product;
    if (!brandId) {
        brandId = ``;
    }
    if (!search) {
        search = ``;
    }
    if(!categoryId) {
        categoryId = ``;
    }
    if (!sectionId) {
        sectionId = ``;
    }
    if(!highCost) {
        highCost = ``;
    }
    if(!lowCost) {
        lowCost = ``;
    }
    if (!pageNumber) {
        pageNumber = 1;
    }
    if(!pageSize) {
        pageSize = 15;
    }
    if(!sortName) {
        sortName = `costAscend`;
    }
    if(!subCategoryId) {
        subCategoryId = ``;
    }
    if(!superCategoryId) {
        superCategoryId = ``;
    }
    try {
        const products = yield axios.get(`${types.ENDPOINT}/mp/products/products/nopag`,
            {params:
                {
                    pageNumber: pageNumber,
                    pageSize: pageSize,
                    superCategoryId: superCategoryId,
                    brandId: brandId,
                    categoryId: categoryId,
                    lowCost: lowCost,
                    highCost: highCost,
                    sortName: sortName,
                    subCategoryId: subCategoryId,
                    sectionId: sectionId,
                    search: search
                }
            }).then(res => res.data);
        yield put({type: types.PRODUCTS_SEARCH_RECEIVED, payload: products});
        if (global && products?.products?.length > 0) {
            history.push({pathname: `/result`, state: products, search: search});
        } else if (global) {
            message.error(`К сожалению такого продукта нет`)
        }
    } catch (error) {
        yield put({type: types.PRODUCTS_SEARCH_FAILED, error});
    }
}

function* fetchPopularProducts(action) {
    let { pageNumber, pageSize } = action.product;
    if (!pageNumber) {
        pageNumber = 1;
    }
    if(!pageSize) {
        pageSize = 15;
    }
    try {
        const products = yield axios.get(`${types.ENDPOINT}/mp/products/products/popular?pageSize=${
            pageSize}&pageNumber=${pageNumber}`).then(res => res.data);
        yield put({type: types.POPULAR_PRODUCTS_RECEIVED, payload: products});
    } catch (error) {
        yield put({type: types.POPULAR_PRODUCTS_FAILED, error});
    }
}

function* fetchNewProducts(action) {
    let { pageNumber, pageSize, brandId } = action.product;
    if (!pageNumber) {
        pageNumber = 1;
    }
    if(!pageSize) {
        pageSize = 15;
    }
    if(!brandId) {
        brandId = ``;
    }
    try {
        const products = yield axios.get(`${types.ENDPOINT}/mp/products/products/new?pageSize=${
            pageSize}&pageNumber=${pageNumber}&brandId=${brandId}`).then(res => res.data);
        yield put({type: types.NEW_PRODUCTS_RECEIVED, payload: products});
    } catch (error) {
        yield put({type: types.NEW_PRODUCTS_FAILED, error});
    }
}

export function* productSaga() {
    yield all([
        takeLatest(types.GET_PRODUCT, fetchProduct),
        takeLatest(types.GET_PRODUCTS, fetchProducts),
        takeLatest(types.GET_PRODUCTS_SEARCH, fetchProductsSearch),
        takeLatest(types.CREATE_USER_CLICK, createUserClick),
        takeLatest(types.GET_BRANDS, getMarketBrands),
        takeLatest(types.GET_POPULAR_PRODUCTS, fetchPopularProducts),
        takeLatest(types.GET_NEW_PRODUCTS, fetchNewProducts),
    ]);
}