import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Modal, Input, Button, Menu, Dropdown, Skeleton, Empty, message, Popover, Select} from 'antd';
import './headerTop.scss';
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as authActions from "../../../actions/authActions";
import * as categoryActions from "../../../actions/categoryActions";
import * as paymentActions from "../../../actions/paymentActions";
import * as userActions from "../../../actions/userActions";
import {connect} from "react-redux";
import classNames from 'classnames';
import MenuOutlined from "@ant-design/icons/lib/icons/MenuOutlined";
import {DeleteOutlined} from "@ant-design/icons";
import ForgotPassword from "../../forgot-password";
import RecoverCode from "../../recover-code";
import NewPassword from "../../new-password";
import Register from "../../register";
import Login from "../../login";
import Card from "../../card";
const { SubMenu } = Menu;
const { Option } = Select;

class HeaderTop extends Component {
    constructor(){
        super();
        this.state = {
            signInVisible: false,
            signUpVisible: false,
            modalVisible: false,
            cardVisible: false,
            openKeys: [''],
            mobileMenu: false,
            codeVisible: false,
            phone: '',
            forgotVisible: false,
            forgotConfirmVisible: false,
            passwordVisible: false,
            newPassword: '',
            regCodeForgot: '',
            phoneForgot: '',
            min: 0,
            sec: 59,
            cityId: '2'
        }
    }
    rootSubmenuKeys = ['sub1', 'sub2', 'sub3']
    onOpenChange = openKeys => {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys });
        } else {
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
            });
        }
    };
    openSignInModal = () => {
        this.setState({
            signInVisible: true,
        })
    };
    handleCity = () => {
        localStorage.setItem('cityId', this.state.cityId)
        this.setState({
            visible: false
        })
        window.location.reload()
    }

    onSelect = e => {
        this.setState({
            cityId: e,          
        })
    }
    handleCardCancel = () => {
        this.setState({
            cardVisible: false,
        })
    }

    openCardModal = () => {
        this.setState({
            cardVisible: true
        })
        this.props.paymentActions.getCards();
    }
     openSignUpModal = () => {
         this.setState({
             signUpVisible: true,
             signInVisible: false
         })
    };
    handleTime = () => {

    }
    handleCodeModal = () => {
        this.setState({
            codeVisible: true,
            signUpVisible: false
        })
    }
     handleSignInOk = values => {
        let phone = ``;
        for (let digit of values.phone) {
            if (digit !== `-` && digit !== `+` && digit !== `(` && digit !== `)`) {
                phone += digit;
            }
        }
        const loginForm = {
            phone: phone,
            password: values.password
        };
        this.props.authActions.signIn(loginForm, this.props.history);
    };
     handleSignUpOk = values => {
         let phone = ``;
         for (let digit of values.phone) {
             if (digit !== `-` && digit !== `+` && digit !== `(` && digit !== `)`) {
                 phone += digit;
             }
         }
         const registerForm = {
             firstName: values.firstName,
             lastName: values.lastName,
             birthdate: values.birthDate,
             email: values.email,
             phone: phone,
             password: values.password,
             gender: values.gender,
             language: "",
             cityId: values.cityId,
             platform: "web",
         }
         this.props.authActions.signUp(registerForm, this.handleCodeModal)
         this.setState({
             phone: phone
         })
    };
     handleSignInCancel = () => {
         this.setState({
             signInVisible: false
         })
    };
     handleSignUpCancel = () => {
         this.setState({
             signUpVisible: false,
         })
    };
    handleForgotCancel = () => {
        this.setState({
            forgotVisible: false
        })
    }
     signOut = () => {
        //  window.location.reload()
         this.props.authActions.signOut();
     }
     handleMenu = () => {
         this.setState({
             mobileMenu: true
         })
     }
     sendCode = () => {
         const data = {
             phone: this.state.phone,
             regCode: this.state.regCode
         }
        this.props.authActions.signUpConfirm(data);
     }
    addCard = () => {
         this.props.paymentActions.addCard(this.props.history);
    }
    onChange = e => {
         this.setState({
             [e.target.name]: e.target.value
         })
    }
    handleCloseForOpenConf = () => {
         this.setState({
             forgotVisible: false,
             forgotConfirmVisible: true
         })
    }
    handleCloseConfOpenPass = () => {
        this.setState({
            forgotConfirmVisible: false,
            passwordVisible: true
        })

    }
    handleClosePass = () => {
        this.setState({
            passwordVisible: false
        })
    }
    changePassword = () => {
        let phone = ``;
        for (let digit of this.state.phoneForgot) {
            if (digit !== `-` && digit !== `+` && digit !== `(` && digit !== `)`) {
                phone += digit;
            }
        }
        const data = {
            phone: phone,
            newPassword: this.state.newPassword,
            regCode: this.state.regCodeForgot
        }
        if (this.state.newPassword) {
            this.props.userActions.changePassword(data, this.handleClosePass)
        } else {
            message.error('Напишите новый пароль!');
        }
    }
    sendForgot = () => {
        let phone = ``;
        for (let digit of this.state.phoneForgot) {
            if (digit !== `-` && digit !== `+` && digit !== `(` && digit !== `)`) {
                phone += digit;
            }
        }
        this.setState({
            signInVisible: false
        })
         if (this.state.phoneForgot) {
             this.props.userActions.forgotPassword(phone, this.handleCloseForOpenConf)
         } else {
             message.error('Напишите номер телефона!');
         }
    }
    sendForgotConfirm = () => {
        let phone = ``;
        for (let digit of this.state.phoneForgot) {
            if (digit !== `-` && digit !== `+` && digit !== `(` && digit !== `)`) {
                phone += digit;
            }
        }
         const data = {
             phone: phone,
             regCode: this.state.regCodeForgot
         }
        if (this.state.regCodeForgot) {
            this.props.userActions.forgotPasswordConfirm(data, this.handleCloseConfOpenPass)
        } else {
            message.error('Напишите код!');
        }
    }
    openForgotModal = () => {
         this.setState({
             forgotVisible: true,
             signInVisible: false
         })
    }
     componentWillReceiveProps(nextProps, nextContext) {
         if((nextProps.visible !== undefined || nextProps.visible || true) && nextProps.component === "product" && nextProps.text === "pressed" &&
             !nextProps.isAuthenticated) {
             this.setState({
                 signInVisible: true
             })
         } else {
             this.setState({
                 signInVisible: false
             })
         }
     }
    closeMenu = () => {
         this.setState({
             mobileMenu: false
         })
    }
    deleteCard = id => {
        this.props.paymentActions.deleteCard(id)
    }
    componentDidMount() {
         const { isAuthenticated, userActions } = this.props;
         if (isAuthenticated) {
             userActions.getProfile();
         }
         
    }
   

    render() {
        const content = (
            <div>
                <Select style={{width: '100%'}} value={this.state.cityId} onSelect={this.onSelect}>
                    <Option value="2">Алматы</Option>
                    <Option value="3">Шымкент</Option>
                </Select>  
                <Button style={{width: '100%', margin: '10px 0'}} type="primary" onClick={this.handleCity}>Ок</Button>
            </div>
            
        )
        const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
        const { isAuthenticated, totalSections, isLoadingSection, cards, isLoadingAuth, profile, isLoadingCard, cityId } = this.props;
        const { signInVisible, signUpVisible, forgotVisible, forgotConfirmVisible, passwordVisible, cardVisible,  } = this.state;
        let userCards = cards.map((item, i) => (
            <div className="medium-text user-card" key={i}>
                <div>{item.masked_number}</div>
                <DeleteOutlined  onClick={() => this.deleteCard(item.card_id)}/>
            </div>
        ))
        if (userCards.length === 0) {
            userCards = <Empty/>;
        }
        let profileName = ``;
        if (profile && (profile.name || profile.surname)) {
            profileName = `${profile.name} ${profile.surname}`;
        }

         const formItemLayout = {
             labelCol: {
                 xs: {
                     span: 24,
                 },
                 sm: {
                     span: 24,
                 },
             },
             wrapperCol: {
                 xs: {
                     span: 24,
                 },
                 sm: {
                     span: 24,
                 },
             },
         };
         const menu = (
             <div className="user-menu">
                 <Menu>
                     <Menu.Item key="0">
                         <Link to={`/account`}>
                             Мои данные
                         </Link>
                     </Menu.Item>
                     <Menu.Item key="1">
                         <Link to={`/order`}>
                             Мои заказы
                         </Link>
                     </Menu.Item>
                     <Menu.Item key="2">
                         <p onClick={this.openCardModal}>
                             Мои карты
                         </p>
                     </Menu.Item>
                     <Menu.Item key="3">
                         <p onClick={this.signOut}>
                             Выйти
                         </p>
                     </Menu.Item>
                 </Menu>
             </div>
         );
         const menuMobile = totalSections.map((item, i) => {
                 return (
                     <SubMenu
                         key={`sub ${i+1}`}
                         title={
                             <span>
                                  <Skeleton className="skeleton" active loading={isLoadingSection} paragraph={{rows: 0}}>
                                 {item.section.name}
                                  </Skeleton>
                             </span>
                         }
                     >
                         {item.brands && item.brands.length > 0 ? item.brands.map((itemBrand, j) => {
                             return (
                                 <Menu.Item key={i + '' + j}>
                                     <Link className='ad-tab-link' to={{pathname: `/sections/brand/${itemBrand.brandId}`,
                                         query: {brandName: itemBrand.brandName, brandId: itemBrand.brandId}}}>
                                         {itemBrand.brandName}
                                     </Link>
                                 </Menu.Item>
                             )
                         }) : []}
                     </SubMenu>
                 )      
         })
         return(
             <div className="header-top">
                 <div className="container">
                     <div className="header-top--inner">
                        <MenuOutlined onClick={this.handleMenu} className="menu" />
                         {isAuthenticated ? 
                          <span 
                            className="city" 
                            style={{marginRight: `30px`, fontSize: '16px'}}>
                                <img 
                                    className="location-img"  
                                    width="30" 
                                    height="30" 
                                    src="/assets/icons/location.svg"/> 
                                {localStorage.cityId == 3 ? 'Шымкент' : 'Алматы'}
                            </span>
                   : <Popover cursor="pointer" content={content} title="Сменить город" trigger="click">
                            <span 
                                className="city" 
                                style={{marginRight: `30px`, fontSize: '16px'}}>
                                    <img 
                                        className="location-img"  
                                        width="30" 
                                        height="30" 
                                        src="/assets/icons/location.svg"
                                    /> {localStorage.cityId == 3 ? 'Шымкент' : 'Алматы'}
                                    </span>
                    </Popover>}  
                                <div className="benefit-first--images">
                                Установи приложение Smart Plaza
                                    и получи массу преимуществ!
                                    <a href='https://apps.apple.com/kz/app/smartplaza/id1298078049'>
                                        <img src="/assets/appstore.svg" alt='appStore'/>
                                    </a>
                                    <a href='https://play.google.com/store/apps/details?id=kz.smartplaza'>
                                        <img src="/assets/googleplay.svg" alt='playMarket'/>
                                    </a>
                                </div>                        
                         <div onClick={this.closeMenu} className={classNames("overlay", {"overlay-active": this.state.mobileMenu})}/>                                
                         <div className="user-header">                    
                             <ul style={{display: 'flex', alignItems: 'center'}}>
                                 {/*<li>*/}
                                 {/*    <Link to={`/online`}>*/}
                                 {/*        Online pay*/}
                                 {/*    </Link>*/}
                                 {/*</li>*/}
                                 {/*<li>*/}
                                 {/*    <Link to={`/pay`}>*/}
                                 {/*        Платежи*/}
                                 {/*    </Link>*/}
                                 {/*</li>*/}
                                 {/*<li>*/}
                                 {/*    <Link to={`/help`}>*/}
                                 {/*        Помощь*/}
                                 {/*    </Link>*/}
                                 {/*</li>*/}
                                 {/* {isAuthenticated ?
                                     <div className="bonus-fav">
                                 <li>
                                     <Link to={`/bonuses`}>
                                         Бонусы
                                     </Link>
                                 </li>
                                 <li>
                                     <Link to={`/favorite`}>
                                         Избранное
                                     </Link>
                                 </li>
                                
                                     </div>
                                     : ''} */}
                                      
                             </ul>      
                                             
                             <div className="header-top--register">                                 
                                 {isAuthenticated ?
                                     <div className="header-top--register-inner">
                                         {/* <div>
                                             <Link to={'/bonuses'}>Бонусы</Link>
                                             <Link to={'/favorite'}>Избранное</Link>
                                         </div> */}
                                     <div className="user-register-name">
                                         <Dropdown overlay={menu}>
                                             <div style={{display: 'flex', alignItems: 'center', cursor: `pointer`}}>
                                                 <img src="/assets/user.svg" alt="user"/>
                                                 <span className="location-img" style={{marginLeft: `5px`}}>{profileName}</span>
                                             </div>
                                         </Dropdown>
                                 </div>
                                     </div>: ''}
                                     
                                 {!isAuthenticated ? <div><span style={{cursor: `pointer`}} onClick={this.openSignInModal}>Войти</span> / <span style={{cursor: `pointer`}} onClick={this.openSignUpModal}>Регистрация</span></div> : ''}
                             </div>
                         </div>
                     </div>
                 </div>
                 <Login 
                    openForgotModal={this.openForgotModal} 
                    openSignUpModal={this.openSignUpModal} 
                    handleSignInCancel={this.handleSignInCancel} 
                    handleSignInOk={this.handleSignInOk} 
                    signInVisible={signInVisible} 
                    formItemLayout={formItemLayout}
                />
                 <ForgotPassword 
                    forgotVisible={forgotVisible} 
                    sendForgot={this.sendForgot} 
                    handleForgotCancel={this.handleForgotCancel}
                    isLoading={isLoadingAuth} 
                    onChange={this.onChange} 
                    phoneForgot={this.state.phoneForgot} 
                />
                 <RecoverCode 
                    forgotConfirmVisible={forgotConfirmVisible} 
                    sendForgotConfirm={this.sendForgotConfirm} 
                    sendForgot={this.sendForgot}
                    isLoading={isLoadingAuth} 
                    onChange={this.onChange} 
                    regCodeForgot={this.state.regCodeForgot} 
                />
                 <NewPassword 
                    passwordVisible={passwordVisible} 
                    changePassword={this.changePassword}
                    isLoading={isLoadingAuth} 
                    onChange={this.onChange} 
                    newPassword={this.state.newPassword} 
                />
                <Register 
                    handleSignUpCancel={this.handleSignUpCancel} 
                    handleSignUpOk={this.handleSignUpOk} 
                    signUpVisible={signUpVisible} 
                    formItemLayout={formItemLayout}
                    dateFormatList={dateFormatList}
                />
                <Menu className={classNames("mobile-menu", {"mobile-menu-active": this.state.mobileMenu})}
                     mode="inline"
                     openKeys={this.state.openKeys}
                     onOpenChange={this.onOpenChange}
                     style={{ width: 256 }}
                 >
                     <Menu.Item key="1000">
                     <Link to={`/`}>
                            <div style={{width: '50px', height: '50px'}} className="logo">
                                <img  src="/assets/smartplazalogo.svg" alt="logo"/>
                            </div>
                        </Link>
                        </Menu.Item>
                      {menuMobile}
                    <Menu.Divider/>
                     <Menu.Item key='100'>
                         <Link to={`/brands`}>
                            Все бренды
                         </Link>
                     </Menu.Item>
                     {/*<Menu.Item key='200'>*/}
                     {/*    <Link  to={`/online`}>*/}
                     {/*        Online Pay*/}
                     {/*    </Link>*/}
                     {/*</Menu.Item>*/}
                     {/*<Menu.Item key='201'>*/}
                     {/*    <Link  to={`/help`}>*/}
                     {/*        Помощь*/}
                     {/*    </Link>*/}
                     {/*</Menu.Item>*/}
                     <Menu.Divider/>
                     {
                         isAuthenticated ?
                         <Menu.ItemGroup className={"user-info"}>
                             <Menu.Item key='101'>
                                 <Link style={{fontWeight: 500, color: '#000000'}} to={`/account`}>
                                     Мои данные
                                 </Link>
                             </Menu.Item>
                             <Menu.Item key='102'>
                             <span style={{fontSize: '12px', color: '#3c91e1'}} onClick={this.signOut}>
                                 Выйти
                             </span>
                             </Menu.Item>
                         </Menu.ItemGroup>  : ''
                     }
                     {isAuthenticated ?  <Menu.Item key='110'>
                         <Link style={{fontWeight: 500, color: '#000000'}} to={`/order`}>
                             Мои заказы
                         </Link>
                     </Menu.Item> : ''}
                     {isAuthenticated ? <Menu.Item key='111'>
                         <p style={{fontWeight: 500, color: '#000000'}} onClick={this.openCardModal}>
                             Мои карты
                         </p>
                     </Menu.Item> : ''}
                 </Menu>
                 <Card 
                    cardVisible={cardVisible} 
                    isLoading={isLoadingCard} 
                    addCard={this.addCard}
                    handleCardCancel={this.handleCardCancel} 
                    userCards={userCards}
                />
                 {/*<Modal*/}
                 {/*    title="Мои карты"*/}
                 {/*    onCancel={this.handleCardCancel}*/}
                 {/*    visible={this.state.cardVisible}*/}
                 {/*    footer={[*/}
                 {/*        <Button onClick={this.addCard} key="ok">Добавить карту</Button>,*/}
                 {/*        <Button  onClick={this.handleCardCancel} key="back">Отмена</Button>,*/}
                 {/*    ]}*/}
                 {/*>*/}
                 {/*    {userCards}*/}
                 {/*</Modal>*/}
                 <Modal
                     closable={false}
                     visible={this.state.codeVisible}
                     footer={null}
                 >
                     <div className="code-register">
                         <p align="center">Для завершения регистрации введите код из смс</p>
                         <Input 
                            name='regCode' 
                            value={this.state.regCode} 
                            onChange={this.onChange} 
                            placeholder="Введите код сюда"
                        />
                         <Button 
                            onClick={this.sendCode} 
                            style={{width: `100%`, color: `#000`, height: `36px`, borderRadius: `5px`}} 
                            type="primary">
                                Отправить
                        </Button>
                     </div>
                 </Modal>
             </div>
         );
     }
}
HeaderTop.propTypes = {
    authActions: PropTypes.object.isRequired,
    userActions: PropTypes.object,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
    totalSections: PropTypes.array,
    user: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    cards: PropTypes.array,
    isLoadingCard: PropTypes.bool,
    isLoadingAuth: PropTypes.bool,
    profile: PropTypes.object
};
const mapStateToProps = state => ({
    error: state.auth.error,
    isAuthenticated: state.auth.isAuthenticated,
    totalSections: state.category.totalSections,
    isLoading: state.auth.isLoading,
    isLoadingSection: state.category.isLoading,
    cards: state.payment.cards,
    isLoadingCard: state.payment.isLoading,
    user: state.auth.user,
    isLoadingAuth: state.user.isLoading,
    profile: state.user.profile,
});
const mapDispatchToProps = dispatch => ({
    authActions: bindActionCreators(authActions, dispatch),
    categoryActions: bindActionCreators(categoryActions, dispatch),
    paymentActions: bindActionCreators(paymentActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps) (withRouter(HeaderTop));