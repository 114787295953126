import React, {useState, useEffect} from "react";
import './products-slider.scss';
import {Link, withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import Slider from "react-slick";
import {ENDPOINT} from "../../actions/actionTypes";
import {bindActionCreators} from "redux";
import * as productActions from "../../actions/productActions";
import * as authActions from "../../actions/authActions";
import * as cartActions from "../../actions/cartActions";
import {connect} from "react-redux";
import {Button, Modal, message, InputNumber} from "antd";
import {ShoppingCartOutlined, EyeOutlined} from '@ant-design/icons'

function ProductsSlider(props) {
    let productsSliderGroup = [];
    const [visible, setVisible] = useState(false);
    const [quickVisible, setQuickVisible] = useState(false);
    const [name, setName] = useState(``);
    const [shortDescription, setShortDescription] = useState(``);
    const [isShowCart, setIsShowCart] = useState(false);
    const [img, setImg] = useState(``);
    const [price, setPrice] = useState(``);
    const [productCount, setProductCount] = useState(1)
    const [id, setId] = useState(null)
    const [brand, setBrand] = useState({})
    const createUserClick = id => {
        props.actions.createUserClick(id);
    };
    const handleCancel = () => {
        setVisible(false);
    };
    const handleVisible = () => {
        setVisible(true);
    };
    const handleOkCart = () => {
        props.history.push(`/cart`);
    };

    const addShoppingList = item => {
        const data = {
            productId: item.id,
            uid: !props.isAuthenticated ? document.cookie.split(`;`)[0].slice(8) : ``,
            productCount: 1,
            brandId: item.brandId,
        }
        const isAuth = props.isAuthenticated ? true : false
        props.cartActions.addShoppingList(data, isAuth)
    };

    const handleQuickCancel = () => {
        setQuickVisible(false);
    };

    const onChangeQuantity = e => {
        setProductCount(e)
    };

    const handleQuickVisible =  item => {
        setQuickVisible(true);
        setName(item.title);
        setPrice(item.promoPrice ? item.promoPrice : item.price);
        setImg(item.img);
        setShortDescription(item.shortDescription);
        setIsShowCart(item.want)
        setId(item.id)
        setBrand(item.brandId)
    };
    const onMouseOver = id => () => {
        const elements = document.getElementsByClassName('eye-buttons')
        const overlays = document.getElementsByClassName('showcase-img-overlay')
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = 'none'
        }
        for (let i = 0; i < overlays.length; i++) {
            overlays[i].style.display = 'none'
        }
        // document.getElementById(String(id)).style.display = 'block'
        const quickShow = document.getElementById(String(id))
        quickShow.style.display = 'block'
        const overlay = document.getElementById(`overlay${String(id)}`)
        
         overlay.style.display = 'block'
       
        
    };
    const onMouseOut = id => () => {
        const quickShow = document.getElementById(String(id))
        const overlay = document.getElementById(`overlay${String(id)}`)
        quickShow.style.display = 'none'
        overlay.style.display = 'none'
    };
    useEffect(() => {
        const uid = !props.isAuthenticated ? document.cookie.split(`;`)[0].slice(8) : ``
        props.cartActions.getShoppingList(uid)
        props.cartActions.getCart(uid)
    }, [])
  
    const handleOk = item => {
        const uid = document.cookie.split(';')[0].slice(8);
        const { cart, cartActions, isAuthenticated } = props;
        const cartItem = {
            cartItem: {
                productId: item.id,
                userId: 0,
                productCount: productCount ? productCount : 1,
                brandId: item.brand.brandId
            },
            productAttributes: []
        };
        if (cart?.length === 0) {
            cartActions.addCart(cartItem, handleVisible, !isAuthenticated ? uid : '');
        } else if (cart && cart?.length > 0 && item.brand.brandId === cart[0]?.brandResponse?.brandId) {
            cartActions.addCart(cartItem, handleVisible, !isAuthenticated ? uid : '');
        } else if (cart && cart?.length > 0 && item.brand.brandId !== cart[0]?.brandResponse?.brandId) {
            message.error(`Для дополнения корзины товаром из другого магазина/заведения,
            завершите текущий и оформите новый, пожалуйста`, 5);
        } 
        setName(item.name);
        setPrice(item.promoPrice ? item.promoPrice : item.price);
        setImg(item.photo_1);
    };
    if (props.productsSlider.popularProduct && props.productsSlider.popularProduct.length > 0) {
        productsSliderGroup = props.productsSlider.popularProduct.map((item, i) => (
            <div onClick={() => createUserClick(item.id)}
                key={i} className="showcase-brand-item">
                 <div 
                    onMouseOver={onMouseOver(item.id)} 
                    onMouseOut={onMouseOut(item.id)} 
                    to={`/product/${item.id}`} 
                    className="showcase-brand--img"
                >
                    <img src={`${ENDPOINT}/mp/products/photos/${item.img}`} alt={item.name}/>
                    <Button 
                    onClick={() => handleQuickVisible(item)} 
                    style={{display: 'none', width: '100%', borderRadius: 0}} 
                    className="eye-buttons"  
                    id={item.id}><EyeOutlined />  
                    Быстрый просмотр
                </Button>
                <div id={`overlay${item.id}`} className="showcase-img-overlay"></div>
                </div>
                <Link to={`/product/${item.id}`} className="prod-info">
                    <div>
                        <p className="tiny-text">{item.label}</p>
                        <p style={{textOverflow: `ellipsis`, whiteSpace: `nowrap`, overflow: `hidden`}}
                            className="medium-text">{item.title}</p>
                    </div>
                    <div className="product-prices">
                        {item.promoPrice ? <span className="tiny-text price-promo">{item.price}</span> :
                            <span style={{color: `#000000`}} className="general-text">{item.price} тг</span>}
                        {item.promoPrice ? <span className="price-label general-text">{item.promoPrice} тг</span>
                            : ``}
                    </div>
                </Link>
                {item.want ? <button onClick={() => handleOk(item)} className="medium-text"><ShoppingCartOutlined/> В корзину</button>
                    : <Link to={`/product/${item.id}`} className="medium-text">Подробнее</Link>}
                  {item.want && !props.shoppingList.some(elem => elem.cartItem.productId === item.id) ?  
                   <p onClick={() => addShoppingList(item)} className="purchase-text" 
                   style={{display: 'flex', justifyContent: 'flex-end', cursor: 'pointer'}}>+ В список покупок</p>  
                   : item.want && props.shoppingList.some(elem => elem.cartItem.productId === item.id) ? 
                   <p  className="purchase-text" 
                   style={{display: 'flex', justifyContent: 'flex-end', cursor: 'pointer', color: 'green'}}>Добавлено</p> : ''}       
            </div>
        ));
    }
    if (props.productsSlider.newProduct && props.productsSlider.newProduct.length > 0) {
        productsSliderGroup = props.productsSlider.newProduct.map((item, i) => (
            <div onClick={() => createUserClick(item.id)}
                key={i} className="showcase-brand-item">
                <div 
                    onMouseOver={onMouseOver(item.id)} 
                    onMouseOut={onMouseOut(item.id)} 
                    to={`/product/${item.id}`} 
                    className="showcase-brand--img"
                >
                    <img src={`${ENDPOINT}/mp/products/photos/${item.img}`} alt={item.name}/>
                    <Button 
                        onClick={() => handleQuickVisible(item)} 
                        style={{display: 'none', width: '100%', borderRadius: 0}} 
                        className="eye-buttons"  
                        id={item.id}>
                        <EyeOutlined />  
                        Быстрый просмотр
                </Button>
                </div>
                <Link to={`/product/${item.id}`} className="prod-info">
                    <div>
                        <p className="tiny-text">{item.label}</p>
                        <p style={{textOverflow: `ellipsis`, whiteSpace: `nowrap`, overflow: `hidden`}}
                            className="medium-text">{item.title}</p>
                    </div>
                    <div className="product-prices">
                        {item.promoPrice ? <span className="tiny-text price-promo">{item.price}</span> :
                            <span style={{color: `#000000`}} className="general-text">{item.price} тг</span>}
                        {item.promoPrice ? <span className="price-label general-text">{item.promoPrice} тг</span>
                            : ``}
                    </div>
                </Link>
                {item.want ? <button onClick={() => handleOk(item)} className="medium-text">В корзину</button>
                    : <Link to={`/product/${item.id}`} className="medium-text">Подробнее</Link>}
                 {item.want && !props.shoppingList.some(elem => elem.cartItem.productId === item.id) ?  
                   <p onClick={() => addShoppingList(item)} className="purchase-text" 
                   style={{display: 'flex', justifyContent: 'flex-end', cursor: 'pointer'}}>+ В список покупок</p>  
                   : item.want && props.shoppingList.some(elem => elem.cartItem.productId === item.id) ? 
                   <p  className="purchase-text" 
                   style={{display: 'flex', justifyContent: 'flex-end', cursor: 'pointer', color: 'green'}}>Добавлено</p> : ''}      
            </div>
        ));
    }
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        autoPlay: true,
        centered: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return(
        <div className="products-slider">
            <div className="container">
                <p className="large-text">{props.productsSlider.title}</p>
                <div className="products-slider--inner">
                    <Slider {...settings}>
                        {productsSliderGroup}
                       
                    </Slider>
 
                </div>
            </div>
            <Modal
                title="ТОВАР ДОБАВЛЕН В КОРЗИНУ"
                visible={visible}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Продолжить покупку
                    </Button>,
                    <Button style={{color: `#000`}} key="ok" type="primary" onClick={handleOkCart}>
                        Перейти в корзину
                    </Button>
                ]}
            >
                <div className="cart-modal">
                    <div className="cart-modal--img">
                        <img src={`${ENDPOINT}/mp/products/photos/${img}`} alt={`product`}/>
                    </div>
                    <div className="medium-text cart-modal--text">
                        <p>{name}</p>
                        <p>{price}тг</p>
                    </div>
                </div>
            </Modal>
            <Modal
                title="Быстрый просмотр"
                visible={quickVisible}
                onCancel={handleQuickCancel}
                footer={[
                    <Button key="back" onClick={handleQuickCancel}>
                       Закрыть
                    </Button>,
                
                ]}
            >
                <div>
                    <div >
                        <div className="cart-modal">
                        <div className="cart-modal--img">
                            <img src={`${ENDPOINT}/mp/products/photos/${img}`} alt={`product`}/>
                        </div>
                        <div className="cart-modal-info">
                        <div className="medium-text cart-modal--text">
                            <p className="general-text">{name}</p>
                            <p className="medium-text"><strong>Описание: </strong>{shortDescription}</p>                 
                            <p style={{marginTop: '10px', fontWeight: 600, fontSize: '18px'}} className="general-text">{price}тг</p>
                        </div>
                        <div>
                        {isShowCart ? <div className="add-cart">
                            {/* <span className="tiny-text">Количество/кг: </span> */}
                            <InputNumber min={1} max={100} defaultValue={1} onChange={onChangeQuantity}  />
                            <Button onClick={() => handleOk({id: id, brand: {brandId: brand}, name: name, price: price, photo_1: img})} style={{color: `#000`, fontSize: '16px', height: '40px', width: '100%'}} type="primary"><ShoppingCartOutlined/>В корзину</Button>
                        </div> : ``}    
                         
                  
                    </div>
                        </div>                      
                        </div>                                             
                    </div>                
                </div>
                
            </Modal>
        </div>
    );
}
ProductsSlider.propTypes = {
    productsSlider: PropTypes.object,
    actions: PropTypes.object,
    cartActions: PropTypes.object,
    authActions: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    history: PropTypes.object
};
const mapStateToProps = (state) => ({
    isLoading: state.product.isLoading,
    error: state.product.error,
    isAuthenticated: state.auth.isAuthenticated,
    cart: state.cart.cart,
    shoppingList: state.cart.shoppingList
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(productActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(ProductsSlider));