import * as types from '../actions/actionTypes';

const initialState = {
    favoriteResponse: '',
    favorites: [],
    isLoading: false,
}
export default function favoriteReducer(state = initialState, action = null) {
    switch (action.type) {
        case types.ADD_FAVORITE:
            return { ...state, isLoading: true };
        case types.ADD_FAVORITE_SUCCESS:
            return { ...state, isLoading: false, favoriteResponse: action.payload };
        case types.ADD_FAVORITE_FAILED:
            return { ...state, isLoading: false, error: action.error };
        case types.GET_FAVORITE:
            return { ...state, isLoading: true };
        case types.GET_FAVORITE_SUCCESS:
            return { ...state, isLoading: false, favorites: action.payload };
        case types.GET_FAVORITE_FAILED:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}